import React from 'react';
import styled, { css } from 'styled-components';
import envelope from 'assets/icon/envelope.svg';
import form from 'assets/icon/link.svg';
import phone from 'assets/icon/phone.svg';
import { BlankBtn } from 'components/Buttons';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  content: string;
  contact?: boolean;
  link?: boolean;
  mlLg?: boolean;
  mrLg?: boolean;
  myXs?: boolean;
};

interface IProps {
  mlLg: boolean;
  mrLg: boolean;
  myXs: boolean;
}

const Card = styled.div<IProps>`
  padding: 20px 40px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(155, 153, 175, 0.31);
  margin: 50px 0;
  box-sizing: border-box;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ mlLg }) =>
    mlLg === true &&
    css`
      margin-left: 10px;
    `}

  ${({ mrLg }) =>
    mrLg === true &&
    css`
      margin-right: 15px;
    `}

  h3 {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Nunito Sans Light';
    margin-bottom: 10px;
    color: var(--secondary-color-light);
  }

  p {
    font-size: 16px;
    font-family: 'Nunito Sans Light';
    margin-bottom: 12px;
  }

  .inline-info {
    display: flex;
    margin: 10px 0;
    img {
      margin-right: 10px;
    }

    h4 {
      font-size: 14px;
      font-family: 'Nunito Sans Light';
      color: var(--secondary-color-light);

      a {
        text-decoration: none;
        color: var(--secondary-color-light);
      }
    }
  }

  .inline-button {
    margin-top: 20px;
    display: flex;
    justify-content: start;

    a {
      text-decoration: none;
    }

    button {
      display: flex;
      align-items: center;
      font-family: 'Nunito Sans SemiBold';
      color: var(--secondary-color);
      font-size: 14px;
      border: 1px solid var(--secondary-color);
      width: 245px;
      letter-spacing: -0.5px;
      height: 38px;
    }
  }

  @media (max-width: 768px) {
    ${({ myXs }) =>
      myXs === true &&
      css`
        margin: 10px;
      `}

    width: 100%;
    padding: 20px;

    .inline-button {
      display: flex;
      justify-content: center;

      button {
        font-size: 14px;
      }
    }
  }
`;

const CardUI: React.FC<Props> = ({
  title,
  content,
  contact,
  link,
  myXs = 'true',
  mlLg = 'true',
  mrLg = 'true',
}) => {
  return (
    <Card mlLg={!!mlLg} mrLg={!!mrLg} myXs={!!myXs}>
      <h3>{title}</h3>
      <p>{content}</p>
      <div className="complement">
        {contact && (
          <>
            <div className="inline-info">
              <img src={phone} alt="telefone" />
              <h4>0800 607 5556</h4>
            </div>
            <div className="inline-info">
              <img src={envelope} alt="e-mail" />
              <h4>
                <a
                  href="mailto:sac@exeltis.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  sac@exeltis.com
                </a>
              </h4>
            </div>
          </>
        )}
        {link && (
          <>
            <div className="inline-button">
              <a
                href="mailto:sac@exeltis.com"
                target="_blank"
                rel="noreferrer"
              >
                <BlankBtn>
                  <img src={form} alt="link" />
                  Fale Conosco
                </BlankBtn>
              </a>
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default CardUI;
