import React from 'react';
import { Container } from './style';

const Map: React.FC = () => {
  return (
    <>
      <Container>
        <iframe
          title="exeltis"
          src="https://munddi.com/exeltis-2.0?e=1&logo=1"
          width="100%"
          height="500px"
          allow="geolocation *;"
        />
      </Container>
    </>
  );
};

export default Map;
