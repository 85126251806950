import styled, { css } from 'styled-components';

interface BioParagraphProps {
  showMoreActive: boolean;
}

export const Card = styled.div`
  padding: 40px 60px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(155, 153, 175, 0.31);
  margin: 50px 0;
  box-sizing: border-box;

  :nth-child(odd) {
    background-color: transparent;
    border: 2px solid transparent;
    opacity: 1;
  }

  @media (max-width: 600px) {
    padding: 40px;
    margin: 30px 0;
  }

  @media (max-width: 499px) {
    padding: 22px;
    margin: 20px 0;
  }
`;

export const Content = styled.div`
  display: block;

  .wrapper {
    display: flex;
    align-items: center;

    .profile {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      object-fit: cover;
    }

    .info-profissional {
      margin-left: 20px;

      h3 {
        font-size: 28px;
        color: var(--third-color-dark);
        font-weight: 600;
        margin-bottom: 7px;
        font-family: 'Nunito Sans SemiBold';
      }

      span {
        font-family: 'Nunito Sans Light';
        font-weight: 600;
        font-size: var(--font-large);
        line-height: 16px;
      }

      p {
        font-family: 'Nunito Sans Light';
        font-weight: 100;
        color: var(--secondary-color);
        margin-left: 5px;
        font-size: 15px;
      }

      div {
        display: flex;
        align-items: center;
        margin-top: 12px;
      }
    }
  }

  .modules {
    div {
      margin-top: 24px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }
  }

  @media only screen and (max-width: 446px) {
    .modules {
      div {
        flex-wrap: wrap;
        gap: 12px;
        margin-left: -3px;
      }
    }

    .profile {
      width: 108px;
    }

    .info-profissional {
      h3 {
        font-size: 22px !important;
      }

      span {
        font-size: var(--font-medium) !important;
        line-height: 24px !important;
      }
    }
  }

  @media only screen and (max-width: 380px) {
    .info-profissional {
      h3 {
        font-size: 19px !important;
      }

      span {
        font-size: var(--font-medium) !important;
        line-height: 24px !important;
      }
    }
  }

  @media only screen and (max-width: 358px) {
    .wrapper {
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }

    .profile {
      margin-bottom: 20px;
    }

    .gps {
      justify-content: center;
      display: flex;
    }
  }
`;

export const MoreInfo = styled.div`
  display: block;
  max-width: 80%;
  position: relative;
  transform: translate(152px, 0px);

  .content {
    display: flex;
    flex-direction: column;

    .read-more {
      cursor: pointer;
      text-align: left;
      margin-top: 10px;
      font-family: 'Signika Light';
      color: var(--secondary-color-light);
    }

    div {
      width: 100%;
      display: block;
    }
  }

  h3 {
    font-size: var(--font-large);
    color: var(--secondary-color-light);
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Nunito Sans ExtraBold';
    margin: 10px 0;
  }

  p {
    color: var(--color-black);
    font-weight: 500;
    font-size: var(--font-large);
    width: 100%;
    line-height: 26px;
    font-family: 'Signika Medium';
    margin: 0;
  }

  ul {
    display: flex;
    margin-top: 20px;
    margin-bottom: 23px;
    //margin-left: -22px;

    li {
      list-style: none;
      //margin-left: 20px;

      a,
      .phone-number {
        display: flex;
        align-items: center;
        font-size: var(--font-large);
        color: var(--secondary-color);
        text-decoration: none;

        margin-right: 20px;

        img {
          width: 18px;
          height: auto;
          margin-right: 10px;
          background-image: var(--secondary-color);
        }
      }

      .phone-number {
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 650px) {
    transform: none;
    max-width: 100%;

    .content {
      align-items: center;
      text-align: center;

      ul {
        justify-content: center;
        margin-left: 0;
      }
    }

    h3 {
      font-size: var(--font-large);
    }

    p {
      font-size: var(--font-medium);
    }

    ul {
      li {
        display: flex;
        justify-content: center;

        a {
          margin-right: 0;
        }

        :nth-child(-n + 3) {
          margin-left: 15px;
          margin-right: 15px;
        }

        a {
          margin-left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 375px) {
    ul {
      margin-left: 0;
      li {
        display: flex;
        justify-content: center;

        :nth-child(-n + 3) {
          margin-left: 10px;
          margin-right: 10px;
        }

        a {
          font-size: var(--font-medium);
          margin-right: 0;

          img {
            width: 15px;
            height: auto;
            margin-right: 6px;
          }
        }
      }
    }
  }
`;

export const Hr = styled.div`
  @media (max-width: 650px) {
    height: 2px;
    background: var(--color-progress-background);
    margin-top: 40px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 375px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const BioParagraph = styled.p<BioParagraphProps>`
  ${({ showMoreActive }) =>
    showMoreActive &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 50px;
    `}
`;
