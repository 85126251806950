import styled from 'styled-components';

export const ProfissionalCardWrapper = styled.div`
  .title-medics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 30px;

    div {
      display: flex;
      gap: 15px;

      @media (max-width: 768px) {
        gap: 5px;
      }

      img {
        cursor: pointer;
      }
    }

    .close {
      transform: rotate(180deg);
    }

    h1 {
      font-family: 'Nunito Sans Light';
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-size: var(--font-largest);
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: var(--font-larger);
      }
    }
  }
`;
