import styled from 'styled-components';

export const CertificateVerifyContainer = styled.div`
  padding: 0 10%;
  margin-top: 100px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    h1 {
      text-align: center;
      margin-top: -60px;
    }
  }

  p {
    font-size: var(--font-large);
    margin-top: 18px;
    width: 80%;

    @media (max-width: 768px) {
      width: 100%;
      margin-top: 36px;
    }

    span {
      font-weight: 900;
      font-size: var(--font-large);
    }
  }

  .certificate-btn {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .disable {
    opacity: 0.2;
  }
`;
