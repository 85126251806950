import styled from 'styled-components';

export const LessonVideoWrapper = styled.div`
  width: 100%;
  height: 28vw;
  max-height: 430px;
  background-color: #b924810d;

  iframe {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 758px) {
    height: 40vw;
    max-height: 170px;
  }
`;
