import { useState, useEffect } from 'react';
import { getInfoByType } from 'services/info';
import { Header, Footer, Wrapper, Title, SubTitle } from 'components';
import Info from 'services/info/models/info-interface';
import { formattedDate } from 'helpers/formatDate';

import { PolicyContainerWrapper, PrivacyPolicy } from './style';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

function PrivacyPage() {
  const [politic, setPolitic] = useState<Array<Info>>();

  useEffect(() => {
    (async () => {
      let politic;
      const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

      if (user) {
        politic = await getInfoByType('POLITIC');
      } else {
        const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
        politic = await getInfoByType('POLITIC', { Authorization: token });
      }

      setPolitic(politic);
    })();
  }, []);

  return (
    <PolicyContainerWrapper>
      <Header reduceHeader={false} showHeaderNotLogged />

      <Wrapper>
        <Title>POLÍTICA DE PRIVACIDADE</Title>

        {politic && politic.length ? (
          <>
            <SubTitle>
              Esta política foi modificado pela última vez em{' '}
              {formattedDate(new Date(politic[0].date))}.
            </SubTitle>

            <PrivacyPolicy>
              <div dangerouslySetInnerHTML={{ __html: politic[0].text }} />
            </PrivacyPolicy>
          </>
        ) : null}
      </Wrapper>
      <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
    </PolicyContainerWrapper>
  );
}

export default PrivacyPage;
