import { MouseEventHandler } from 'react';
import { ImFacebook } from 'react-icons/im';

import { Container } from './styles';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FacebookButton = ({ onClick }: Props) => (
  <Container onClick={onClick}>
    <ImFacebook size={36} color="#3B5998" />
  </Container>
);

export default FacebookButton;
