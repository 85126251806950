import Header from 'components/Header';
import ModulesContent from './components/ModulesContent';

import { MyCoursesWrapper } from './style';
import smoothscroll from 'smoothscroll-polyfill'


smoothscroll.polyfill()

function MyCourses() {
  return (
    <MyCoursesWrapper>
      <Header reduceHeader={false} />

      <ModulesContent />
    </MyCoursesWrapper>
  );
}

export default MyCourses;
