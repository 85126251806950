import styled, { css } from 'styled-components';

interface IMaterialProps {
  hasMaterial: boolean;
}

export const MaterialCourseWrapper = styled.div<IMaterialProps>`
  ${({ hasMaterial }) => css`
    display: ${hasMaterial ? 'initial' : 'flex'};
  `}

  .title-support {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 5%;

    div {
      display: flex;
      gap: 15px;

      img {
        cursor: pointer;
      }
    }

    .close {
      transform: rotate(180deg);
    }

    h1 {
      font-family: 'Nunito Sans Light';
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-size: var(--font-larger);
      font-weight: 300;
    }
  }

  .slider-wrapper {
    padding-left: 5%;
    margin-bottom: 20px;
  }

  .empty-document {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    div {
      background: rgba(235, 208, 176, 0.47);
      height: 320px;
      border-radius: 12px;
      width: 410px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 20px;
      padding: 25px 32px;

      h2 {
        font-size: var(--font-larger);
        text-transform: uppercase;
        color: var(--secondary-color);
        font-weight: 100;
      }

      img {
        width: 70px;
        height: 70px;
        margin-bottom: 25px;
      }
    }
  }
`;
