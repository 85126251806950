import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 1185px) {
    padding: 40px 20px;
    .thumb-desktop {
      display: none;
    }

    h2 {
      display: none;
    }

    .subtitle {
      display: none;
    }

    .header-content-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      img {
        width: 183px;
      }

      h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-family: 'Nunito Sans Light';
      }
    }

    p {
      font-family: 'Nunito Sans Light';
      font-size: 16px;
      line-height: 28px;
      white-space: pre-line;
      margin: 20px 0;
    }

    iframe {
      height: 289px;
      width: 100%;
    }
  }
  @media screen and (min-width: 1186px) {
    //DESKTOP

    margin: 80px 0;
    display: grid;
    grid-template-columns: 50% 50%;
    position: relative;
    gap: 30px;

    .thumb-desktop {
      justify-self: center;
      width: 100%;
      /* width: 391px;
      height: 500px; */
    }

    .header-content-mobile {
      display: none;
    }

    div {
      h2 {
        font-size: 48px;
        text-transform: uppercase;
        line-height: 48px;
        color: var(--secondary-color);
        font-family: 'Nunito Sans ExtraBold';
        margin-bottom: 10px;
      }

      h3 {
        font-size: 26px;
        text-transform: uppercase;
        font-family: 'Nunito Sans Light';
        margin-bottom: 30px;
      }
      p {
        font-family: 'Nunito Sans Light';
        font-size: 18px;
        line-height: 28px;
        white-space: pre-line;
        margin: 40px 0;
      }
    }
  }
`;

export const Alerts = styled.div`
  @media screen and (max-width: 1185px) {
    margin-bottom: 40px;

    .icon-title {
      display: flex;
      align-items: center;
      flex-direction: initial;
    }

    h4 {
      color: var(--secondary-color);
    }

    .temperature {
      margin-left: -10px;
    }
    img {
      margin-right: 14px;
    }
  }

  @media screen and (min-width: 1186px) {
    div {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .icon-title {
        display: flex;
        align-items: center;
        flex-direction: initial;
      }

      h4 {
        text-transform: uppercase;
        color: var(--secondary-color);
        font-family: 'Nunito Sans ExtraBold';
        font-size: 16px;
      }

      p {
        margin: 15px 40px;
        font-size: 16px;
        line-height: 21px;
        word-break: break-all;
      }

      .alert-medication {
        margin: 30px 0px 50px 0px;
        font-size: 16px;
        span {
          font-family: 'Nunito Sans ExtraBold';
          font-weight: bold;
        }
      }

      .temperature {
        margin-left: -10px;
      }
      img {
        margin-right: 16px;
      }
    }

    h5 {
      font-size: 16px;
      font-family: 'Nunito Sans Light';
    }
  }
`;

export const ContainerBtn = styled.div`
  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    button {
      max-width: 230px;
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      background: var(--secondary-color);
    }
  }
`;

export const Player = styled.div`
  @media screen and (min-width: 1186px) {
    #player {
      width: 100%;
      height: 22vw;
      max-height: 341px;
      background-color: #b924810d;

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

export const ContainerImgInfo = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 10px auto;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 600px) {
    max-width: 600px;
    width: 100%;
  }
`;
