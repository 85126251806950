import React, { useState, useEffect } from 'react';
import { Wrapper, Footer, Title, SubTitle, ErrorUI } from 'components';
import { getAllAuthors } from 'services/profissionals';
import CardProfessional from './components/CardProfessional';
import Dropdown from './components/Dropdown';
import { Content } from './style';
import Author from 'services/profissionals/models/author-interface';

const ModulesContent: React.FC = () => {
  const [professional, setProfessional] = useState<Author[]>([]);
  const [specialties, setSpecialties] = useState<Array<string>>();
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>('');
  const [reload, setReload] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const localProfissionals = await getAllAuthors({
        is_active: true,
        with_courses: true,
      });

      setProfessional(localProfissionals.authors);
      setSpecialties(localProfissionals.specialty);
    })();
  }, [reload]);

  const handleFilterSpecialty = async () => {
    const localProfissionals = await getAllAuthors({
      specialty: selectedSpeciality,
      with_courses: true,
      is_active: true,
    });
    setProfessional(localProfissionals.authors);
  };

  useEffect(() => {
    if (selectedSpeciality) {
      handleFilterSpecialty();
    }
  }, [selectedSpeciality]);

  const renderProfessionals = (items: Author[]) => {
    if (items.length === 0)
      return (
        <>
          <ErrorUI
            errorText="Tente outro especialista no menu"
            boldText="ESPECIALIDADE"
          />
        </>
      );
    return items.map(item => (
      <CardProfessional key={item.author_id} professional={item} />
    ));
  };

  return (
    <>
      <Wrapper>
        <Content>
          <div>
            <Title>Profissionais</Title>
            <SubTitle>
              Conheça os profissionais de saúde que respaldam nossos conteúdos:
            </SubTitle>
          </div>
          {specialties && specialties.length ? (
            <Dropdown
              specialty={selectedSpeciality}
              specialties={specialties}
              onChange={specialty => setSelectedSpeciality(specialty)}
              resetPage={() => setReload(reload => reload + 1)}
            />
          ) : null}
        </Content>
        {professional && professional.length
          ? renderProfessionals(professional)
          : null}
      </Wrapper>
      <Footer backgroundColor={'transparent'} hasLogo />
    </>
  );
};

export default ModulesContent;
