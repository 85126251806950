import styled from 'styled-components';

export const ProfissionalsWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  .sm {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }

    p {
      padding: 0 5%;
      font-family: 'Nunito Sans Light';
      font-size: var(--font-large);
      text-align: left;
      line-height: 35px;

      span {
        font-size: var(--font-large);
        font-weight: 800;
      }
    }
  }

  .slider-wrapper-profissional {
    padding: 0 5%;

    div.slick-list {
      padding-left: 18px;
    }
  }
`;
