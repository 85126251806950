import React from 'react';
import { Wrapper, Title, SubTitle } from 'components';
import { Container } from './style';
import Faq from '../Accordion';

const ModulesContent: React.FC = () => {
  return (
    <>
      <Wrapper>
        <Title>Perguntas frequentes</Title>
        <SubTitle>Perguntas frequentes do Curso de Gestante Regenesis</SubTitle>
        <Container>
          <Faq />
        </Container>
      </Wrapper>
    </>
  );
};

export default ModulesContent;
