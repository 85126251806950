import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { SearchInputWrapper } from './style';
import Glass from 'assets/icon/magnifying-glass-icon.svg';

interface ISearchInput {
  initialSearchInput?: string;
  placeholder?: string;
  searchIcon?: boolean;
}

const SearchInput: React.FC<ISearchInput> = ({
  initialSearchInput,
  placeholder = 'Encontre aulas ou materiais extras...',
  searchIcon = "true",
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    if (initialSearchInput) {
      setSearchValue(initialSearchInput);
    }
  }, [initialSearchInput]);

  const handleSearch = (): void => {
    history.push(`/search/${searchValue}`);
  };

  return (
    <SearchInputWrapper>
      <input
        id="search-input"
        type="text"
        placeholder={placeholder}
        value={searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(event.target.value)
        }
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            handleSearch();
          }
        }}
      />

      {searchIcon && (
        <img src={Glass} alt="magnifying glass" onClick={handleSearch} />
      )}
      
    </SearchInputWrapper>
  );
};

export default SearchInput;
