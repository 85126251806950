import httpClient from 'http-client';
import { User } from './models/user-interface';

export interface IUserProgressTrail {
  trail: {
    user_id: string;
    trail_id: string;
    progress: number;
  };
  courses: IUserProgressTrailCourses[];
}

interface IUserProgressTrailCourses {
  course_id: string;
  name: string;
  percent: number;
  thumbnail: string;
}

interface IUserAvatarFile {
  created_at: string;
  file_id: string;
  mime_type: string;
  name: string;
  original_name: string;
  reference: string;
  type: string;
}

interface IUserProgressUserTrail {
  trail_id: string;
  name: string;
  progress: number;
  time_remaining: number;
}

interface IConfirmPassword {
  email: string;
  password: string;
  code: string;
}

const getUserDetails = async (): Promise<User> => {
  const user: User = (await httpClient.get('/users/me')).data;

  return user;
};

const getUserProgressUserTrail = async (): Promise<
  Array<IUserProgressUserTrail>
> => {
  const userProgress: IUserProgressUserTrail[] = (
    await httpClient.get('/trail-users/me/in-progress')
  ).data;

  return userProgress;
};

const getUserProgress = async (
  trailId: string,
): Promise<IUserProgressTrail> => {
  const meProgress = (await httpClient.get(`trail-users/progress/${trailId}`))
    .data;

  return meProgress;
};

const changeUserInfo = async (userInfo: object): Promise<User> => {
  return (await httpClient.put('/users', userInfo)).data;
};

const changeUserAvatar = async (formData: FormData): Promise<User> => {
  if (!formData) {
    throw new Error('Nenhum arquivo foi enviado!');
  }

  const { reference }: IUserAvatarFile = (
    await httpClient.post(`/files/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

  const userAvatar: User = await (
    await httpClient.patch('/users/avatar', {
      avatar: reference,
    })
  ).data;

  return userAvatar;
};

const setForgotPassword = async (email: string): Promise<boolean> => {
  const password = (
    await httpClient.post('/cognito/forgot-password', {
      email,
    })
  ).data;

  return password;
};

const setConfirmPassword = async (data: IConfirmPassword): Promise<boolean> => {
  const confirm = (
    await httpClient.post('/cognito/confirm-new-password', {
      email: data.email,
      password: data.password,
      code: data.code,
    })
  ).data;

  return confirm;
};

const verifyUser = async (
  email: string,
): Promise<{ email_exists: boolean }> => {
  const confirm = (
    await httpClient.post('/users/verify-user', {
      email: email,
    })
  ).data;

  return confirm;
};

export {
  verifyUser,
  getUserDetails,
  getUserProgress,
  changeUserInfo,
  changeUserAvatar,
  getUserProgressUserTrail,
  setForgotPassword,
  setConfirmPassword,
};
