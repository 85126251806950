import React from 'react';
import Product from 'services/product/models/product-interface';
import { useHistory } from 'react-router-dom';

import { BannerRegenesisWrapper } from './style';
import LogoWhite from 'assets/logo/logopremium-negativa.svg';

interface IBanner {
  withText: boolean;
  product?: Product;
}

const Banner: React.FC<IBanner> = ({ withText, product }) => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push(`/produtos/${product?.product_id}`);
  };

  return (
    <>
      {withText ? (
        <BannerRegenesisWrapper color={product?.info.color}>
          <div className="xl">
            <h2>{product?.title}</h2>

            <p>{product?.subtitle}</p>

            <button className="btn-main" onClick={handleClick}>
              conheça o produto
            </button>
          </div>

          <div className="sm">
            <img src={LogoWhite} alt="regenesis white" />
            <p>{product?.subtitle}</p>
            {product?.thumbnail ? (
              <img src={product?.thumbnail} alt="regenesis pre" />
            ) : null}
          </div>
        </BannerRegenesisWrapper>
      ) : (
        <BannerRegenesisWrapper color={product?.info.color}>
          <div className="without-text">
            {product?.thumbnail ? (
              <img src={product?.thumbnail} alt="regenesis pre" />
            ) : null}

            <div className="elements">
              <h2>{product?.title}</h2>

              <button className="btn-main" onClick={handleClick}>
                conheça {product?.title}
              </button>
            </div>
          </div>
        </BannerRegenesisWrapper>
      )}
    </>
  );
};

export default Banner;
