import React, { useState, useEffect } from 'react';
import { getAllCourses, getCourse } from 'services/course';
import Course from 'services/course/models/course-interface';
import Content from 'services/course/models/content-interface';

import { LandingPageVideoModules, LandingPageModuleCard } from './style';
import Module1Img from 'assets/images/module1-page.png';
import Module2Img from 'assets/images/module2-page.png';
import Module3Img from 'assets/images/module3-page.png';
import Module4Img from 'assets/images/module4-page.png';
import IconPlay from 'assets/icon/play-icon.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import Trail from 'services/course/models/trail-interface';

const VideoContent: React.FC = () => {
  const [moduleOne, setModuleOne] = useState<Course>();
  const [moduleTwo, setModuleTwo] = useState<Course>();
  const [moduleThree, setModuleThree] = useState<Course>();
  const [moduleFour, setModuleFour] = useState<Course>();
  const [openTab, setOpenTab] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;

      const localCourses = await getAllCourses({}, { Authorization: token });
      const selectedCourse = localCourses[0]
        ? await getCourse(localCourses[0].trail_id, {
            Authorization: token,
          })
        : ({} as Trail);

      if (selectedCourse && selectedCourse.courses) {
        setModuleOne(selectedCourse.courses[0]);
        setModuleTwo(selectedCourse.courses[1]);
        setModuleThree(selectedCourse.courses[2]);
        setModuleFour(selectedCourse.courses[3]);
      }
    })();
  }, []);

  const handleTabChange = (tab: number): void => {
    if (openTab === tab) {
      setOpenTab(0);
    } else {
      setOpenTab(tab);
    }
  };

  return (
    <LandingPageVideoModules>
      <div className="container-content">
        <img src={Module1Img} className="img-my" alt="woman hug man" />
        <LandingPageModuleCard>
          <div className="sm">
            <div className="title">
              <div
                className={openTab === 1 ? 'arrow open' : 'arrow'}
                onClick={() => handleTabChange(1)}
              >
                módulo 1: {moduleOne?.title}
                <img src={BackArrow} alt="back arrow" className="back" />
              </div>

              <div className={openTab === 1 ? 'open' : 'close'}>
                <div className="divider"></div>

                {moduleOne?.contents?.length
                  ? moduleOne.contents.map((lesson: Content, index: number) => (
                      <div key={index} className="list">
                        <img src={IconPlay} alt="play" className="icon-play" />

                        <h5>{lesson.title}</h5>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>

          <div className="xl">
            <div className="title">módulo 1: {moduleOne?.title}</div>

            {moduleOne?.contents?.length
              ? moduleOne.contents.map((lesson: Content, index: number) => (
                  <div key={index} className="list">
                    <img src={IconPlay} alt="play" className="icon-play" />

                    <h5>{lesson.title}</h5>
                  </div>
                ))
              : null}
          </div>
        </LandingPageModuleCard>
      </div>

      <div className="container-reverse-content">
        <LandingPageModuleCard>
          <div className="sm">
            <div className="title">
              <div
                className={openTab === 2 ? 'arrow open' : 'arrow'}
                onClick={() => handleTabChange(2)}
              >
                módulo 2: {moduleTwo?.title}
                <img src={BackArrow} alt="back arrow" className="back" />
              </div>

              <div className={openTab === 2 ? 'open' : 'close'}>
                <div className="divider"></div>
                {moduleTwo?.contents?.length
                  ? moduleTwo.contents.map((lesson: Content, index: number) => (
                      <div key={index} className="list">
                        <img src={IconPlay} alt="play" className="icon-play" />

                        <h5>{lesson.title}</h5>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>

          <div className="xl">
            <div className="title">módulo 2: {moduleTwo?.title}</div>

            {moduleTwo?.contents?.length
              ? moduleTwo.contents.map((lesson: Content, index: number) => (
                  <div key={index} className="list">
                    <img src={IconPlay} alt="play" className="icon-play" />

                    <h5>{lesson.title}</h5>
                  </div>
                ))
              : null}
          </div>
        </LandingPageModuleCard>

        <img src={Module2Img} alt="woman eating" className="img-my" />
      </div>

      <div className="container-content">
        <img src={Module3Img} alt="woman yoga" className="img-my" />
        <LandingPageModuleCard>
          <div className="sm">
            <div className="title">
              <div
                className={openTab === 3 ? 'arrow open' : 'arrow'}
                onClick={() => handleTabChange(3)}
              >
                módulo 3: {moduleThree?.title}
                <img src={BackArrow} alt="back arrow" className="back" />
              </div>

              <div className={openTab === 3 ? 'open' : 'close'}>
                <div className="divider"></div>
                {moduleThree?.contents?.length
                  ? moduleThree.contents.map(
                      (lesson: Content, index: number) => (
                        <div key={index} className="list">
                          <img
                            src={IconPlay}
                            alt="play"
                            className="icon-play"
                          />

                          <h5>{lesson.title}</h5>
                        </div>
                      ),
                    )
                  : null}
              </div>
            </div>
          </div>

          <div className="xl">
            <div className="title">módulo 3: {moduleThree?.title}</div>

            {moduleThree?.contents?.length
              ? moduleThree.contents.map((lesson: Content, index: number) => (
                  <div key={index} className="list">
                    <img src={IconPlay} alt="play" className="icon-play" />

                    <h5>{lesson.title}</h5>
                  </div>
                ))
              : null}
          </div>
        </LandingPageModuleCard>
      </div>

      <div className="container-reverse-content">
        <LandingPageModuleCard>
          <div className="sm">
            <div className="title">
              <div
                className={openTab === 4 ? 'arrow open' : 'arrow'}
                onClick={() => handleTabChange(4)}
              >
                {moduleFour?.title}
                <img src={BackArrow} alt="back arrow" className="back" />
              </div>

              <div className={openTab === 4 ? 'open' : 'close'}>
                <div className="divider"></div>
                {moduleFour?.contents?.length
                  ? moduleFour.contents.map(
                      (lesson: Content, index: number) => (
                        <div key={index} className="list">
                          <img
                            src={IconPlay}
                            alt="play"
                            className="icon-play"
                          />

                          <h5>{lesson.title}</h5>
                        </div>
                      ),
                    )
                  : null}
              </div>
            </div>
          </div>

          <div className="xl">
            <div className="title">{moduleFour?.title}</div>

            {moduleFour?.contents?.length
              ? moduleFour.contents.map((lesson: Content, index: number) => (
                  <div key={index} className="list">
                    <img src={IconPlay} alt="play" className="icon-play" />

                    <h5>{lesson.title}</h5>
                  </div>
                ))
              : null}
          </div>
        </LandingPageModuleCard>

        <img src={Module4Img} alt="woman breastfeeding" className="img-my" />
      </div>
    </LandingPageVideoModules>
  );
};

export default VideoContent;
