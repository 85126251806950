import React from 'react';
import { useHistory } from 'react-router-dom';

import { KeepWatchingCardWrapper, ThumbnailCard } from './style';
import PlayIcon from 'assets/icon/play-icon-card.svg';
import PlayIconBlue from 'assets/icon/play-icon-blue.svg';
import CloseIcon from 'assets/icon/close-icon.svg';
import ProgressBar from 'components/ProgressBar';
import { BlankBtn } from 'components/Buttons';

interface IKeepWatchingCard {
  userName: string;
  titleCourse: string;
  thumbnail?: string;
  progress: number;
  trailId: string;
  courseId: string;
  lessonId: string;
  closeCard: () => void;
}

const KeepWatchingCard: React.FC<IKeepWatchingCard> = ({
  userName,
  titleCourse,
  thumbnail,
  progress,
  closeCard,
  courseId,
  lessonId,
  trailId,
}) => {
  const history = useHistory();

  return (
    <KeepWatchingCardWrapper>
      <ThumbnailCard>
        {thumbnail ? (
          <img src={thumbnail} alt="thumbnail" className="thumbnail" />
        ) : (
          <div className="video-empty-card"></div>
        )}
        <img src={PlayIcon} alt="play" className="play-icon" />

        <div className="bar">
          <ProgressBar hasTitle={false} progress={progress} />
        </div>
      </ThumbnailCard>

      <div className="container">
        <div className="info">
          <h1 className="info-h1">continuar assistindo</h1>

          <h4 className="info-h4">
            <span>{userName}, </span>você parou na aula:
          </h4>

          <h2 className="info-h2">{titleCourse}</h2>

          <BlankBtn
            onClick={() =>
              history.push(
                `/trilha/${trailId}/curso/${courseId}/aula/${lessonId}`,
              )
            }
          >
            <img src={PlayIconBlue} alt="play blue" /> continuar
          </BlankBtn>
        </div>

        <div className="close-btn">
          <img src={CloseIcon} alt="close" onClick={closeCard} />
        </div>
      </div>
    </KeepWatchingCardWrapper>
  );
};

export default KeepWatchingCard;
