import Header from 'components/Header';
import Footer from 'components/Footer';
import Banners from './components/Banners';
import WelcomeTitle from './components/Welcome';
import SupportMaterial from './components/SupportMaterial';
import Profissionals from './components/Profissionals';

import { HomeContainerWrapper } from './style';

function Home() {
  return (
    <HomeContainerWrapper>
      <Header reduceHeader={false} />
      <WelcomeTitle />
      <Banners />
      <SupportMaterial />
      <Profissionals />
      <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
    </HomeContainerWrapper>
  );
}

export default Home;
