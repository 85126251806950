import styled, { css } from 'styled-components';

export interface InputProp {
  width?: boolean;
}

export const SearchInputWrapper = styled.div<InputProp>`
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #cfc1c7;
  border-radius: 28px;
  padding: 10px 20px;
  justify-content: space-between;

  input[type='text'] {
    border: none;
    background: transparent;
    outline: none;

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(0, 0, 0, 0.34);
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(0, 0, 0, 0.34);
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(0, 0, 0, 0.34);
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.34);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.34);
    }

    ::placeholder {
      color: rgba(0, 0, 0, 0.34);
    }

    @media (min-width: 800px) {
      min-width: 260px;
    }
  }

  img {
    cursor: pointer;
  }
`;
