import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.68) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--default-background-color);

  h1 {
    font-family: 'Nunito Sans ExtraBold';
    text-transform: uppercase;
    color: var(--secondary-color-light);
    font-size: var(--font-larger);
  }

  .divider {
    margin: 70px auto;
    height: 1px;
    background-color: var(--secondary-color);
    opacity: 0.27;
  }
`;
