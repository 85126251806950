import styled from 'styled-components';

export const SearchContainer = styled.div`
  padding: 0 10%;

  .sm {
    display: none;
  }

  .arrow-back {
    margin-bottom: 40px;
    cursor: pointer;

    @media (max-width: 768px) {
      margin-bottom: 20px;
      margin-top: 15px;
    }
  }

  @media (max-width: 768px) {
    h2 {
      text-align: left;
      margin-bottom: 24px;
    }

    .sm {
      display: initial;
    }
  }
`;

export const SearchContainerListWrapper = styled.div`
  .info-search {
    font-size: var(--font-larger);
    margin-top: 28px;

    @media (max-width: 768px) {
      font-size: var(--font-medium);
    }

    .span-2 {
      font-size: var(--font-larger);
      color: var(--third-color-dark);
    }

    .span-1 {
      font-size: var(--font-larger);
      font-weight: 900;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 200px;

    > div {
      margin-top: 28px;
    }

    .material-extra {
      img {
        height: 100%;
      }
    }
  }

  .container-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    @media (max-width: 768px) {
      margin-top: -15px;
    }
  }
`;
