import React, { useState, useEffect, useCallback } from 'react';
import { getInfoByType } from 'services/info';
import Slider from 'react-slick';
import { homeBannerSettings } from 'config/settingsSlider';
import Info from 'services/info/models/info-interface';

import { BannerWrapper } from './style';
import { Link } from 'react-router-dom';

const Banners: React.FC = () => {
  const [banners, setBanners] = useState<Array<Info>>([]);

  useEffect(() => {
    (async () => {
      const localBanners = await getInfoByType('HOME_IMAGE');

      setBanners(localBanners);
    })();
  }, []);

  return (
    <BannerWrapper>
      <Slider {...homeBannerSettings}>
        {banners &&
          banners.length &&
          banners.map((data, index) => {
            if (Object.keys(data.info).includes('link')) {
              const regex =
                /(onde-comprar|profissionais|termos|privacidade|faq|meus-cursos|trilha|produtos|fale-conosco)/i;
              const isInternal = data.info.link?.match(regex);

              if (isInternal) {
                const index = data.info.link.indexOf(isInternal[0]);
                const newLink = data.info.link.substring(index);

                return (
                  <Link to={newLink}>
                    <div key={`banner-${index}`}>
                      <img src={data.info.image_url} alt="banner" />
                    </div>
                  </Link>
                );
              } else {
                return (
                  <a target="_blanck" href={data.info.link}>
                    <div key={`banner-${index}`}>
                      <img src={data.info.image_url} alt="banner" />
                    </div>
                  </a>
                );
              }
            }

            return (
              <div key={`banner-${index}`}>
                <img src={data.info.image_url} alt="banner" />
              </div>
            );
          })}
      </Slider>
    </BannerWrapper>
  );
};

export default Banners;
