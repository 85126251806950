import React from 'react';
import { useHistory } from 'react-router-dom';
import Author from 'services/profissionals/models/author-interface';

import { ProfissionalCardWrapper } from './style';
import IconGps from 'assets/icon/gps-icon.svg';

interface IProfissional {
  profissional: Author;
  clickable: boolean;
}

const ProfissionalCard: React.FC<IProfissional> = ({
  profissional,
  clickable,
}) => {
  const history = useHistory();

  return (
    <ProfissionalCardWrapper
      onClick={() => {
        if (clickable) {
          history.push('/profissionais');
        }
      }}
      style={{ cursor: clickable ? 'pointer' : 'default' }}
    >
      <img
        src={profissional.avatar_url}
        alt="doctor"
        className="avatar-doctor"
      />

      <div className="main-info">
        <h2>{profissional.name}</h2>
        <h4>
          {profissional.info.specialty.map(
            (specialty: string, index: number, array: Array<string>) => (
              <React.Fragment key={index}>
                {specialty}
                {index !== array.length - 1 ? ' e ' : ''}
              </React.Fragment>
            ),
          )}
        </h4>

        <div>
          <img src={IconGps} alt="gps" />

          <span>{profissional?.info?.city}</span>
        </div>
      </div>
    </ProfissionalCardWrapper>
  );
};

export default ProfissionalCard;
