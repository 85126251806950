import React from 'react';
import ReactDOM from 'react-dom';
import { ModalCloseButton, ModalContent, ModalContentContainer, ModalHeader, ModalTitle } from './style';
import { GrFormClose } from 'react-icons/gr';
import { v4 as uuidv4 } from 'uuid';

const showModal = (title: string, content: React.ReactElement): string => {
  const modalId = `global-modal_${uuidv4()}`;

  const modal = document.createElement('div');
  modal.id = modalId;

  document.body.appendChild(modal);

  const contentToShow = React.cloneElement(content, { modalId, ...content.props });

  ReactDOM.render(
    <ModalContentContainer>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton type="button" onClick={() => hideModal(modalId)}>
          <GrFormClose />
        </ModalCloseButton>
      </ModalHeader>
      <ModalContent>{contentToShow}</ModalContent>
    </ModalContentContainer>,
    document.getElementById(modalId)
  );

  return modalId;
};

const hideModal = (modalId?: string) => {
  let modal = null as HTMLElement | null;
  if (modalId) {
    modal = document.getElementById(`${modalId}`);
  } else {
    modal = document.querySelector(`[id^='global-modal']`);
  }

  if (modal) {
    modal.remove();
  }
};

export { showModal, hideModal };
