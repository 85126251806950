import styled from 'styled-components';

export const ExtraMaterialContainer = styled.div`
  position: relative;
  width: 180px;
  height: 320px;
  cursor: pointer;

  img {
    width: 350px;
    height: 400px;
    border-radius: 8px;
    object-fit: cover;
  }

  .info-support-card {
    position: absolute;
    background: var(--third-color);
    top: 185px;
    width: 352px;
    height: 140px;
    padding: 18px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    h2 {
      font-family: 'Nunito Sans Light';
      color: white;
      text-transform: uppercase;
      font-size: var(--font-largest);
      font-weight: 100;
    }

    p {
      font-family: 'Nunito Sans Light';
      color: white;
      font-size: var(--font-medium);
      font-weight: 100;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;
