import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { RouteComponentProps } from 'react-router-dom';
import { Footer, Header } from 'components';
import VideoContent from './components/VideoModules';
import MedicsComponent from './components/Medics';
import WelcomeVideo from './components/WelcomeVideo';
import { MainBtn } from 'components/Buttons';
import { toast } from 'react-toastify';
import IconBabyWoman from 'assets/icon/icon-woman-baby.png';
import IconDoctor from 'assets/icon/icon-doctor.png';
import IconEbook from 'assets/icon/icon-ebook.png';
import smoothscroll from 'smoothscroll-polyfill';

import {
  LandingPageContainer,
  LandingPageResume,
  LandingPageButtonSubscribe,
} from './style';

import { socialLogin } from 'services/login';

smoothscroll.polyfill();

interface props extends RouteComponentProps {}

function LandingPage({ history }: props) {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

    if (user) {
      history.push('/home');
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await Auth.currentSession();
        const accessToken = response.getAccessToken().getJwtToken();
        const idToken = response.getIdToken().getJwtToken();

        const loginInfo = await socialLogin({
          access_token: accessToken,
          id_token: idToken,
        });

        if (loginInfo) {
          localStorage.setItem('exeltis-api-token', loginInfo.api_token);
          localStorage.setItem('exeltis-user', JSON.stringify(loginInfo.user));

          history.push('/home');
        }
      } catch (error) {}
    })();
  }, []);

  return (
    <LandingPageContainer>
      <Header reduceHeader={false} showHeaderNotLogged />

      <WelcomeVideo />

      <LandingPageResume>
        <h1>somos a sua rede de apoio online na gestação</h1>

        <p>
          Trouxemos obstetras, nutricionista, consultora de amamentação,
          educadora física, dermatologista e vários outros especialistas para te
          orientar com segurança e carinho neste gestar!
        </p>

        <div className="options-info">
          <div>
            <img
              src={IconBabyWoman}
              alt="woman with baby"
              className="woman-baby"
            />

            <span>4 Módulos do 1º Trimestre ao Pós-parto </span>
          </div>

          <div>
            <img src={IconDoctor} alt="doctor" />

            <span>
              + de 40 aulas com PROFISSIONAIS DA SAÚDE E ESPECIALISTAS
            </span>
          </div>

          <div>
            <img src={IconEbook} alt="ebook" />

            <span>materiais de apoio: e-books, guias e muito mais... </span>
          </div>
        </div>
      </LandingPageResume>

      <VideoContent />

      <LandingPageButtonSubscribe>
        <MainBtn onClick={() => history.push('/signup')}>
          quero me inscrever
        </MainBtn>
        <p>curso 100% online e gratuito</p>
      </LandingPageButtonSubscribe>

      <MedicsComponent />

      <LandingPageButtonSubscribe>
        <MainBtn onClick={() => history.push('/signup')}>
          quero me inscrever
        </MainBtn>
        <p>curso 100% online e gratuito</p>
      </LandingPageButtonSubscribe>

      <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
    </LandingPageContainer>
  );
}

export default LandingPage;
