import React from 'react';
import { TabTitle, TextFaq, List } from './style';
import FAQ from 'services/faq/models/faq-interface';

import Arrow from 'assets/icon/back-arrow.svg';

type Props = {
  faqs: FAQ[];
  setFaqSelected: Function;
};

const AccordionUI: React.FC<Props> = ({ faqs, setFaqSelected }) => {
  const [isOpen, setOpen] = React.useState<String>('');

  function verifyIsOpen(id: string) {
    return id === isOpen;
  }

  return (
    <>
      {faqs.map((faq, index) => {
        return (
          <List key={index}>
            <TabTitle>
              <div
                className={`accordion-title ${
                  verifyIsOpen(String(faq.faq_id)) ? 'open' : 'close'
                }`}
                onClick={() => {
                  if (verifyIsOpen(String(faq.faq_id))) {
                    setOpen('');
                  } else {
                    setOpen(String(faq.faq_id));
                  }

                  setFaqSelected(faqs);
                }}
              >
                {faq.question}

                <img
                  src={Arrow}
                  className={
                    verifyIsOpen(String(faq.faq_id)) ? 'open-img' : 'close-img'
                  }
                  alt="/"
                />
              </div>
            </TabTitle>

            <TextFaq>
              {verifyIsOpen(String(faq.faq_id)) && (
                <div
                  className={`accordion-item ${
                    !verifyIsOpen(String(faq.faq_id)) ? 'collapsed' : ''
                  }`}
                >
                  <div className="accordion-content">
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </div>
                </div>
              )}
            </TextFaq>
          </List>
        );
      })}
    </>
  );
};

export default AccordionUI;
