import React, { useState } from 'react';

import { InputWrapper } from './style';
import EyeActive from 'assets/icon/eye-active-icon.svg';
import EyeInactive from 'assets/icon/eye-inactive-icon.svg';

interface IInputProps {
  value: string | number | readonly string[] | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  type: string;
  id?: string;
  label: string;
  alert?: string;
  hasError?: boolean;
}

const Input: React.FC<IInputProps> = ({
  children,
  onChange,
  type,
  value,
  id,
  onFocus,
  label,
  alert,
  hasError,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [typePassword, setTypePassword] = useState<string>('password');

  return (
    <InputWrapper>
      <label htmlFor={id}>{label}</label>
      {alert ? <span className="alert">{alert}</span> : null}
      <input
        type={type === 'password' ? typePassword : type}
        id={id}
        className={hasError ? 'error-border' : ''}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
      />
      {type === 'password' ? (
        <>
          {showPassword ? (
            <img
              src={EyeActive}
              alt="eye"
              className={alert ? 'eye-icon adjust' : 'eye-icon'}
              onClick={() => {
                setShowPassword(false);
                setTypePassword('password');
              }}
            />
          ) : (
            <img
              src={EyeInactive}
              alt="eye"
              className={alert ? 'eye-icon adjust' : 'eye-icon'}
              onClick={() => {
                setShowPassword(true);
                setTypePassword('text');
              }}
            />
          )}
        </>
      ) : null}

      {children}
    </InputWrapper>
  );
};

export default Input;
