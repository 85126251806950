import React from 'react';
import { Content } from './style';
import { Wrapper, Title, SubTitle } from 'components';
import Tab from '../Tab';
import AccordionUI from '../../components/Accordion';

const ModulesContent: React.FC = () => {
  return (
    <Wrapper>
      <Title alignXS="center">PRODUTOS</Title>
      <SubTitle alignXS="center">
        Uma linha completa em suplementação para casais tentantes e gestantes
      </SubTitle>
      <Content>
        <div className="content-tab">
          <Tab />
        </div>
        <div className="content-accordion">
          <AccordionUI />
        </div>
      </Content>
    </Wrapper>
  );
};

export default ModulesContent;
