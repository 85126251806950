import React, { ButtonHTMLAttributes } from 'react';

import {
  BlankBtnWrapper,
  MainBtnWrapper,
  SmallBtnWrapper,
  EmptyBtnWrapper,
} from './style';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isWhite?: boolean;
  size?: 'xs' | 'md';
}

const BlankBtn: React.FC<ButtonProps> = ({ children, isWhite, size, ...props }) => {
  return <BlankBtnWrapper {...props}>{children}</BlankBtnWrapper>
};

const MainBtn: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <MainBtnWrapper {...props}>{children}</MainBtnWrapper>;
};

const SmallBtn: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <SmallBtnWrapper isWhite={false} size={'xs' || 'md'} {...props}>
      {children}
    </SmallBtnWrapper>
  );
};

const EmptyBtn: React.FC<ButtonProps> = ({ children, ...props }) => {
  return <EmptyBtnWrapper {...props}>{children}</EmptyBtnWrapper>;
};

export { BlankBtn, MainBtn, SmallBtn, EmptyBtn };
