import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;

  h4,
  span {
    font-size: 20px;
  }

  img {
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
  }

  @media (max-width: 600px) {
    margin: 50px 0;

    h4,
    span {
      font-size: 16px;
      text-align: center;
    }
  }
`;
