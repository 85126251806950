import styled, { css } from 'styled-components';

interface IInfo {
  open: boolean;
}

interface ICourseWrapper {
  isOpen: boolean;
}

export const CourseWrapper = styled.div<ICourseWrapper>`
  ${({ isOpen }) => css`
    opacity: ${isOpen ? `0.2` : `1`};
  `}

  width: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.68) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--default-background-color);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
`;

export const CourseHeader = styled.div`
  width: 120px;
  height: 100%;
  position: fixed;

  img {
    margin-top: 20px;
    margin-left: 30%;
    cursor: pointer;
  }

  .menu-sm {
    @media (max-width: 768px) {
      display: initial;
    }
  }

  @media (min-width: 769px) {
    .actual-header {
      display: none;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: initial;
    width: 100%;
    padding: 35px 0;

    img {
      margin: 0;
    }
  }
`;

export const CourseModuleInfoWrapper = styled.div<IInfo>`
  ${({ open }) => css`
    width: ${open ? `50%` : `82%`};
  `}

  height: 100%;
  margin-left: 120px;
  transition: all 0.7s ease-in-out;
  margin-bottom: 60px;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }

  .course-title {
    margin-top: 50px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    h1 {
      font-size: var(--font-large);
      opacity: 0.92;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .sm-footer {
    display: none;
    padding-top: 20px;

    .material-course {
      background-color: var(--color-side-menu);
      margin-top: 40px;
      margin-bottom: 120px;
      width: 100vw;
      margin-left: -20px;
    }

    .progress {
      margin-top: 56px;
      margin-bottom: 15px;

      h1 {
        color: var(--secondary-color-light);
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 12px;
        font-size: var(--font-medium);
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .container-course-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`;

export const CourseModuleSelector = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 120px;

  @media (max-width: 768px) {
    margin-top: 0;
  }

  .module-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    cursor: pointer;

    h2 {
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-size: var(--font-large);
    }
  }

  .lesson-selector {
    display: flex;
    align-items: center;
    gap: 22px;

    @media (max-width: 768px) {
      display: none;
    }

    h3 {
      text-transform: uppercase;
      font-size: var(--font-large);
      color: var(--primary-color-light);
    }

    .menu-bullet {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }

    .arrow-left {
      width: 22px;
      height: 22px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  .arrow-down {
    width: 22px;
    transform: rotate(270deg);
  }
`;

export const CourseInfoActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 30px;

  button {
    width: 200px;
  }

  .first-row,
  .second-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .sm-btn {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .sm-third-row {
    display: none;

    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;

      .lesson-selector {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 18px 0;
        margin: 25px 0;
        border: 1px solid rgba(155, 153, 175, 0.31);

        h3 {
          text-transform: uppercase;
          font-size: var(--font-large);
          color: var(--primary-color-light);
        }

        .menu-bullet {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }

        .arrow-left {
          width: 22px;
          height: 22px;
          transform: rotate(180deg);
          cursor: pointer;
        }
      }
    }
  }

  .divider {
    background-color: var(--secondary-color);
    opacity: 0.27;
    height: 1px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .info-author {
    display: flex;
    align-items: center;
    cursor: pointer;

    h1 {
      font-size: var(--font-medium);
      font-weight: 400;
    }

    span {
      opacity: 0.5;
      font-weight: 400;
    }

    img {
      height: 40px;
      width: 40px;
      margin-right: 13px;
      border-radius: 50%;
    }
  }

  .share-btn,
  .comments-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    position: relative;

    span {
      font-weight: 600;
      font-size: var(--font-medium);
      text-transform: uppercase;
      color: var(--secondary-color);
    }
  }

  .comments-btn {
    span {
      font-weight: 200;
      text-transform: capitalize;
      font-size: var(--font-large);
    }
  }

  .notifications-icon {
    background-color: var(--third-color);
    position: absolute;
    left: 10px;
    color: white;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: var(--font-smallest);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
