import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import Product from 'services/product/models/product-interface';
import { Container } from './style';

type Props = {
  products: Product[];
};

const HeaderUI: React.FC<Props> = ({ products }) => {
  const location = useLocation();
  return (
    <Container>
      <ScrollContainer className="categories-nav">
        <ul>
          {products.map((product, index) => {
            const active = location.pathname.includes(
              `/produtos/${product.product_id}`,
            );
            return (
              <li key={index}>
                <NavLink
                  activeClassName={active ? 'active' : undefined}
                  to={`/produtos/${product.product_id}`}
                >
                  <span>{product.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </ScrollContainer>
    </Container>
  );
};

export default HeaderUI;
