const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:cc1f5274-8f93-4bbc-b806-34acfad34ea6',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_E18d1HNSA',
  aws_user_pools_web_client_id: '5357hon5m5pds6fkfeg2ra64de',
  oauth: {
    domain: 'exeltis-test.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://exeltis.testing.sambatech.dev/',
    redirectSignOut: 'https://exeltis.testing.sambatech.dev/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsconfig;
