import React from 'react';
import zoom from 'assets/icon/zoom-in.svg';
import { Container } from './style';

interface IErrorProps {
  errorText: string;
  boldText: string;
}

const ErrorUI: React.FC<IErrorProps> = ({ errorText, boldText }) => {
  return (
    <>
      <Container>
        <h4>
          {errorText} <span>"{boldText}"</span>
        </h4>
        <div>
          <img src={zoom} alt="zoom" />
        </div>
      </Container>
    </>
  );
};

export default ErrorUI;
