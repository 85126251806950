import { useParams } from 'react-router-dom';
import { Header, Footer } from 'components';
import List from './components/List';

import { SearchWrapper } from './style';
import smoothscroll from 'smoothscroll-polyfill'


smoothscroll.polyfill()

interface ISearchParams {
  search: string;
}

function Search() {
  const { search } = useParams() as ISearchParams;

  return (
    <SearchWrapper>
      <Header reduceHeader={false} />

      <List searchValue={search} />
      <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
    </SearchWrapper>
  );
}

export default Search;
