import { useState } from 'react';
import { resendCode, verifyCode as verifyCodeService } from 'services/signup';
import {
  setForgotPassword as forgotPasswordService,
  setConfirmPassword as confirmPasswordService,
  verifyUser,
} from 'services/user';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Footer, Input } from 'components';
import { SmallBtn, EmptyBtn } from 'components/Buttons';

import { VerifyCodeContainer, FormContainer, CodeInput } from './style';

import LogoCursoGestante from 'assets/logo/logo@3x 1.svg';
import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import Flower1 from 'assets/details/flower-1.svg';
import Flower2 from 'assets/details/flower-2.svg';
import Flower3 from 'assets/details/flower-3.svg';
import Flower4 from 'assets/details/flower-4.svg';
import Flower5 from 'assets/details/flower-5.svg';
import Flower6 from 'assets/details/flower-6.svg';
import Flower7 from 'assets/details/flower-7.svg';
import Flower8 from 'assets/details/flower-8.svg';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

interface props extends RouteComponentProps {}

interface IState {
  state: {
    email: string;
    type: string;
    unverified?: boolean;
  };
}

function ResetPassword({ history, location }: props) {
  const [showPasswordInput, setShowPasswordInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<string>('');
  const [errorRepeatPassword, setErrorRepeatPassword] = useState<string>('');
  const { state } = location as IState;

  const type = state?.type || 'login';

  function handleArrow() {
    history.push('/login');
  }

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document.getElementById(`input-verify-code-number-${index + 1}`)?.focus();

    if (index === 5) {
      document.getElementById(`input-verify-code-number-${index}`)?.blur();
    }
  };

  const handlePasswordReset = async (): Promise<void> => {
    if (newPassword !== repeatPassword) {
      setErrorPassword('As senhas não se correspondem, informe senhas iguais.');
      setErrorRepeatPassword(
        'As senhas não se correspondem, informe senhas iguais.',
      );
      return;
    }

    if (!verifyCode) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email,
      password: newPassword,
      code: verifyCode,
    };

    await confirmPasswordService(data)
      .then(response => {
        if (response) {
          toast.success('Senha alterada com sucesso');
          history.push('/login');
        }
      })
      .catch(() => {
        toast.error(
          '⚠ Algo deu errado ao salvar sua nova senha. Tente novamente.',
        );
      });
  };

  const handleVerifyCodeSubmit = async (): Promise<void> => {
    if (!verifyCode || !verifyCode.length) {
      toast.error('Preencha o código de verificação');
      return;
    }

    const data = {
      email: state.email || email,
      code: verifyCode,
    };

    const isValid = await verifyCodeService(data);
    if (isValid) {
      toast.success('Código verificado com sucesso');
      history.push('/login');
    } else {
      toast.error('⚠ Erro ao verificar código. Tente novamente');
    }
  };

  const handleNewCode = async (): Promise<void> => {
    const codeResponse = await resendCode(email);
    if (codeResponse) {
      toast.success('Código reenviado com sucesso!');
    }
  };

  const handleVerifyEmail = async (): Promise<void> => {
    const { email_exists } = await verifyUser(email);

    if (!email_exists) {
      toast.error('⚠ E-mail não encontrado.');
    } else {
      await resendCode(email);
      history.push('/redefinir', {
        type: 'codeSecondStep',
      });
    }
  };

  const sendVerifyCodeToEmail = async (): Promise<void> => {
    if (!email || !email.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
      toast.error('Preencha o campo Email');
      return;
    }

    await forgotPasswordService(email).then(response => {
      if (response) {
        setShowPasswordInput(true);
      }
    });
  };

  return (
    <VerifyCodeContainer>
      <div className="logo-container">
        <img src={Flower1} alt="flower1" className="flower flower-1" />
        <img src={Flower2} alt="flower2" className="flower flower-2" />
        <img src={Flower3} alt="flower3" className="flower flower-3" />
        <img src={Flower4} alt="flower4" className="flower flower-4" />
        <img src={Flower5} alt="flower5" className="flower flower-5" />
        <img src={Flower6} alt="flower6" className="flower flower-6" />
        <img src={Flower7} alt="flower7" className="flower flower-7" />
        <img src={Flower8} alt="flower8" className="flower flower-8" />

        <img
          src={BackArrow}
          alt="back arrow"
          className="back-arrow"
          onClick={handleArrow}
        />

        <img src={LogoRegenesis} alt="regenesis" className="img-1" />

        <img
          src={LogoCursoGestante}
          alt="curso de gestante"
          className="img-2"
        />
      </div>

      {type === 'login' ? (
        <FormContainer>
          {showPasswordInput ? (
            <>
              <h1>Atualize sua senha</h1>

              <div className="space-text-2">
                <h5>Código de verificação</h5>

                <CodeInput>
                  {Array(6)
                    .fill(6)
                    .map((_, index) => (
                      <input
                        key={index}
                        type="number"
                        name=""
                        id={`input-verify-code-number-${index}`}
                        value={verifyCode.charAt(index)}
                        onChange={event => handleChangeInputCode(event, index)}
                      />
                    ))}
                </CodeInput>
              </div>

              <Input
                label={'Nova senha'}
                type="password"
                onChange={event => setNewPassword(event.target.value)}
                value={newPassword}
                id={'new-password-input'}
                alert="A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas e números"
              >
                {errorPassword && <span>{errorPassword}</span>}
              </Input>

              <Input
                label={'Confirme a nova senha'}
                type="password"
                onChange={event => setRepeatPassword(event.target.value)}
                value={repeatPassword}
                id={'repeat-password-input'}
              >
                {errorRepeatPassword && <span>{errorRepeatPassword}</span>}
              </Input>

              <div className="container-btn">
                <SmallBtn onClick={handlePasswordReset}>Enviar</SmallBtn>
              </div>
            </>
          ) : (
            <>
              <h4 className="space-text">
                Insira seu email para receber o código de verificação
              </h4>

              <Input
                label="Email"
                onChange={event => setEmail(event.target.value)}
                type="text"
                value={email}
              />

              <div className="container-btn">
                <SmallBtn onClick={sendVerifyCodeToEmail}>Enviar</SmallBtn>
              </div>
            </>
          )}
        </FormContainer>
      ) : (
        <>
          {type === 'codeFirstStep' ? (
            <FormContainer>
              <h4 className="space-text">Insira seu email</h4>
              {state.unverified && (
                <p>
                  Usuário não confirmado, insira seu email para receber o
                  código, confirmar o cadastro e acessar o sistema.
                </p>
              )}
              <Input
                label="Email"
                onChange={event => setEmail(event.target.value)}
                type="text"
                value={email}
              />

              <div className="container-btn">
                <SmallBtn onClick={handleVerifyEmail}>Enviar</SmallBtn>
              </div>
            </FormContainer>
          ) : (
            <>
              {type === 'codeSecondStep' ? (
                <FormContainer>
                  <h1>Verifique seu email</h1>
                  <div className="space-text-2">
                    <h5>Código de verificação</h5>
                    <CodeInput>
                      {Array(6)
                        .fill(6)
                        .map((_, index) => (
                          <input
                            key={index}
                            type="number"
                            name=""
                            id={`input-verify-code-number-${index}`}
                            value={verifyCode.charAt(index)}
                            onChange={event =>
                              handleChangeInputCode(event, index)
                            }
                          />
                        ))}
                    </CodeInput>
                  </div>
                  <div className="container-btns">
                    <p onClick={handleNewCode}>Reenviar código</p>
                    <EmptyBtn onClick={() => history.push('/')}>
                      Cancelar
                    </EmptyBtn>
                    <SmallBtn onClick={handleVerifyCodeSubmit}>Enviar</SmallBtn>
                  </div>
                </FormContainer>
              ) : (
                <>
                  <FormContainer>
                    <h1>Para prosseguir, é preciso validar seu e-mail</h1>

                    <h5>
                      Um código de confirmação foi enviado para o e-mail:
                      <br />
                      <span>{state.email}</span>
                      <br />
                      Verifique também sua caixa de spam.
                    </h5>

                    <h4>Código</h4>

                    <CodeInput>
                      {Array(6)
                        .fill(6)
                        .map((_, index) => (
                          <input
                            key={index}
                            type="number"
                            name=""
                            id={`input-verify-code-number-${index}`}
                            value={verifyCode.charAt(index)}
                            onChange={event =>
                              handleChangeInputCode(event, index)
                            }
                          />
                        ))}
                    </CodeInput>

                    <div className="container-btns">
                      <EmptyBtn onClick={() => history.push('/')}>
                        Cancelar
                      </EmptyBtn>
                      <SmallBtn onClick={handleVerifyCodeSubmit}>
                        Confirmar
                      </SmallBtn>
                    </div>
                  </FormContainer>
                </>
              )}
            </>
          )}
        </>
      )}

      <div className="footer-login">
        <Footer
          backgroundColor={'var(--color-secondary-background-color)'}
          hasLogo
        />
      </div>
    </VerifyCodeContainer>
  );
}

export default ResetPassword;
