import React from 'react';
import CircularProgress from 'components/CircularProgress';

import { LessonDrawerMenuWrapper, CoursesListCard } from './style';
import CloseIcon from 'assets/icon/close-icon-full.svg';
import PlayBlueIcon from 'assets/icon/play-icon-blue.svg';
import PlayWhiteIcon from 'assets/icon/play-icon-white.svg';
import Course from 'services/course/models/course-interface';
import Content from 'services/course/models/content-interface';

interface ILessonDrawer {
  isOpen: boolean;
  onClose: () => void;
  course: Course;
  lessonId: string;
  coursePosition: number;
  changeSelectedLesson: (lessonId: string) => void;
}

const LessonDrawerMenu: React.FC<ILessonDrawer> = ({
  isOpen,
  onClose,
  changeSelectedLesson,
  course,
  lessonId,
  coursePosition,
}) => {
  return (
    <LessonDrawerMenuWrapper isOpen={isOpen}>
      <div className="container-drawer">
        <img
          src={CloseIcon}
          alt="close"
          onClick={onClose}
          className="icon-close"
        />

        <div className="title">
          <div>
            <h1>{'Módulo ' + coursePosition}</h1>
            <h4>{course?.title}</h4>
          </div>

          <h2>aulas</h2>
        </div>

        <div className="list-courses">
          {course?.contents &&
            course?.contents.length &&
            course?.contents.map(
              (lesson: Content, index: number, lessons: any[]) => (
                <React.Fragment key={index}>
                  <CoursesListCard
                    onClick={() => {
                      changeSelectedLesson(lesson.content_id);
                      onClose();
                    }}
                  >
                    <div className="play-container">
                      {lesson.content_id === lessonId ? (
                        <img
                          src={PlayWhiteIcon}
                          alt="play"
                          className="circle-component"
                        />
                      ) : (
                        <img src={PlayBlueIcon} alt="play" />
                      )}

                      {lesson.content_id === lessonId ? (
                        <div className="progress-position">
                          <CircularProgress
                            progress={25}
                            radius={39}
                            strokeColor={'#E46C9A'}
                            stroke={3.5}
                          />
                        </div>
                      ) : null}
                    </div>

                    <h4
                      className={lesson.content_id === lessonId ? 'bold' : ''}
                    >
                      {lesson?.title}
                    </h4>
                  </CoursesListCard>

                  {index + 1 !== lessons.length && (
                    <hr className="dashed-component" />
                  )}
                </React.Fragment>
              ),
            )}
        </div>
      </div>
    </LessonDrawerMenuWrapper>
  );
};

export default LessonDrawerMenu;
