import httpClient from 'http-client';
import Comment from './models/comments-interface';

interface ICreateComment {
  description: string;
  reference_id: string;
  comment_reference_id?: string;
}

const getCommentsByContent = async (
  content_id: string,
): Promise<Array<Comment>> => {
  const comments: Comment[] = (await httpClient.get(`/comments/${content_id}`))
    .data;

  return comments;
};

const createNewCommentInContentByUser = async (
  data: ICreateComment,
): Promise<Comment> => {
  const comment: Comment = (await httpClient.post('/comments', data)).data;

  return comment;
};

const deleteCommentByCommentId = async (comment_id: string): Promise<any> => {
  const comment: any = (await httpClient.delete(`/comments/${comment_id}`))
    .data;

  return comment;
};

export {
  getCommentsByContent,
  createNewCommentInContentByUser,
  deleteCommentByCommentId,
};
