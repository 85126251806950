import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { updateLessonProgress as updateLessonProgressService } from 'services/course';
import Content from 'services/course/models/content-interface';
import Course from 'services/course/models/course-interface';
import { PlayerSambaVideos } from 'components';

import { LessonVideoWrapper } from './style';

interface ICourseContent {
  lesson: Content;
  course: Course;
  trailId: string;
  allCourses: Course[];
  getEventListeners: (player: PlayerEventListener) => void;
  progress: number;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

const CourseContent: React.FC<ICourseContent> = ({
  lesson,
  course,
  trailId,
  getEventListeners,
  progress,
}) => {
  const [contentUrl, setContentUrl] = useState<string>('');
  const [previousSelectedContent, setPreviousSelectedContent] =
    useState<Content>();
  const [shouldShowPlayer, setShouldShowPlayer] = useState<boolean>(false);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [hasFinishedVideo, setFinishedVideo] = useState<boolean>(false);

  useEffect(() => {
    const selectedContentIsDifferent =
      lesson.content_id !== previousSelectedContent?.content_id ||
      lesson.course_id !== previousSelectedContent.course_id ||
      lesson.trail_id !== previousSelectedContent.trail_id;
    if (selectedContentIsDifferent) {
      setShouldShowPlayer(false);
      process.nextTick(() => setShouldShowPlayer(true));
    }

    setPreviousSelectedContent(lesson);
  }, [trailId, lesson]);

  const resumeTime = useMemo(() => {
    if (!lesson) {
      return false;
    }
    if (lesson.content_view) {
      setLastWatchedTime(lesson.content_view);
      return lesson.content_view;
    }

    return false;
  }, [lesson]);

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateLessonProgressService(
        course.course_id,
        trailId,
        lesson.content_id,
        time,
      );
    },
    [course.course_id, lesson.content_id],
  );

  useEffect(() => {
    if (hasFinishedVideo) {
      setFinishedVideo(false);
    }
  }, [hasFinishedVideo]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }

    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress]);

  const getControlsToEnable = useCallback(() => {
    return hasFinishedVideo
      ? [
          'play',
          'pause',
          'quality',
          'fullscreen',
          'time',
          'volume',
          'backward',
          'forward',
          'seekbar',
          'cast',
          'subtitles',
          'settings',
          'speed',
        ]
      : [
          'play',
          'pause',
          'quality',
          'fullscreen',
          'time',
          'volume',
          'backward',
          'forward',
          'seekbar',
          'cast',
          'subtitles',
          'settings',
          'speed',
        ];
  }, [hasFinishedVideo]);

  useEffect(() => {
    setShouldShowPlayer(false);

    process.nextTick(() => {
      let localContentUrl = contentUrl;
      const validUrlStart =
        'https://fast.player.liquidplatform.com/pApiv2/embed/';
      const isValidUrl =
        localContentUrl && localContentUrl.startsWith(validUrlStart);

      if (isValidUrl) {
        setShouldShowPlayer(true);
      }
    });
  }, [contentUrl, lesson.content_id]);

  useEffect(() => {
    setContentUrl(lesson.reference);
  }, [lesson.reference]);

  return (
    <LessonVideoWrapper>
      {shouldShowPlayer && (
        <PlayerSambaVideos
          contentReference={contentUrl}
          resume={resumeTime || 0}
          controlsToEnable={getControlsToEnable()}
          getEventListeners={getEventListeners}
        />
      )}
    </LessonVideoWrapper>
  );
};

export default CourseContent;
