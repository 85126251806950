import React from 'react';
import { getFaqData } from 'services/faq';
import FAQ from 'services/faq/models/faq-interface';
import Categories from '../Accordion/Categories';
import ContentUI from '../Accordion/Content';

const AccordionUI: React.FC = () => {
  const [faqs, setFaqs] = React.useState<FAQ[]>([]);
  const [faqSelected, setFaqSelected] = React.useState<FAQ>();

  React.useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem('exeltis-user') as string);
      let localFaqs;

      if (user) {
        localFaqs = await getFaqData();
      } else {
        const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
        localFaqs = await getFaqData(undefined, { Authorization: token });
      }

      setFaqs(localFaqs);
    })();
  }, []);

  return (
    <>
      <Categories faqs={faqs} setFaqSelected={setFaqSelected}>
        <ContentUI faq={faqSelected} />
      </Categories>
    </>
  );
};

export default AccordionUI;
