import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    :root {
        --default-background-color: #FFF1F1;
        --color-secondary-background-color:#FFF9F9;
        

        --primary-color: #B92481;
        --primary-color-light: #E46C9A;
        --primary-color-dark:#7D1857;

        --secondary-color: #5858A7;
        --secondary-color-light: #7F67DA;
        --secondary-color-dark:#3C3C73;
        
        --third-color: #5075CD;
        --third-color-dark: #0C38A3;

        --color-black: #000000;        
        --color-text-footer: #282E58;

        --color-error: #DE2443;
        --color-menu: #F6F6F6;
        --color-progress-background: #E7E6F0;
        --color-text-card:#646464;
        --banner-color:#3FADBB;
        --color-side-menu: #EADAC6;

        --font-smallest: 11px;
        --font-small: 12px;
        --font-normal: 14px;
        --font-medium: 16px;
        --font-large: 18px;
        --font-larger: 24px;
        --font-largest: 32px;
        --font-bigger: 44px;
    } 

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      
    }
    
    body {
      font-family: "Nunito Sans";
      font-size: var(--font-normal);
      background: var(--default-background-color);
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      
    }
    
    input, button, textarea {
      font-family: "Nunito Sans";
      font-size: var(--font-normal);
    }

    button {
      cursor: pointer;
      transition: all 0.2s;
    }

    .Toastify__toast-container {
      padding: 0;
    }

    .Toastify__toast-container--top-center {
      width: 100%;
      left: 0;
      top: 0;
      transform: none;
    }

    .Toastify__toast {
      width: 100%;
      border-radius: 0;
    }

    .Toastify__toast--error {
      background: var(--color-error);
    }

    .Toastify__close-button {
      align-self: center;
    }

 ::-webkit-scrollbar {
	  width: 8px;
	}
	::-moz-scrollbar {
	  width: 8px;
	}
	:-ms-input-scrollbar {
	  width: 8px;
	}

  ::-webkit-scrollbar-thumb {
	  background: #cecece;
	  border-radius: 10px;
	}

  
`;

export default GlobalStyle;
