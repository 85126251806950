import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LandingPage from 'pages/Landing-page';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import ResetPassword from 'pages/VerifyCode';
import MyCourses from 'pages/MyCourses';
import Course from 'pages/Course';
import Professionals from 'pages/Professionals';
import Localization from 'pages/Localization';
import Produtos from 'pages/Products';
import Profile from 'pages/Profile';
import Terms from 'pages/Termos';
import Privacy from 'pages/Privacy';
import Search from 'pages/Search';
import Contact from 'pages/Contact';
import Faq from 'pages/FAQ';
import Page404 from 'pages/Page404';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage} />

      <ProtectedRoute path="/home" exact component={Home} />

      <Route path="/login" exact component={Login} />

      <Route path="/signup" exact component={Signup} />

      <Route path="/redefinir" exact component={ResetPassword} />

      <ProtectedRoute path="/perfil" exact component={Profile} />

      <ProtectedRoute path="/onde-comprar" exact component={Localization} />

      <ProtectedRoute path="/profissionais" exact component={Professionals} />

      <Route path="/termos" exact component={Terms} />

      <Route path="/privacidade" exact component={Privacy} />

      <Route path="/faq" exact component={Faq} />

      <ProtectedRoute path="/search/:search" exact component={Search} />

      <ProtectedRoute
        path="/meus-cursos/trilha/:trailId/curso/:courseId"
        exact
        component={MyCourses}
      />

      <ProtectedRoute
        path="/trilha/:trailId/curso/:courseId/aula/:lessonId"
        exact
        component={Course}
      />

      <ProtectedRoute path="/produtos/:id" exact component={Produtos} />

      <Route path="/fale-conosco" exact component={Contact} />

      <Route path="*" exact component={Page404} />
    </Switch>
  );
};

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => {
        let user = localStorage.getItem('exeltis-user');
        if (user) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default Routes;
