import React from 'react';
import { Container } from './style';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LogoCursoGestante from 'assets/logo/logo@3x 1.svg';
import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import Flower1 from 'assets/details/flower-1.svg';
import Flower2 from 'assets/details/flower-2.svg';
import Flower3 from 'assets/details/flower-3.svg';
import Flower4 from 'assets/details/flower-4.svg';
import Flower5 from 'assets/details/flower-5.svg';
import Flower6 from 'assets/details/flower-6.svg';
import Flower7 from 'assets/details/flower-7.svg';
import Flower8 from 'assets/details/flower-8.svg';

type Props = {
  isLogo: boolean;
};

const BackgroundFlowers: React.FC<Props> = ({ children, isLogo = false }) => {
  const history = useHistory();

  function handleArrow() {
    history.goBack();
  }

  return (
    <Container>
      <div className="logo-container">
        <img src={Flower1} alt="flower1" className="flower flower-1" />
        <img src={Flower2} alt="flower2" className="flower flower-2" />
        <img src={Flower3} alt="flower3" className="flower flower-3" />
        <img src={Flower4} alt="flower4" className="flower flower-4" />
        <img src={Flower5} alt="flower5" className="flower flower-5" />
        <img src={Flower6} alt="flower6" className="flower flower-6" />
        <img src={Flower7} alt="flower7" className="flower flower-7" />
        <img src={Flower8} alt="flower8" className="flower flower-8" />

        <img
          src={BackArrow}
          alt="back arrow"
          className="back-arrow"
          onClick={handleArrow}
        />

        {isLogo && (
          <Link to="/">
            <img src={LogoRegenesis} alt="regenesis" className="img-1" />
          </Link>
        )}

        {isLogo && (
          <Link to="/">
            <img
              src={LogoCursoGestante}
              alt="curso de gestante"
              className="img-2"
            />
          </Link>
        )}
      </div>
      {children}
    </Container>
  );
};

export default BackgroundFlowers;
