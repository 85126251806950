import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from 'components/CircularProgress';
import ProgressBar from 'components/ProgressBar';
import { BlankBtn, MainBtn } from 'components/Buttons';
import { getUserProgressUserTrail } from 'services/user';
import { getCourse, getAllCourses } from 'services/course';
import { User } from 'services/user/models/user-interface';

import { WelcomeTitleContainer, LastWatchedContainer } from './style';
import PlayIconWhite from 'assets/icon/play-icon-white.svg';
import PlayIconBlue from 'assets/icon/play-icon-blue.svg';
import WomanFirstPeriod from 'assets/details/woman-draw-1period.svg';
import WomanSecondPeriod from 'assets/details/woman-draw-2period.svg';
import WomanThirdPeriod from 'assets/details/woman-draw-3period.svg';
import WomanForthPeriod from 'assets/details/woman-draw-4period.svg';

const Welcome: React.FC = () => {
  const [infoOverall, setInfoOverall] = useState<any>();
  const [user, setUser] = useState<User>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const localUserDetails = JSON.parse(
        localStorage.getItem('exeltis-user') as string,
      );
      setUser(localUserDetails);
      const progress = await getUserProgressUserTrail();

      if (progress && progress.length) {
        const localTrail = await getCourse(progress[0].trail_id);
        const sortCourse = localTrail.user.courses
          .filter(course => !!course.start_at && !!course.finish_at === false)
          .sort((a, b) => {
            let date1 = new Date(a.start_at);
            let date2 = new Date(b.start_at);

            if (date1 < date2) return 1;
            if (date1 > date2) return -1;
            return 0;
          });

        let lastCourse;

        if (sortCourse.length) {
          lastCourse = localTrail.courses.find(
            course => course.course_id === sortCourse[0].course_id,
          );
        }

        if (sortCourse && sortCourse.length) {
          let lastLessonProgress = sortCourse[0]?.contents
            .filter(lesson => !!lesson.start_at)
            .sort((a, b) => {
              let date1 = new Date(a.start_at);
              let date2 = new Date(b.start_at);

              if (date1 < date2) return 1;
              if (date1 > date2) return -1;
              return 0;
            });

          let lastLesson;

          if (lastCourse && lastLessonProgress && lastLessonProgress.length) {
            lastLesson = lastCourse.lessons.find(
              lesson => lesson.content_id === lastLessonProgress[0].content_id,
            );
          }

          if (lastLesson && lastCourse) {
            setInfoOverall({
              ...localUserDetails,
              progress: Math.round(progress[0].progress * 100),
              period: lastCourse.position,
              title: lastCourse.title,
              lesson_title: lastLesson?.title,
              trailId: localTrail.trail_id,
              courseId: lastCourse.course_id,
              lessonId: lastLesson?.content_id,
            });
          }
        }
      } else {
        const allTrails = await getAllCourses();
        const localTrail = await getCourse(allTrails[0].trail_id);

        setInfoOverall({
          ...localUserDetails,
          trailId: localTrail.trail_id,
          courseId: localTrail.courses[0].course_id,
          lessonId: localTrail.courses[0].lessons[0]?.content_id,
        });
      }
    })();
  }, []);

  const handlePeriodImg = ():
    | React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
      >
    | undefined => {
    switch (infoOverall.period) {
      case 1:
        return (
          <img
            src={WomanFirstPeriod}
            alt="woman first period"
            className="img-woman-period"
          />
        );

      case 2:
        return (
          <img
            src={WomanSecondPeriod}
            alt="woman second period"
            className="img-woman-period"
          />
        );

      case 3:
        return (
          <img
            src={WomanThirdPeriod}
            alt="woman third period"
            className="img-woman-period"
          />
        );

      case 4:
        return (
          <img
            src={WomanForthPeriod}
            alt="woman forth period"
            className="img-woman-period"
          />
        );

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {infoOverall && infoOverall?.progress !== undefined ? (
        <WelcomeTitleContainer hasCourse>
          <div className="title-text">
            <h1>Olá, {user?.name}!</h1>

            <h3>
              Acompanhe o seu progresso no
              <span> Curso de Gestante Regenesis.</span>
            </h3>

            <div className="bar">
              <ProgressBar progress={infoOverall?.progress} hasTitle />
            </div>
          </div>

          <LastWatchedContainer>
            <div className="progress-container">
              {handlePeriodImg()}

              <div className="xl">
                <div className="container">
                  <CircularProgress
                    progress={100}
                    strokeColor={'rgba(236, 234, 255, 0.52)'}
                    stroke={12}
                    radius={130}
                  />
                </div>

                <div className="container">
                  <CircularProgress
                    progress={infoOverall?.progress}
                    strokeColor={'#E46C9A'}
                    stroke={12}
                    radius={130}
                  />
                </div>
              </div>

              <div className="sm">
                <div className="container">
                  <CircularProgress
                    progress={100}
                    strokeColor={'rgba(236, 234, 255, 0.52)'}
                    stroke={5}
                    radius={70}
                  />
                </div>

                <div className="container">
                  <CircularProgress
                    progress={infoOverall?.progress}
                    strokeColor={'#E46C9A'}
                    stroke={5}
                    radius={70}
                  />
                </div>
              </div>
            </div>

            <div className="watched-info">
              <h1>continuar assistindo</h1>

              <h4>{infoOverall?.title}</h4>

              <p>{infoOverall?.lesson_title}</p>

              <div className="container-btn">
                <BlankBtn
                  onClick={() =>
                    history.push(
                      `/trilha/${infoOverall?.trailId}/curso/${infoOverall?.courseId}/aula/${infoOverall?.lessonId}`,
                    )
                  }
                >
                  <img src={PlayIconBlue} alt="play" /> continuar de onde parei
                </BlankBtn>
              </div>
            </div>
          </LastWatchedContainer>
        </WelcomeTitleContainer>
      ) : (
        <WelcomeTitleContainer hasCourse={false}>
          <div className="title-text">
            <h1>Olá, {user?.name}!</h1>

            <h3>
              Seja bem-vinda ao <span>Curso de Gestante Regenesis.</span>
            </h3>

            <p>
              Aqui você terá acesso a um curso completo que vai te acompanhar
              por toda a jornada da gestação até os
              <span> 6 meses de vida do seu bebê. </span>
            </p>

            <br />

            <p>
              Sabemos o quão importante é a <span>rede de apoio</span> neste
              momento único da vida. Por isso, trouxemos vários
              <span> profissionais da saúde</span> para te orientar com
              segurança e carinho neste gestar!
            </p>
          </div>

          {infoOverall && (
            <MainBtn
              onClick={() =>
                history.push(
                  `/trilha/${infoOverall?.trailId}/curso/${infoOverall?.courseId}/aula/${infoOverall?.lessonId}`,
                )
              }
            >
              <img src={PlayIconWhite} alt="play" /> iniciar curso
            </MainBtn>
          )}
        </WelcomeTitleContainer>
      )}
    </React.Fragment>
  );
};

export default Welcome;
