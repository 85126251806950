import React, { useState } from 'react';
import Comment from 'services/comments/models/comments-interface';
import { EmptyBtn, SmallBtn } from 'components/Buttons';
import { compareDate } from 'helpers/formatDate';

import { CommentCardContainer, CommentFormContainer } from '../style';
import Arrow from 'assets/icon/back-arrow.svg';
import { User } from 'services/user/models/user-interface';

interface ICommentResponse {
  comment: Comment;
  handleCreateNewComment: (
    comment: string,
    id: string,
    response_id: string,
  ) => void;
  user: User;
  lessonId: string;
}

const ResponseContent: React.FC<ICommentResponse> = ({
  comment,
  handleCreateNewComment,
  user,
  lessonId,
}) => {
  const [responseText, setResponseText] = useState('');
  const [showResponses, setShowResponses] = useState<boolean>(false);
  const [isResponding, setIsResponding] = useState<boolean>(false);

  const responseCleanup = () => {
    setResponseText('');
    setIsResponding(false);
  };

  const handleCancelBtnClick = () => {
    responseCleanup();
  };

  const handleSendBtnClick = () => {
    handleCreateNewComment(responseText, lessonId, comment.comment_id);
    responseCleanup();
  };

  return (
    <>
      <div className="btns-comment">
        {!isResponding && (
          <EmptyBtn
            onClick={() => {
              setIsResponding(true);
            }}
          >
            responder
          </EmptyBtn>
        )}

        <div className="show-response">
          {comment.responses !== null ? (
            <>
              {showResponses ? (
                <h3 onClick={() => setShowResponses(false)}>
                  ocultar respostas {'(' + comment.responses.length + ')'}
                </h3>
              ) : (
                <h3 onClick={() => setShowResponses(true)}>
                  ver respostas {'(' + comment.responses.length + ')'}
                </h3>
              )}

              <img
                src={Arrow}
                alt="arrow"
                className={showResponses ? 'arrow-icon open' : 'arrow-icon'}
              />
            </>
          ) : null}
        </div>
      </div>

      {comment.responses && comment.responses.length && showResponses ? (
        <div className="list-responses">
          {comment.responses.map((response, index) => (
            <CommentCardContainer key={index}>
              <div className="container">
                <img
                  src={response.user_avatar_url}
                  alt="person"
                  className="profile-avatar"
                />

                <div className="comment-card">
                  <div className="title">
                    <h2>{response?.username}</h2>

                    <span>{compareDate(response?.created_at)}</span>
                  </div>

                  <p>{response?.description}</p>
                </div>
              </div>
            </CommentCardContainer>
          ))}
        </div>
      ) : null}
      {isResponding && (
        <CommentFormContainer isResponseComponent={true}>
          <div className="input-container">
            <img src={user?.info?.avatar} alt="person" />

            <textarea
              placeholder="Faça um comentário"
              value={responseText}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setResponseText(event.target.value)
              }
            />
          </div>

          <div className="btns-container">
            <EmptyBtn onClick={handleCancelBtnClick}>cancelar</EmptyBtn>

            <SmallBtn onClick={handleSendBtnClick}>enviar</SmallBtn>
          </div>
        </CommentFormContainer>
      )}
    </>
  );
};

export default ResponseContent;
