import React from 'react';
import styled, { css } from 'styled-components';

type IProps = {
  alignLG?: 'center' | 'left' | 'right';
  alignXS?: 'center' | 'left' | 'right';
};

const Title = styled.h2<IProps>`
  font-size: 28px;
  color: var(--secondary-color-light);
  font-weight: bold;
  margin-bottom: 7px;
  font-family: 'Nunito Sans ExtraBold';
  text-transform: uppercase;

  @media (max-width: 1279px) {
    font-size: 26px;

    ${({ alignXS }) =>
      alignXS === 'center' &&
      css`
        text-align: center;
      `}

    ${({ alignXS }) =>
      alignXS === 'left' &&
      css`
        text-align: left;
      `}

		${({ alignXS }) =>
      alignXS === 'right' &&
      css`
        text-align: right;
      `}
  }

  @media (max-width: 650px) {
    font-size: 26px;
    text-align: center;

    ${({ alignXS }) =>
      alignXS === 'center' &&
      css`
        text-align: center;
      `}

    ${({ alignXS }) =>
      alignXS === 'left' &&
      css`
        text-align: left;
      `}

		${({ alignXS }) =>
      alignXS === 'right' &&
      css`
        text-align: right;
      `}
  }
`;

type Props = {
  alignLG?: 'center' | 'left' | 'right';
  alignXS?: 'center' | 'left' | 'right';
};

const TitleUI: React.FC<Props> = ({ children, alignXS, alignLG }) => {
  return (
    <Title alignLG={alignLG} alignXS={alignXS}>
      {children}
    </Title>
  );
};

export default TitleUI;
