import styled from 'styled-components';

export const ProgressCourseWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;

  .woman-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .progress-bar-circular {
      position: relative;
      width: 83px;
      height: 83px;
      border-radius: 50%;
      background-color: var(--third-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 6px solid var(--color-progress-background);
      border-radius: 50%;

      .container {
        position: absolute;
        top: -12px;
        left: -16%;
      }

      span {
        font-size: var(--font-largest);
        color: white;
      }
    }

    .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;
      cursor: pointer;

      h6 {
        color: var(--secondary-color);
        font-family: 'Nunito Sans Light';
        font-weight: 100;
        text-transform: capitalize;
        margin-top: 8px;
        max-width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        @media (max-width: 768px) {
          font-size: var(--font-small);
        }
      }

      img {
        width: 83px;
        height: 83px;
        border: 6px solid var(--color-progress-background);
        border-radius: 50%;
        background-color: var(--color-secondary-background-color);

        @media (max-width: 768px) {
          width: 52px;
          height: 52px;
        }
      }

      .bold {
        font-weight: 900;

        @media (max-width: 768px) {
          font-size: var(--font-normal);
        }
      }
    }

    ::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: -14.7px;
      background-color: var(--primary-color-light);
    }

    i {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: 52px;
      border: 1px solid var(--primary-color-light);
      background-color: transparent;
    }
  }

  .dashed-component {
    width: 2%;
    height: 1px;
    border: none;
    border-top: 1px dashed var(--primary-color-light);
    padding: 0 3px;

    @media (max-width: 768px) {
      padding: 0 12px;
    }
  }
`;
