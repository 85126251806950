import React from 'react';
import styled, { css } from 'styled-components';

type IProps = {
  alignLG?: 'center' | 'left' | 'right';
  alignXS?: 'center' | 'left' | 'right';
};

const SubTitle = styled.p<IProps>`
  font-family: 'Nunito Sans';
  font-weight: 100;
  color: var(--color-black);
  font-size: var(--font-larger);
  margin-top: 20px;
  margin-bottom: 15px;

  ${({ alignLG }) =>
    alignLG === 'center' &&
    css`
      text-align: center;
    `}

  ${({ alignLG }) =>
    alignLG === 'left' &&
    css`
      text-align: left;
    `}

		${({ alignLG }) =>
    alignLG === 'right' &&
    css`
      text-align: right;
    `}

  @media (max-width: 1279px) {
    font-size: var(--font-large);

     ${({ alignXS }) =>
    alignXS === 'center' &&
    css`
      text-align: center;
    `}

  ${({ alignXS }) =>
    alignXS === 'left' &&
    css`
      text-align: left;
    `}

		${({ alignXS }) =>
    alignXS === 'right' &&
    css`
      text-align: right;
    `}
  }
`;

type Props = {
  alignLG?: 'center' | 'left' | 'right';
  alignXS?: 'center' | 'left' | 'right';
};

const SubTitleUI: React.FC<Props> = ({ children, alignLG, alignXS }) => {
  return (
    <SubTitle alignLG={alignLG} alignXS={alignXS}>
      {children}
    </SubTitle>
  );
};

export default SubTitleUI;
