import React from 'react';
import { Header, Footer } from 'components';
import ModulesContent from './components/ModulesContent';
import { ProductWrapper } from './style';
import smoothscroll from 'smoothscroll-polyfill'


smoothscroll.polyfill()

const Product: React.FC = () => {
  return (
    <ProductWrapper>
      <Header reduceHeader={false} />

      <ModulesContent />
      <Footer backgroundColor={'transparent'} hasLogo />
    </ProductWrapper>
  );
};

export default Product;
