import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth, Cache } from 'aws-amplify';
import { getIp, login } from 'services/login';
import { toast } from 'react-toastify';
import { validateEmail } from 'helpers/validation';
import { Footer, Input, Header } from 'components';
import { SmallBtn } from 'components/Buttons';

import {
  LoginContainer,
  FormContainer,
  SocialMediaLinks,
  SocialMedia,
} from './style';

import LogoCursoGestante from 'assets/logo/logo@3x 1.svg';
import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import Flower1 from 'assets/details/flower-1.svg';
import Flower2 from 'assets/details/flower-2.svg';
import Flower3 from 'assets/details/flower-3.svg';
import Flower4 from 'assets/details/flower-4.svg';
import Flower5 from 'assets/details/flower-5.svg';
import Flower6 from 'assets/details/flower-6.svg';
import Flower7 from 'assets/details/flower-7.svg';
import Flower8 from 'assets/details/flower-8.svg';
import smoothscroll from 'smoothscroll-polyfill';
import FacebookButton from 'components/FacebookButton';
import GoogleButton from 'components/GoogleButton';
import createCookie from 'helpers/createCookie';

smoothscroll.polyfill();

interface props extends RouteComponentProps {}

function Login({ history }: props) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'email-input':
        setEmail(e.target.value);
        break;

      case 'password-input':
        setPassword(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleLogin = async (): Promise<void> => {
    if (!email) {
      setEmailError('Este campo é obrigatório.');
    }

    if (!password) {
      setPasswordError('Este campo é obrigatório.');
      return;
    }

    if (password && password.length < 8) {
      toast.error('⚠ Erro ao fazer login E-mail ou Senha estão incorretos.');
      return;
    }

    if (validateEmail(email) === false) {
      setEmailError('Informe um e-mail com formato válido.');
      return;
    }
    const { ip } = await getIp();
    const data = {
      email,
      password,
      is_medic: false,
      ip,
    };

    login(data)
      .then(response => {
        localStorage.setItem('exeltis-api-token', response?.api_token);
        localStorage.setItem('exeltis-user', JSON.stringify(response?.user));
        history.push('/home');
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === 'Erro ao fazer login Usuário não confirmado') {
          history.push('/redefinir', {
            type: 'codeFirstStep',
            unverified: true,
          });
        }
        toast.error(errorMessage);

        setEmailError('');
        setPasswordError('');
      });
  };

  return (
    <LoginContainer>
      <Header reduceHeader={false} />

      <div className="logo-container">
        <img src={Flower1} alt="flower1" className="flower flower-1" />
        <img src={Flower2} alt="flower2" className="flower flower-2" />
        <img src={Flower3} alt="flower3" className="flower flower-3" />
        <img src={Flower4} alt="flower4" className="flower flower-4" />
        <img src={Flower5} alt="flower5" className="flower flower-5" />
        <img src={Flower6} alt="flower6" className="flower flower-6" />
        <img src={Flower7} alt="flower7" className="flower flower-7" />
        <img src={Flower8} alt="flower8" className="flower flower-8" />

        <img
          src={BackArrow}
          alt="back arrow"
          className="back-arrow"
          onClick={() => history.push('/')}
        />

        <img
          src={LogoRegenesis}
          alt="regenesis"
          className="img-1"
          onClick={() => history.push('/')}
        />

        <img
          src={LogoCursoGestante}
          alt="curso de gestante"
          className="img-2"
        />
      </div>

      <FormContainer>
        <div className="title">
          <h2>entre com</h2>

          <div className="btn-login-xl">
            <SocialMediaLinks>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Facebook"
              >
                <FacebookButton aria-hidden />
              </SocialMedia>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Google"
              >
                <GoogleButton aria-hidden />
              </SocialMedia>
            </SocialMediaLinks>
          </div>

          <div className="btn-login-sm">
            <SocialMediaLinks>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Facebook"
              >
                <FacebookButton aria-hidden />
                <p>Continuar com o Facebook</p>
              </SocialMedia>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Google"
              >
                <GoogleButton aria-hidden />
                <p>Continuar com o Google</p>
              </SocialMedia>
            </SocialMediaLinks>
          </div>

          <h4>ou entre com seu email</h4>
        </div>

        <Input
          type="email"
          id="email-input"
          value={email}
          onChange={handleChangeInput}
          onFocus={() => setEmailError('')}
          label="E-mail"
          hasError={!!emailError}
        >
          {emailError && <span>{emailError}</span>}
        </Input>

        <Input
          type="password"
          id="password-input"
          value={password}
          onChange={handleChangeInput}
          onFocus={() => setPasswordError('')}
          label="Senha"
          hasError={!!passwordError}
        >
          {passwordError && <span>{passwordError}</span>}
        </Input>
        <p
          className="reset"
          onClick={() =>
            history.push('/redefinir', {
              type: 'login',
            })
          }
        >
          Recuperar minha senha
        </p>

        <SmallBtn onClick={handleLogin}>Entrar</SmallBtn>

        <h4>
          Ainda não é cadastrada?{' '}
          <span onClick={() => history.push('/signup')}>Crie sua conta.</span>
        </h4>
      </FormContainer>

      <div className="footer-login">
        <Footer
          backgroundColor={'var(--color-secondary-background-color)'}
          hasLogo
        />
      </div>
    </LoginContainer>
  );
}

export default Login;
