import styled from 'styled-components';

export const KeepWatchingCardWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 2px solid rgba(128, 104, 218, 0.18);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  padding: 0 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    width: auto;
    margin: 0 15px;
    padding: 0;

    .close-btn {
      display: none;
    }
  }

  .container {
    display: flex;
    width: 75%;
    justify-content: space-between;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: center;
      margin-bottom: 25px;
    }

    .close-btn {
      img {
        cursor: pointer;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    @media (max-width: 768px) {
      min-width: auto;
      padding: 0 8px;

      button {
        align-self: center;
      }
    }

    &-h1 {
      font-size: var(--font-larger);
      color: var(--secondary-color-light);
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    &-h4 {
      font-family: 'Nunito Sans Light';
      font-weight: 100;
      font-size: var(--font-medium);

      span {
        font-family: 'Nunito Sans ExtraBold';
        font-size: var(--font-medium);
      }
    }

    &-h2 {
      font-family: 'Nunito Sans ExtraBold';
      font-size: var(--font-medium);
      color: var(--third-color-dark);
      margin-bottom: 15px;
    }
  }
`;

export const ThumbnailCard = styled.div`
  position: relative;
  height: 150px;
  width: 300px;
  margin-right: 6%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 25px 0;
  }

  .thumbnail {
    width: 100%;
    height: 100%;
  }

  .video-empty-card {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    opacity: 0.05;
    border-radius: 4px;
  }

  .play-icon {
    position: absolute;
    left: 48%;
    top: 58px;
  }

  .bar {
    margin-top: -5px;
  }
`;
