import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  h2 {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'Cookie';
    font-weight: 400;
    color: var(--primary-color-light);
    font-size: 68px;
  }

  h3 {
    font-size: 24px;
    text-transform: uppercase;
    color: var(--secondary-color-light);
    margin-top: 20px;
    font-family: 'Nunito Sans ExtraBold';
  }

  img {
    margin: 80px 0;
    max-width: 250px;
    width: 100%;
  }

  button {
      max-width: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--secondary-color);
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 58px;
      text-align: center;
      white-space: break-spaces;
    }
    h3 {
      font-size: 24px;
      text-align: center;
      white-space: break-spaces;
    }
    img {
      max-width: 180px;
      margin: 35px 0;
    }
  }
`;
