import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import { getAllCourses, getCourse } from 'services/course';
import { NavLink, useLocation } from 'react-router-dom';
import Course from 'services/course/models/course-interface';
import { Dropdown, SearchInput } from 'components';
import { User } from 'services/user/models/user-interface';
import { SmallBtn } from 'components/Buttons';
import DrawerMenu from './components/DrawerMenu';

import awsconfig from '../../aws-exports';

import {
  HeaderContainerWrapper,
  NavBarContainer,
  ProfileMenuContainer,
  HomeWrapper,
  MenuBackgroundOpacity,
  NotRegisterHeader,
  NotRegisterHeaderResponsive,
} from './style';

import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import Avatar from 'assets/icon/avatar.svg';
import Arrow from 'assets/icon/back-arrow.svg';
import Logo from 'assets/logo/logo-small.svg';
import MenuIcon from 'assets/icon/menu-icon.svg';

interface IHeader {
  reduceHeader: boolean;
  showHeaderNotLogged?: boolean;
  search?: string;
  showLogo?: boolean;
}

interface CourseParams {
  trailId: string;
}

const Header: React.FC<IHeader> = ({
  reduceHeader,
  showHeaderNotLogged,
  showLogo = true,
}) => {
  const [user, setUser] = useState<User>();
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [modules, setModules] = useState<Array<Course>>();
  const [menuCourse, setMenuCourse] = useState<boolean>(false);
  const [menuAvatar, setMenuAvatar] = useState<boolean>(false);
  const [trailId, setTrailId] = useState<string>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams() as CourseParams;
  const active = location.pathname ? true : false;
  const isCoursePage = !!params.trailId;

  useEffect(() => {
    (async () => {
      const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

      if (!!user) {
        setIsRegister(true);
        const localCourses = await getAllCourses();

        if (localCourses && localCourses.length > 0) {
          const selectedCourse = await getCourse(localCourses[0].trail_id);
          setTrailId(selectedCourse.trail_id);
          setModules(selectedCourse.courses);
        }
        setUser(user);
      } else {
        setIsRegister(false);
      }
    })();
  }, []);

  const handleMenuOpen = (type: string): void => {
    switch (type) {
      case 'course':
        if (menuAvatar) {
          setMenuAvatar(false);
        }
        setMenuCourse(course => !course);
        break;

      case 'avatar':
        if (menuCourse) {
          setMenuCourse(false);
        }
        setMenuAvatar(avatar => !avatar);
        break;

      default:
        break;
    }
  };

  const handleLogout = async () => {
    window.localStorage.removeItem('exeltis-api-token');
    window.localStorage.removeItem('exeltis-user');

    Auth.currentSession()
      .then(() => {
        Auth.signOut().then(() => {
          window.location.href = `https://${awsconfig.oauth.domain}/logout?client_id=${awsconfig.aws_user_pools_web_client_id}&logout_uri=${awsconfig.oauth.redirectSignOut}`;
        });
      })
      .catch(() => {
        history.push('/');
      });
  };

  return (
    <>
      {isRegister ? (
        <>
          <>
            {
              <>
                <MenuBackgroundOpacity
                  open={openDrawer}
                  onClick={() => setOpenDrawer(false)}
                />
                <DrawerMenu
                  isRegister={isRegister}
                  trailId={trailId}
                  logout={handleLogout}
                  courses={modules}
                  onClose={() => setOpenDrawer(false)}
                  openDrawer={openDrawer}
                />
              </>
            }
            <HomeWrapper>
              {showLogo && !isCoursePage ? (
                <img
                  src={Logo}
                  alt="regenesis"
                  onClick={() => history.push('/home')}
                />
              ) : (
                <span></span>
              )}

              <img
                src={MenuIcon}
                alt="menu"
                onClick={() => setOpenDrawer(true)}
              />
            </HomeWrapper>
          </>
          {reduceHeader ? (
            <HeaderContainerWrapper isReduced>
              <div className="reduced-header">
                <div></div>
                <ProfileMenuContainer>
                  <p>{user?.name}</p>

                  <img
                    src={user?.info?.avatar ? user.info.avatar : Avatar}
                    alt="avatar"
                    onClick={() => handleMenuOpen('avatar')}
                  />

                  <Dropdown
                    open={menuAvatar}
                    typeMenu={'avatar'}
                    logout={handleLogout}
                    onClose={() => setMenuCourse(false)}
                    positionProps={{
                      top: '50px',
                      left: '-140px',
                    }}
                  />
                </ProfileMenuContainer>
              </div>
            </HeaderContainerWrapper>
          ) : (
            <HeaderContainerWrapper isReduced={false}>
              {showLogo && (
                <img
                  src={LogoRegenesis}
                  alt="regenesis"
                  className="logo"
                  onClick={() => history.push('/home')}
                />
              )}
              <SearchInput />

              <NavBarContainer>
                <div className="my-course">
                  <div
                    className="selected"
                    onClick={() => handleMenuOpen('course')}
                  >
                    <span>meu curso</span>
                    <img src={Arrow} alt="arrow" className="arrow-course" />
                  </div>
                  <Dropdown
                    open={menuCourse}
                    typeMenu={'course'}
                    modules={modules}
                    onClose={() => setMenuCourse(false)}
                    positionProps={{
                      top: '40px',
                      left: '-130px',
                    }}
                    trailId={trailId}
                  />
                </div>

                <NavLink
                  activeClassName={active ? 'active' : ''}
                  to="/profissionais"
                >
                  profissionais
                </NavLink>

                <NavLink
                  activeClassName={active ? 'active' : undefined}
                  to={`/produtos/1`}
                >
                  <span>PRODUTOS</span>
                </NavLink>

                <NavLink
                  activeClassName={active ? 'active' : ''}
                  to="/onde-comprar"
                >
                  onde comprar
                </NavLink>

                <ProfileMenuContainer>
                  <p>{user?.name}</p>

                  <img
                    src={user?.info?.avatar ? user.info.avatar : Avatar}
                    alt="avatar"
                    onClick={() => handleMenuOpen('avatar')}
                  />

                  <Dropdown
                    open={menuAvatar}
                    typeMenu={'avatar'}
                    logout={handleLogout}
                    onClose={() => setMenuCourse(false)}
                    positionProps={{
                      top: '50px',
                      left: '-140px',
                    }}
                  />
                </ProfileMenuContainer>
              </NavBarContainer>
            </HeaderContainerWrapper>
          )}
        </>
      ) : (
        <>
          <MenuBackgroundOpacity
            open={openDrawer}
            onClick={() => setOpenDrawer(false)}
          />

          <DrawerMenu
            isRegister={isRegister}
            trailId={trailId}
            logout={handleLogout}
            courses={modules}
            onClose={() => setOpenDrawer(false)}
            openDrawer={openDrawer}
          />

          {showHeaderNotLogged ? (
            <NotRegisterHeader>
              <img
                src={LogoRegenesis}
                alt="exeltis"
                onClick={() => history.push('/')}
              />

              <div>
                <p onClick={() => history.push('/login')}>Já tenho cadastro</p>

                <SmallBtn onClick={() => history.push('/signup')}>
                  Inscreva-se
                </SmallBtn>
              </div>
            </NotRegisterHeader>
          ) : null}

          <NotRegisterHeaderResponsive>
            <img src={Logo} alt="regenesis" onClick={() => history.push('/')} />

            <img
              src={MenuIcon}
              alt="menu"
              onClick={() => setOpenDrawer(true)}
            />
          </NotRegisterHeaderResponsive>
        </>
      )}
    </>
  );
};

export default Header;
