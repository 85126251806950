import React from 'react';
import {
  ShareOptionsContainer,
  LinkInput,
  ShareButtonsContainer,
  CopyLinkButton,
} from './style';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { useRef } from 'react';
import { toast } from 'react-toastify';

interface ShareOptionsProps {
  modalId?: string;
}

const ShareOptions: React.FC<ShareOptionsProps> = ({ modalId }) => {
  const inputReference = useRef<HTMLInputElement>(null);

  const copyLink = () => {
    if (inputReference && inputReference.current) {
      inputReference.current.select();
      inputReference.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      inputReference.current.setSelectionRange(0, 0);
      toast.success(`Link de compartilhamento copiado!`);
    }
  };

  return (
    <ShareOptionsContainer>
      <LinkInput ref={inputReference} readOnly value={window.location.href} />

      <ShareButtonsContainer>
        <CopyLinkButton
          onClick={copyLink}
          title={'Copiar Link de Compartilhamento'}
        >
          <AiOutlinePaperClip size={18} />
        </CopyLinkButton>

        <FacebookShareButton
          url={window.location.href}
          children={<FacebookIcon />}
        />
        <TwitterShareButton
          url={window.location.href}
          children={<TwitterIcon />}
        />
        <LinkedinShareButton
          url={window.location.href}
          children={<LinkedinIcon />}
        />
        <EmailShareButton url={window.location.href} children={<EmailIcon />} />
      </ShareButtonsContainer>
    </ShareOptionsContainer>
  );
};

export default ShareOptions;
