import styled, { css, keyframes } from 'styled-components';

const dropdown = keyframes`
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}

	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

interface IDropdownMenu {
  open: boolean;
  typeMenu: string;
}

export const DropdownMenuContainer = styled.div<IDropdownMenu>`
  ${({ open }) => css`
    display: ${open ? 'block' : 'none'};
    animation: ${dropdown} 0.6s;
  `}

  ${({ typeMenu }) => css`
    li {
      justify-content: ${typeMenu === 'course' ? 'center' : 'start'};
    }
  `}

  position: absolute;
  background-color: var(--color-menu);
  width: 260px;
  border-radius: 8px;
  box-shadow: 0px 24px 64px rgba(45, 48, 58, 0.13);
  border: 1px solid rgba(228, 108, 154, 0.39);
  padding: 25px 30px;
  z-index: 4;

  .triangle {
    position: absolute;
    height: auto;
    z-index: 5;
    top: -15px;
    left: 203px;
    transform: rotate(0deg);
    border: none;
  }

  .triangle-1 {
    position: absolute;
    height: auto;
    z-index: 5;
    top: -17px;
    left: 203px;
    transform: rotate(0deg);
    border: none;
  }

  .divider-header {
    background-color: var(--secondary-color);
    width: 100%;
    opacity: 0.1;
    height: 1px;
    margin: 15px 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: 'center';
    list-style-type: none;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-family: 'Nunito Sans SemiBold';
    cursor: pointer;

    > img {
      width: 19px;
      height: 19px;
      border: none;
      border-radius: 0;
      margin-right: 10px;
    }
  }
`;
