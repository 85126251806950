import React, { useState, useEffect } from 'react';
import { getAllExtraMaterial } from 'services/course/extra-material';
import ExtraMaterial from 'services/course/models/extra-material-interface';
import Slider from 'react-slick';
import { supportMaterialSettings } from 'config/settingsSlider';

import NextArrow from 'assets/icon/arrow-expanded.svg';
import PrevArrow from 'assets/icon/arrow-retracted.svg';
import { SupportMaterialWrapper } from './style';

const SupportMaterial: React.FC = () => {
  const [supportMaterial, setSupportMaterial] = useState<Array<ExtraMaterial>>(
    [],
  );
  const [reachEnd, setReachEnd] = useState<boolean>(false);
  const [reachStart, setReachStart] = useState<boolean>(true);
  let sliderRef: Slider | null;

  useEffect(() => {
    (async () => {
      const allExtraMaterial = await getAllExtraMaterial();
      if (allExtraMaterial?.length) {
        setSupportMaterial(allExtraMaterial);
      }
    })();
  }, []);

  const handleSliderChange = (current: number): void => {
    if (current + 3.2 === supportMaterial.length) {
      setReachEnd(true);
      setReachStart(false);
    } else if (current !== 0) {
      setReachEnd(false);
      setReachStart(false);
    } else {
      setReachEnd(false);
      setReachStart(true);
    }
  };

  return supportMaterial && supportMaterial.length ? (
    <SupportMaterialWrapper>
      <div className="title-support">
        <h1>material de apoio</h1>

        <div>
          <img
            src={reachStart ? PrevArrow : NextArrow}
            alt="previous arrow"
            className={reachStart ? '' : 'close'}
            onClick={() => sliderRef?.slickPrev()}
          />
          <img
            src={reachEnd ? PrevArrow : NextArrow}
            alt="next arrow"
            className={reachEnd ? 'close' : ''}
            onClick={() => sliderRef?.slickNext()}
          />
        </div>
      </div>

      <div className="slider-wrapper">
        <Slider
          {...supportMaterialSettings}
          ref={slider => (sliderRef = slider)}
          afterChange={current => handleSliderChange(current)}
        >
          {supportMaterial.map((data: ExtraMaterial, index) => (
            <a
              key={index}
              href={data.reference}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="support-card">
                {data.thumbnail ? (
                  <img src={data.thumbnail} alt="support" />
                ) : (
                  <div className="no-image"></div>
                )}

                <div className="info-support-card">
                  <h2>{data.name}</h2>

                  <span>{data.course_name}</span>

                  {data.info?.description && <p>{data.info.description}</p>}
                </div>
              </div>
            </a>
          ))}
        </Slider>
      </div>
    </SupportMaterialWrapper>
  ) : (
    <></>
  );
};

export default SupportMaterial;
