import React, { useEffect, useState } from 'react';
import {
  getAllCourses,
  getSearchCoursesAndExtraMaterial,
} from 'services/course';
import { useHistory } from 'react-router-dom';
import {
  Title,
  ErrorUI,
  CourseCard,
  ExtraMaterialCard,
  SearchInput,
  Pagination,
} from 'components';
import { BlankBtn } from 'components/Buttons';

import { SearchContainer, SearchContainerListWrapper } from './style';
import ArrowBack from 'assets/icon/back-arrow.svg';

interface IListProps {
  searchValue: string;
}

const List: React.FC<IListProps> = ({ searchValue }) => {
  const history = useHistory();
  const [trailId, setTrailId] = useState<string>();
  const [content, setContent] = useState<Array<any>>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const localTrail = await getAllCourses();

      setTrailId(localTrail[0].trail_id);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const localSearchContent = await getSearchCoursesAndExtraMaterial({
        text: searchValue,
        limit: offset !== 0 ? offset : null,
        offset: offset !== 0 ? offset : null,
      });

      setContent(localSearchContent.data);
      setTotalElements(localSearchContent.total);
    })();
  }, [searchValue, offset]);

  return (
    <SearchContainer>
      <img
        src={ArrowBack}
        alt="arrow back"
        className="arrow-back"
        onClick={() => history.push('/home')}
      />
      <Title>busca</Title>

      <div className="sm">
        <SearchInput initialSearchInput={searchValue} />
      </div>

      <SearchContainerListWrapper>
        {content && content.length && trailId ? (
          <div>
            <h2 className="info-search">
              Encontramos <span className="span-1">{content.length}</span>{' '}
              resultados para
              <span className="span-2"> {searchValue}</span>
            </h2>

            <div className="list">
              {content.map((el, index) =>
                el.type === 'CONTENT' ? (
                  <CourseCard
                    key={index}
                    courseId={el?.courses[0]?.course_id}
                    lessonId={el.content_id}
                    profissional_avatar={el.authors[0]?.avatar_url}
                    profissional_name={el.authors[0]?.name}
                    profissional_specialty={el.authors[0]?.info?.specialty}
                    thumbnail={el.thumbnail}
                    title={el.title}
                    trailiId={trailId}
                  />
                ) : (
                  <div className="material-extra" key={index}>
                    <ExtraMaterialCard materialCourse={el} />
                  </div>
                ),
              )}
            </div>

            <Pagination
              quantity={totalElements}
              next={() => {
                if (totalElements > 5) {
                  setOffset(offset => offset + 5);
                }
              }}
              preview={() => {
                if (totalElements !== 0 || offset !== 0) {
                  setOffset(offset => offset - 5);
                }
              }}
            />
          </div>
        ) : (
          <div>
            <h2 className="info-search">
              Não encontramos nenhum resultado
              <span className="span-2"> {searchValue}</span>
            </h2>

            <ErrorUI
              errorText="Tente usar outras palavras-chave ou navegue pelo menu"
              boldText="MEU CURSO"
            />

            <div className="container-btn">
              <BlankBtn>ir para meu curso</BlankBtn>
            </div>
          </div>
        )}
      </SearchContainerListWrapper>
    </SearchContainer>
  );
};

export default List;
