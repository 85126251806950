import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Footer from 'components/Footer';
import ProgressBar from 'components/ProgressBar';
import CourseCard from 'components/CourseCard';
import Banner from 'components/Banner';
import KeepWatchingCard from './components/KeepWatchingCard';
import { getCourse, getCourseModule } from 'services/course';
import { getAuthor } from 'services/profissionals';
import Content from 'services/course/models/content-interface';
import Course from 'services/course/models/course-interface';
import Author from 'services/profissionals/models/author-interface';
import Trail from 'services/course/models/trail-interface';
import Product from 'services/product/models/product-interface';
import { User } from 'services/user/models/user-interface';
import { completePercentageInVideo } from 'helpers/contentProgress';

import {
  ModulesContentWrapper,
  ModuleSelectorTitle,
  ModulesListContainer,
} from './style';
import WomanImg1 from 'assets/details/woman-draw-1period.svg';
import WomanImg2 from 'assets/details/woman-draw-2period.svg';
import WomanImg3 from 'assets/details/woman-draw-3period.svg';
import WomanImg4 from 'assets/details/woman-draw-4period.svg';
import ArrowExpanded from 'assets/icon/arrow-expanded.svg';
import ArrowRetracted from 'assets/icon/arrow-retracted.svg';
import smoothscroll from 'smoothscroll-polyfill';
import { Loading } from 'components';

smoothscroll.polyfill();

interface IMyCourseParams {
  courseId: string;
  trailId: string;
}

interface ICourseData {
  progress: number;
  userName: string;
  titleCourse: string;
  thumbnail: string;
  lessonId: string;
}

const ModulesContent: React.FC = () => {
  const [allModules, setAllModules] = useState<Array<Course>>([]);
  const [moduleData, setModuleData] = useState<Course>();
  const [courseData, setCourseData] = useState<ICourseData>({
    progress: 0,
    userName: '',
    titleCourse: '',
    thumbnail: '',
    lessonId: '',
  });
  const [lessons, setLessons] = useState<Array<Content>>([]);
  const [author, setAuthor] = useState<Author>();
  const [authorInfo, setAuthorInfo] = useState<Array<Content>>();
  const [position, setPosition] = useState<number>(0);
  const [showKeepWatchingCard, setShowKeepWatchingCard] =
    useState<boolean>(false);
  const [courseProgress, setCourseProgress] = useState<number>(0);
  const { courseId, trailId } = useParams() as IMyCourseParams;
  const [product, setProduct] = useState<Product>();
  const history = useHistory();

  const isLoading = useMemo(() => {
    return !moduleData;
  }, [moduleData]);

  const handleCoursesLoad = useCallback(async () => {
    const localCourse = await getCourse(trailId);
    let localAuthor = null;
    if (
      localCourse?.authors !== null &&
      localCourse?.authors[0] &&
      localCourse?.authors[0]?.id
    ) {
      localAuthor = await getAuthor(localCourse.authors[0].id);
    }

    const selectedCourse =
      localCourse &&
      localCourse.courses.length &&
      localCourse.courses.filter(course => course.course_id === courseId);
    setAllModules(localCourse.courses);
    setCourseData({
      progress: 0,
      userName: '',
      titleCourse: '',
      thumbnail: '',
      lessonId: '',
    });

    setShowKeepWatchingCard(false);
    if (localCourse.user) {
      handleKeepWatchingCard(localCourse);
    }

    if (selectedCourse) {
      const localProduct = await getCourseModule(selectedCourse[0].course_id);
      setPosition(selectedCourse[0].position - 1);
      setModuleData(selectedCourse[0]);
      setLessons(selectedCourse[0].lessons);
      setAuthorInfo(localProduct?.contents);
      if (localAuthor !== null) {
        setAuthor(localAuthor);
      }
      setProduct(localProduct.products[0]);

      const finishedCourses = selectedCourse[0].lessons.filter(
        lesson => lesson.alreadyFinished === true,
      );
      const watchedCoursesPercentage =
        (finishedCourses.length * 100) / selectedCourse[0].lessons.length;
      setCourseProgress(
        watchedCoursesPercentage ? Math.round(watchedCoursesPercentage) : 0,
      );
    }
  }, [trailId, courseId]);

  useEffect(() => {
    handleCoursesLoad();
  }, [trailId, courseId]);

  const handleKeepWatchingCard = useCallback(
    async (trailProp: Trail) => {
      const user: User = JSON.parse(
        localStorage.getItem('exeltis-user') as string,
      );
      const course = trailProp.courses.find(
        course => course.course_id === courseId,
      );

      const lastLessons = course?.lessons.filter(
        lesson =>
          lesson.alreadyFinished === false && lesson.alreadyStarted === false,
      );

      if (lastLessons && lastLessons.length) {
        setCourseData({
          progress: lastLessons[0].content_view
            ? completePercentageInVideo(
                lastLessons[0].duration,
                lastLessons[0].content_view,
              )
            : 0,
          userName: user.name,
          titleCourse: lastLessons[0]?.title,
          thumbnail: lastLessons[0]?.thumbnail,
          lessonId: lastLessons[0]?.content_id,
        });

        setShowKeepWatchingCard(true);
      } else {
        setShowKeepWatchingCard(false);
      }
    },
    [courseId],
  );

  const handleNextModule = (): void => {
    if (allModules.length === position + 1) {
      return;
    }

    history.push(
      `/meus-cursos/trilha/${trailId}/curso/${
        allModules[position + 1].course_id
      }`,
    );
  };

  const handlePreviousModule = (): void => {
    if (position === 0) {
      return;
    }

    history.push(
      `/meus-cursos/trilha/${trailId}/curso/${
        allModules[position - 1].course_id
      }`,
    );
  };

  const handleReturnImg = (): string => {
    switch (position) {
      case 0:
        return WomanImg1;

      case 1:
        return WomanImg2;

      case 2:
        return WomanImg3;

      case 3:
        return WomanImg4;

      default:
        return '';
    }
  };

  const handleNextModuleTitle = (): string => {
    switch (position) {
      case 0:
        return (
          'Módulo ' + (position + 2) + ' - ' + allModules[position + 1].title
        );

      case 1:
        return (
          'Módulo ' + (position + 2) + ' - ' + allModules[position + 1].title
        );

      case 2:
        return (
          'Módulo ' + (position + 2) + ' - ' + allModules[position + 1].title
        );

      case 3:
        return '';

      default:
        return '';
    }
  };

  interface LessonContentDTO {
    lesson: Content
    author: Content
  }

  const lessonContents: LessonContentDTO[] = []

  lessons.map(async lesson => {
    authorInfo?.map(async author => {
      if(lesson.content_id === author.content_id) {
        const lessonContent: LessonContentDTO = {
          lesson: lesson,
          author: author
        }

        lessonContents.push(lessonContent)
      }
    })
  })

  return !isLoading ? (
    <ModulesContentWrapper>
      <ModulesListContainer>
        <img src={handleReturnImg()} alt="woman period" className="woman-img" />

        <ModuleSelectorTitle>
          <div className="title">
            <div>
              <h1>{'Módulo ' + (position + 1)}</h1>
              <h4>{moduleData?.title}</h4>
            </div>

            <div className="arrows">
              <img
                src={position === 0 ? ArrowRetracted : ArrowExpanded}
                alt="arrow retracted"
                className={position !== 0 ? 'close' : ''}
                onClick={handlePreviousModule}
              />

              <img
                src={position === 3 ? ArrowRetracted : ArrowExpanded}
                alt="arrow expanded"
                className={position === 3 ? 'close' : ''}
                onClick={handleNextModule}
              />
            </div>
          </div>

          <div className="progress-bar-module">
            <h4>aulas</h4>

            <div className="bar">
              <ProgressBar progress={courseProgress} hasTitle />
            </div>
          </div>

          {showKeepWatchingCard ? (
            <KeepWatchingCard
              trailId={trailId}
              courseId={courseId}
              lessonId={courseData?.lessonId}
              titleCourse={courseData?.titleCourse}
              userName={courseData?.userName}
              thumbnail={courseData?.thumbnail}
              progress={courseData?.progress}
              closeCard={() => setShowKeepWatchingCard(false)}
            />
          ) : null}

          <div className="courses">
            {lessons && lessons.length && lessonContents
              ? lessons.map((lesson, index: number) => (
                  <React.Fragment key={index}>
                    {index === 4 ? <Banner withText product={product} /> : null}
                    <CourseCard
                      courseId={courseId}
                      trailiId={trailId}
                      lessonId={lessonContents[index]?.lesson.content_id}
                      title={lessonContents[index]?.lesson.title}
                      thumbnail={lessonContents[index]?.lesson.thumbnail}
                      profissional_avatar={
                        lessonContents[index]?.author.author[0].avatar_url
                      }
                      profissional_name={
                         lessonContents[index]?.author.author[0].name
                      }
                      profissional_specialty={
                         lessonContents[index]?.author.author[0].info?.specialty
                      }
                      progress={
                        lesson.content_view
                          ? completePercentageInVideo(
                              lesson.duration,
                              lesson.content_view,
                            )
                          : 0
                      }
                    />
                  </React.Fragment>
                ))
              : null}
          </div>

          <div className="paginator">
            <h4>
              {allModules && allModules.length ? handleNextModuleTitle() : null}
            </h4>

            {position !== 3 && (
              <img
                src={ArrowExpanded}
                alt="arrow expanded"
                onClick={handleNextModule}
              />
            )}
          </div>

          <Footer
            backgroundColor={'var(--default-background-color)'}
            hasLogo={false}
          />
        </ModuleSelectorTitle>
      </ModulesListContainer>
    </ModulesContentWrapper>
  ) : (
    <Loading />
  );
};

export default ModulesContent;
