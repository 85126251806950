import styled from 'styled-components';

export const VerifyCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 40%;
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-secondary-background-color);
  padding-bottom: 40px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .img-1,
    .img-2,
    .back-arrow,
    .flower,
    .flower-1,
    .flower-2,
    .flower-3,
    .flower-4,
    .flower-5,
    .flower-6,
    .flower-7,
    .flower-8 {
      display: none;
    }
  }

  .footer-login {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-left: 80px;
    position: relative;

    @media (max-width: 768px) {
      display: none;
    }

    .img-1 {
      width: 120px;
      margin-left: 80px;
      margin-top: -50px;
    }

    .img-2 {
      width: 280px;
      margin-left: 80px;
      margin-top: 90px;
    }

    .back-arrow {
      width: 50px;
      z-index: 2;
      cursor: pointer;
    }

    .flower {
      position: absolute;
      background: transparent;
      opacity: 0.2;
    }

    .flower-1 {
      top: -70px;
      left: -80px;
    }

    .flower-2 {
      top: -75px;
      left: 100px;
    }

    .flower-3 {
      top: 120px;
      left: 250px;
    }

    .flower-4 {
      top: -40px;
      left: 380px;
    }

    .flower-5 {
      top: 250px;
      left: 220px;
    }

    .flower-6 {
      top: 180px;
      left: 440px;
    }

    .flower-7 {
      top: 400px;
      left: 270px;
    }

    .flower-8 {
      top: 370px;
      left: 340px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  .space-text {
    margin-bottom: 40px;
  }

  .space-text-2 {
    margin-bottom: 35px;

    h5 {
      margin-bottom: 5px;
    }
  }

  h1 {
    font-size: var(--font-larger);
    text-transform: uppercase;
    color: var(--color-black);
    margin-bottom: 32px;
  }

  h5 {
    font-size: var(--font-medium);
    color: var(--color-black);
    margin-bottom: 54px;
    line-height: 37px;

    span {
      font-family: 'Nunito Sans ExtraBold';
      font-weight: 900;
      font-size: var(--font-medium);
      color: var(--color-black);
    }
  }

  h4 {
    color: var(--color-black);
    font-size: var(--font-large);
  }
  > p {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .container-btns {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
    margin-top: 80px;
    p {
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
      color: var(--secondary-color-light);
    }
  }
`;

export const CodeInput = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  input[type='number'] {
    text-align: center;
    width: 64px;
    height: 80px;
    border: 1.5px solid var(--secondary-color);
    color: var(--secondary-color);
    outline: none;

    position: relative;
    z-index: 99999;

    :focus {
      border: 2px solid var(--third-color-dark);
    }
    @media (min-width: 998px){
      font-size: 38px;
    }
    @media (max-width: 997px){
      width: 30px;
      height: 40px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media (max-width: 997px){
    gap: 10px;
    justify-content: space-around;
    max-width: 400px;
    margin: 0 auto;
  }
`;
