import React, { useState, useEffect } from 'react';
import { BlankBtn, EmptyBtn, SmallBtn } from 'components/Buttons';
import { Input } from 'components';
import {
  changeUserInfo,
  changeUserAvatar,
  setForgotPassword as forgotPasswordService,
  setConfirmPassword as confirmPasswordService,
} from 'services/user';
import { User } from 'services/user/models/user-interface';
import { maskPhone } from 'helpers/masks';
import { toast } from 'react-toastify';

import {
  ProfileInfoContainer,
  InfoContainer,
  ProfileMenuWrapper,
  CodeInput,
} from './style';
import EditIcon from 'assets/icon/pencil-icon.svg';
import KeyIcon from 'assets/icon/key-icon.svg';
import CameraIcon from 'assets/icon/camera-icon.svg';
import CloseIcon from 'assets/icon/close-icon-full.svg';
import Avatar from 'assets/icon/avatar.svg';

interface IProfileInfo {
  user: User;
  reload: () => void;
}

const ProfileInfo: React.FC<IProfileInfo> = ({ user, reload }) => {
  const [newUsername, setNewUsername] = useState<string>('');
  const [newPhone, setNewPhone] = useState<string>('');
  const [showEditName, setShowEditName] = useState<boolean>(false);
  const [showEditPhone, setShowEditPhone] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [presentPassword, setPresentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [verifyCode, setVerifyCode] = useState<string>('');

  useEffect(() => {
    setNewUsername(user.name);
    setNewPhone(user.info.telephone);
  }, [user]);

  const handleChangeInputCode = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ): void => {
    if (event.target.value === '') {
      const removeChar = verifyCode.slice(0, index);

      setVerifyCode(removeChar);
      return;
    }

    setVerifyCode(verifyCode + event.target.value);
    document
      .getElementById(`input-verify-reset-code-number-${index + 1}`)
      ?.focus();

    if (index === 5) {
      document
        .getElementById(`input-verify-reset-code-number-${index}`)
        ?.blur();
    }
  };

  const handleOpenChangePassword = async (): Promise<void> => {
    await forgotPasswordService(user.email).then(response => {
      if (response) {
        setOpenDrawer(true);
        toast.success('Foi enviado um código de verificação para o seu email');
      }
    });
  };

  const handlePasswordReset = async (): Promise<void> => {
    if (newPassword !== repeatPassword) {
      toast.error('Senhas não coincidem');
      return;
    }

    if (!verifyCode) {
      toast.error('Preencha o código de verificação!');
      return;
    }

    const data = {
      email: user.email,
      password: newPassword,
      code: verifyCode,
    };

    await confirmPasswordService(data).then(response => {
      if (response) {
        toast.success('Senha alterada com sucesso!');
        handleResetFields();
        setOpenDrawer(false);
      }
    });
  };

  const handleResetFields = (): void => {
    setPresentPassword('');
    setNewPassword('');
    setRepeatPassword('');
    setVerifyCode('');
  };

  const handleChangeUserInfo = async (): Promise<void> => {
    const userInfo = {
      name: newUsername,
      info: {
        telephone: newPhone,
      },
    };

    await changeUserInfo(userInfo).then(response => {
      if (response) {
        localStorage.setItem('exeltis-user', JSON.stringify(response));
      }

      reload();
      setShowEditName(false);
      setShowEditPhone(false);
    });
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault();
    if (!event.target.files) return;

    let file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    await changeUserAvatar(formData).then(response => {
      if (response) {
        localStorage.setItem('exeltis-user', JSON.stringify(response));
        reload();
      }
    });
  };

  return (
    <>
      {openDrawer ? (
        <ProfileMenuWrapper isOpen={openDrawer}>
          <div className="close-icon">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => setOpenDrawer(false)}
            />
          </div>

          <h1>Alterar minha Senha</h1>

          <div className="form-password">
            <h5>Código de verificação</h5>

            <CodeInput>
              {Array(6)
                .fill(6)
                .map((_, index) => (
                  <input
                    type="number"
                    name=""
                    id={`input-verify-reset-code-number-${index}`}
                    value={verifyCode.charAt(index)}
                    onChange={event => handleChangeInputCode(event, index)}
                  />
                ))}
            </CodeInput>

            <Input
              label={'Senha atual'}
              type="password"
              onChange={event => setPresentPassword(event.target.value)}
              value={presentPassword}
              id={'present-password-input'}
            ></Input>

            <Input
              label={'Nova senha'}
              type="password"
              onChange={event => setNewPassword(event.target.value)}
              value={newPassword}
              id={'new-password-input'}
            ></Input>

            <Input
              label={'Confirme a nova senha'}
              type="password"
              onChange={event => setRepeatPassword(event.target.value)}
              value={repeatPassword}
              id={'repeat-password-input'}
            ></Input>
          </div>

          <div className="btn-component">
            <EmptyBtn onClick={handleResetFields}>CANCELAR</EmptyBtn>
            <SmallBtn onClick={handlePasswordReset}>Salvar</SmallBtn>
          </div>
        </ProfileMenuWrapper>
      ) : null}

      <ProfileInfoContainer>
        <h1>meu perfil</h1>

        <InfoContainer>
          <div className="img-container-input">
            <img
              src={!!user.info.avatar ? user.info.avatar : Avatar}
              alt="user"
              className="avatar"
            />

            <img
              src={CameraIcon}
              alt="camera"
              className="camera"
              onClick={() => {
                document.getElementById('input-file-user-avatar')?.click();
              }}
            />

            <input
              type="file"
              id="input-file-user-avatar"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleAvatarChange(event)
              }
            />
          </div>

          <div className="info-user">
            <h1>
              {showEditName ? (
                <div className="input-display">
                  <input
                    type="text"
                    className="input-name"
                    value={newUsername}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewUsername(event.target.value)
                    }
                  />

                  <div className="container-btn">
                    <EmptyBtn onClick={() => setShowEditName(false)}>
                      CANCELAR
                    </EmptyBtn>
                    <SmallBtn onClick={handleChangeUserInfo}>Salvar</SmallBtn>
                  </div>
                </div>
              ) : (
                <>
                  {user?.name}{' '}
                  <img
                    src={EditIcon}
                    alt="edit"
                    className="edit-icon"
                    onClick={() => setShowEditName(true)}
                  />
                </>
              )}
            </h1>

            <h6>{user?.email}</h6>

            <div className="edit-phone">
              {showEditPhone ? (
                <div className="input-display">
                  <input
                    type="text"
                    className="input-phone"
                    value={newPhone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewPhone(maskPhone(event.target.value))
                    }
                  />

                  <div className="container-btn">
                    <EmptyBtn onClick={() => setShowEditPhone(false)}>
                      CANCELAR
                    </EmptyBtn>
                    <SmallBtn onClick={handleChangeUserInfo}>Salvar</SmallBtn>
                  </div>
                </div>
              ) : (
                <>
                  <span>{maskPhone(user?.info.telephone)}</span>

                  <img
                    src={EditIcon}
                    alt="edit"
                    className="edit-icon"
                    onClick={() => setShowEditPhone(true)}
                  />
                </>
              )}
            </div>
          </div>

          <div className="btn-password">
            <BlankBtn onClick={handleOpenChangePassword}>
              <img src={KeyIcon} alt="key" /> alterar minha senha
            </BlankBtn>
          </div>
        </InfoContainer>

        <div className="divider"></div>
      </ProfileInfoContainer>
    </>
  );
};

export default ProfileInfo;
