import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  FooterWrapper,
  FooterSocialIconsWrapper,
  FooterNavInfo,
} from './style';
import LogoExeltisSmall from 'assets/logo/logo-exeltis-small.svg';
import CareIcon from 'assets/icon/care-icon.svg';
import YoutubeIcon from 'assets/icon/youtube-icon.svg';
import FacebookIcon from 'assets/icon/facebook-icon-2.svg';
import InstagramIcon from 'assets/icon/instagram-icon.svg';
import TelegramIcon from 'assets/icon/telegram-icon.svg';

interface IFooter {
  backgroundColor: string;
  hasLogo: boolean;
}

const Footer: React.FC<IFooter> = ({ backgroundColor, hasLogo }) => {

  return (
    <FooterWrapper style={{ backgroundColor: backgroundColor }}>
      {hasLogo && (
        <Link to="/">
          <img src={LogoExeltisSmall} alt="exeltis" />
        </Link>
      )}

      <FooterSocialIconsWrapper>
        <a
          href="https://mulheregestacao.com.br/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={CareIcon} alt="care" />
        </a>

        <a
          href="https://www.youtube.com/channel/UCIqmGUeQa39jrAdd_5uW-Kw"
          target="_blank"
          rel="noreferrer"
        >
          <img src={YoutubeIcon} alt="youtube" />
        </a>

        <a
          href="https://www.facebook.com/regenesisbrasil"
          target="_blank"
          rel="noreferrer"
        >
          <img src={FacebookIcon} alt="facebook" />
        </a>

        <a
          href="https://www.instagram.com/regenesisbrasil/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={InstagramIcon} alt="instagram" />
        </a>

        <a
          href="https://t.me/mulheregestacao?utm_medium=social&utm_source=linktree&utm_campaign=grupo+do+telegram%3A+mulher+e+gesta%C3%A7%C3%A3o+by+regenesis"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TelegramIcon} alt="telegram" />
        </a>
      </FooterSocialIconsWrapper>

      <FooterNavInfo>
        <Link to="/privacidade">Política de privacidade</Link>

        <Link to="/termos">Termo de Uso</Link>

        <Link to="/faq">Perguntas frequentes</Link>

        <Link to="/fale-conosco">Fale com a gente</Link>
      </FooterNavInfo>
    </FooterWrapper>
  );
};

export default Footer;
