import React, { useState, useEffect } from 'react';
import {
  createNewCommentInContentByUser,
  deleteCommentByCommentId,
} from 'services/comments';
import { SmallBtn, EmptyBtn } from 'components/Buttons';
import { compareDate } from 'helpers/formatDate';
import Comment from 'services/comments/models/comments-interface';
import { User } from 'services/user/models/user-interface';
import ResponseContent from './ResponseContent';
import DeleteIcon from '../../../../assets/icon/delete.png';
import {
  CommentsContentWrapper,
  CommentCardContainer,
  CommentFormContainer,
} from './style';

import UserIcon from '../../../../assets/icon/user-icon.svg';

interface IComments {
  comments: Comment[];
  lessonId: string;
  reload: () => void;
}

const CommentsContent: React.FC<IComments> = ({
  comments,
  lessonId,
  reload,
}) => {
  const [commentText, setCommentText] = useState<string>('');
  const [showBtnsForm, setShowBtnsForm] = useState<boolean>(false);
  const [user, setUser] = useState<User>({} as User);
  const [deleteComment, setDeleteComment] = useState<String>('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

    setUser(user);
  }, []);

  const handleCancelFormBtn = (): void => {
    setShowBtnsForm(false);
    setCommentText('');
  };

  const handleCreateNewComment = async (
    comment: string,
    id: string,
    response_id: string,
  ): Promise<void> => {
    if (!comment || !id) {
      return;
    }

    const data = {
      description: comment,
      reference_id: id,
      comment_reference_id: response_id !== '' ? response_id : undefined,
    };

    await createNewCommentInContentByUser(data).then(response => {
      if (response) {
        handleCancelFormBtn();
        reload();
      }
    });
  };

  const handleDeleteComment = async (comment_id: string) => {
    setDeleteComment(comment_id);
    await deleteCommentByCommentId(comment_id);
  };

  const isAdmin = user?.roles?.includes('Admin');
  return (
    <CommentsContentWrapper>
      <h1>Envie seu comentário</h1>

      <h6>
        {comments && comments?.length > 1
          ? comments?.length + ' comentários'
          : comments?.length + ' comentário'}
      </h6>

      <CommentFormContainer isResponseComponent={false}>
        <div className="input-container">
          <img
            src={user?.info?.avatar ? user?.info?.avatar : UserIcon}
            alt="person"
          />

          <textarea
            placeholder="Faça um comentário"
            value={commentText}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setCommentText(event.target.value)
            }
            onFocus={() => setShowBtnsForm(true)}
          />
        </div>

        {showBtnsForm ? (
          <div className="btns-container">
            <EmptyBtn onClick={handleCancelFormBtn}>cancelar</EmptyBtn>

            <SmallBtn
              onClick={() => handleCreateNewComment(commentText, lessonId, '')}
            >
              perguntar
            </SmallBtn>
          </div>
        ) : null}
      </CommentFormContainer>

      {comments && comments.length
        ? comments.map((comment, index, array) => {
            if (deleteComment === comment?.comment_id) {
              return null;
            } else {
              return (
                <CommentCardContainer key={index}>
                  <div className="container">
                    <img
                      src={
                        comment.user_avatar_url
                          ? comment?.user_avatar_url
                          : UserIcon
                      }
                      alt="person"
                      className="profile-avatar"
                    />

                    <div className="comment-card">
                      <div className="title">
                        <h2>{comment?.username}</h2>

                        <span>{compareDate(comment?.created_at)}</span>
                        {isAdmin && (
                          <button
                            onClick={() =>
                              handleDeleteComment(comment?.comment_id)
                            }
                          >
                            <img src={DeleteIcon} alt="Excluir" />
                          </button>
                        )}
                        {!isAdmin && comment?.user_id == user?.user_id && (
                          <button
                            onClick={() =>
                              handleDeleteComment(comment?.comment_id)
                            }
                          >
                            <img src={DeleteIcon} alt="Excluir" />
                          </button>
                        )}
                      </div>

                      <p>{comment?.description}</p>
                    </div>
                  </div>

                  <ResponseContent
                    comment={comment}
                    handleCreateNewComment={handleCreateNewComment}
                    lessonId={lessonId}
                    user={user}
                  />

                  {array.length !== index + 1 ? (
                    <div className="spacer"></div>
                  ) : null}
                </CommentCardContainer>
              );
            }
          })
        : null}
    </CommentsContentWrapper>
  );
};

export default CommentsContent;
