import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 45px;
  @media screen and (min-width: 1186px) {
    .content-accordion {
      display: none;
    }
  }

  @media screen and (max-width: 1185px) {
    .content-accordion {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
`;
