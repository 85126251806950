import styled from 'styled-components';
import BackgroundImg from 'assets/images/background-landing.png';

export const LandingPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url(${BackgroundImg});
  background-size: contain;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    background-size: auto;
  }
`;

export const LandingPageResume = styled.div`
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0),
    var(--default-background-color) 18%
  );

  min-height: 300px;
  margin-top: 40px;
  padding-top: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    padding-left: 2%;
    padding-right: 2%;
    background-color: var(--default-background-color);
    margin-top: 0;
  }

  h1 {
    font-weight: 800;
    font-size: var(--font-larger);
    text-transform: uppercase;
    color: var(--primary-color);

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    width: 600px;
    text-align: center;
    margin-top: 12px;
    font-size: var(--font-large);

    @media (max-width: 768px) {
      width: auto;
    }
  }

  .options-info {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    align-items: center;
    height: 280px;
    justify-content: space-evenly;

    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      margin-top: 50px;
      gap: 50px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      height: 230px;

      @media (max-width: 768px) {
        width: auto;
        height: auto;
      }

      img {
        width: 130px;
        height: 130px;
      }

      .woman-baby {
        width: 200px;
        height: 140px;
      }

      span {
        margin-top: 30px;
        color: var(--secondary-color-light);
        font-weight: 800;
        text-align: center;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 2px;
      }
    }
  }
`;

export const LandingPageButtonSubscribe = styled.div`
  background-color: var(--default-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;

  button {
    background-color: var(--primary-color);
    min-width: 500px;
    height: 68px;
    font-size: var(--font-larger);

    @media (max-width: 768px) {
      min-width: 10px;
      width: 90%;
      height: 45px;
      font-size: var(--font-large);
    }
  }

  p {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-weight: 800;
    margin-top: 5px;
    font-size: var(--font-small);
  }
`;
