import Header from 'components/Header';
import ModulesContent from './components/ModulesContent';
import smoothscroll from 'smoothscroll-polyfill';
import { Footer } from 'components';

import { Page } from './style';

smoothscroll.polyfill();
function ContactPage() {
  return (
    <Page>
      <Header reduceHeader={false} showHeaderNotLogged />

      <ModulesContent />
      <Footer backgroundColor={'transparent'} hasLogo />
    </Page>
  );
}

export default ContactPage;
