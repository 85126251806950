import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 38%;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: white;
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .img-1,
    .img-2,
    .back-arrow,
    .flower,
    .flower-1,
    .flower-2,
    .flower-3,
    .flower-4,
    .flower-5,
    .flower-6,
    .flower-7,
    .flower-8 {
      display: none;
    }
  }

  .footer-signup {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-left: 80px;
    position: relative;

    @media (max-width: 768px) {
      display: none;
    }

    .img-1 {
      width: 120px;
      margin-left: 80px;
      margin-top: -50px;
    }

    .img-2 {
      width: 280px;
      margin-left: 80px;
      margin-top: 90px;
    }

    .back-arrow {
      width: 50px;
      z-index: 2;
      cursor: pointer;
    }

    .flower {
      position: absolute;
      background: transparent;
      opacity: 0.2;
    }

    .flower-1 {
      top: -70px;
      left: -80px;
    }

    .flower-2 {
      top: -75px;
      left: 100px;
    }

    .flower-3 {
      top: 120px;
      left: 250px;
    }

    .flower-4 {
      top: -40px;
      left: 380px;
    }

    .flower-5 {
      top: 250px;
      left: 220px;
    }

    .flower-6 {
      top: 180px;
      left: 440px;
    }

    .flower-7 {
      top: 400px;
      left: 270px;
    }

    .flower-8 {
      top: 370px;
      left: 340px;
    }
  }
`;
