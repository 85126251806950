import styled, { css, keyframes } from 'styled-components';

const dropdown = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

interface IDropdown {
  open: boolean;
  typeMenu?: string;
}

export const Container = styled.nav`
  display: flex;
  align-items: center;
  gap: 28px;

  span {
    color: var(--secondary-color-light);
    font-family: 'Nunito Sans SemiBold';
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: var(--font-larger);
  }

  .my-specialists {
    display: inline-block;
    position: relative;

    .selected {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .arrow-speciality {
      height: 40px;
      width: 30px;
      transform: rotate(270deg);
      margin-left: 14px;
    }

  }

  @media (max-width: 1280px) {
    span {
      font-size: 20px;
    }
  }
`;

export const Dropdown = styled.div<IDropdown>`
  ${({ open }) => css`
    display: ${open ? 'block' : 'none'};
    animation: ${dropdown} 0.6s;
  `}

  ${({ typeMenu }) => css`
    top: ${typeMenu === 'course' ? '40px' : '50px'};
    left: 0;

    li {
      justify-content: ${typeMenu === 'course' ? 'center' : 'start'};
    }
  `}

  position: absolute;
  background-color: var(--color-menu);
  width: 260px;
  border-radius: 8px;
  box-shadow: 0px 24px 64px rgba(45, 48, 58, 0.13);
  border: 1px solid rgba(228, 108, 154, 0.39);
  padding: 10px 30px;
  z-index: 4;

  .triangle {
    position: absolute;
    height: auto;
    z-index: 5;
    top: -15px;
    left: 203px;
    transform: rotate(0deg);
  }

  li {
    display: flex;
    align-items: center;
    list-style-type: none;
    text-transform: uppercase;
    color: var(--secondary-color);
    font-family: 'Nunito Sans SemiBold';
    cursor: pointer;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;

    :nth-child(n) {
      border-bottom: 1px solid #e5e5ed;
      width: 100%;
      margin: 15px 0;
      padding-bottom: 15px;
    }
    :last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  .selected-specialty {
    color: var(--primary-color-light);
  }
`;
