import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    cursor: pointer;
    padding: 2px;
  }

  .to-right {
    transform: rotate(180deg);
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--third-color);
    border-radius: 50%;
    border: 2px solid var(--third-color);
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .list-pages {
    display: flex;
    gap: 12px;
    margin: 0 15px;
  }
`;
