import styled from 'styled-components';

const ShareOptionsContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
`;

const LinkInput = styled.input`
  padding: 5px;
  background: #eee;
  height: 40px;
  width: 350px;
  border: solid 1px #ddd;
  border-radius: 5px;
  outline: none;
`;

const ShareButtonsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const CopyLinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  background: #bbb;
`;

export {
  ShareOptionsContainer,
  LinkInput,
  ShareButtonsContainer,
  CopyLinkButton,
};
