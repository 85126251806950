import React from 'react';
import { TextArea, InputWrapper } from '../style';

interface IInputTextAreaProps {
  value: string | number | readonly string[] | undefined;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  type: string;
  id?: string;
  label: string;
  alert?: string;
  width?: string;
}

const TextAreaUI: React.FC<IInputTextAreaProps> = ({
  children,
  onChange,
  value,
  id,
  onFocus,
  label,
  alert,
}) => {
  return (
    <>
      <InputWrapper>
        <>
          <label htmlFor={id}>{label}</label>
          <span className="alert">{alert}</span>
          <TextArea rows={3} id={id} value={value} onChange={onChange} onFocus={onFocus}>
            {children}
          </TextArea>
        </>
      </InputWrapper>
    </>
  );
};

export default TextAreaUI;
