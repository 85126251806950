import { Certificate } from 'services/certificate';
import CertificateTemplate from '../templates/certificate';
import { pdf } from '@react-pdf/renderer';

const generateCertificate = (certificateData: Certificate) => {
  const ExeltisCertificateTemplate = (
    <CertificateTemplate certificateData={certificateData} />
  );

  return pdf(ExeltisCertificateTemplate).toBlob();
};

export { generateCertificate };
