import React from 'react';

import { CircularProgressWrapper } from './style';

interface ICircularProgress {
  stroke: number;
  radius: number;
  progress: number;
  strokeColor: string;
}

const CircularProgress: React.FC<ICircularProgress> = ({
  progress,
  stroke,
  radius,
  strokeColor,
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <CircularProgressWrapper>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          strokeDasharray={circumference + ' ' + circumference}
          strokeWidth={stroke}
          stroke={strokeColor}
          style={{ strokeDashoffset }}
          fill="transparent"
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </CircularProgressWrapper>
  );
};

export default CircularProgress;
