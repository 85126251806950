import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import { Certificate } from 'services/certificate';
import { formattedDate } from 'helpers/formatDate';

import ExeltisLogo from '../assets/logo/exeltis-logo.png';
import RegenesisLogo from '../assets/logo/regenesis-logo.png';
import CertificateDetails from '../assets/details/certificate-detail.png';
import FlowerDetails from '../assets/details/flower-detail.png';

// @ts-ignore
import NunitoSans from 'assets/font/nunitosans/NunitoSans-Regular.ttf';
// @ts-ignore
import NunitoSansExtraBold from 'assets/font/nunitosans/NunitoSans-ExtraBold.ttf';
// @ts-ignore
import Cookie from 'assets/font/cookie/Cookie-Regular.ttf';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '5pt 50pt',
    position: 'relative',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '16pt',
  },
  textContainerDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '35pt',
  },
  textContainerWatched: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  normalText: {
    fontFamily: 'Cookie',
    fontSize: '40pt',
    color: '#706A6A',
    textAlign: 'center',
  },
  nameUserText: {
    textAlign: 'center',
    position: 'relative',
    left: '0',
    fontFamily: 'Nunito Sans',
    fontSize: '68pt',
    color: '#B92481',
    fontWeight: 900,
  },
  detailsText: {
    fontFamily: 'Nunito Sans',
    fontSize: '15pt',
    color: '#000000',
  },
  detailsTextBold: {
    fontFamily: 'Nunito Sans Extra Bold',
    fontSize: '15pt',
    color: '#000000',
  },
  logoRegenesis: {
    width: '100pt',
    height: '80pt',
    marginTop: '15pt',
    alignSelf: 'center',
  },
  logoExeltis: {
    width: '100pt',
    marginTop: '90pt',
    alignSelf: 'center',
  },
  certificateDetails: {
    left: '-10%',
    position: 'absolute',
    width: '600pt',
    height: '99%',
    top: '0',
    opacity: 0.6,
  },
  certificateRibbon: {
    position: 'absolute',
    width: '200pt',
    height: '400pt',
    right: '0',
    top: '8%',
    opacity: 0.6,
  },
});

Font.register({
  family: 'Cookie',
  src: Cookie,
});

Font.register({
  family: 'Nunito Sans',
  src: NunitoSans,
});

Font.register({
  family: 'Nunito Sans Extra Bold',
  src: NunitoSansExtraBold,
});

interface ICertificateTemplate {
  certificateData: Certificate;
}

const CertificateTemplate: React.FC<ICertificateTemplate> = ({
  certificateData,
}) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image src={RegenesisLogo} style={styles.logoRegenesis} />
        <Image src={CertificateDetails} style={styles.certificateDetails} />
        <Image src={FlowerDetails} style={styles.certificateRibbon} />

        <View style={styles.textContainer}>
          <Text style={styles.normalText}>Certificamos que:</Text>
          <Text style={styles.nameUserText}>{certificateData.user_name}</Text>
          <Text style={styles.normalText}>
            participou do Curso de Gestante Regenesis
          </Text>
        </View>

        <View style={styles.textContainerDetails}>
          <Text style={styles.detailsText}>Carga horária realizada: </Text>
          <Text style={styles.detailsTextBold}>
            {Math.round(certificateData.watched_time)} minutos
          </Text>
        </View>

        <View style={styles.textContainerWatched}>
          <Text style={styles.detailsText}>Concluído em: </Text>
          <Text style={styles.detailsTextBold}>
            {formattedDate(certificateData.emitted_at)}
          </Text>
        </View>

        <Image src={ExeltisLogo} style={styles.logoExeltis} />
      </Page>
    </Document>
  );
};

export default CertificateTemplate;
