import styled from 'styled-components';

export const SupportMaterialWrapper = styled.div`
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  .title-support {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    padding: 0 5%;

    div {
      display: flex;
      gap: 15px;

      img {
        cursor: pointer;
      }
    }

    h1 {
      font-family: 'Nunito Sans Light';
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-size: var(--font-largest);
      font-weight: 300;
    }

    .close {
      transform: rotate(180deg);
    }
  }

  .slider-wrapper {
    padding: 0 5%;

    .support-card {
      position: relative;
      cursor: pointer;

      img {
        width: 350px;
        height: 400px;
        border-radius: 8px;
        object-fit: cover;
      }

      .no-image {
        width: 350px;
        height: 400px;
        border-radius: 8px;
        background-color: rgba(235, 208, 176, 0.47);
      }
    }

    div.slick-list {
      padding-left: 18px;
    }

    .info-support-card {
      position: absolute;
      background: var(--third-color);
      top: 160px;
      left: -15px;
      width: 230px;
      height: 200px;
      border-radius: 8px;
      padding: 18px 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      h2 {
        font-family: 'Nunito Sans Extra Light';
        color: white;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 100;
      }

      span {
        font-family: 'Nunito Sans Light';
        color: white;
        text-transform: uppercase;
        font-weight: 100;
      }

      p {
        font-family: 'Nunito Sans Light';
        color: white;
        font-size: var(--font-large);
        font-weight: 100;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
