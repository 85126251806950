import styled, { css } from 'styled-components';

interface IWelcomeTitle {
  hasCourse: boolean;
}

export const WelcomeTitleContainer = styled.div<IWelcomeTitle>`
  ${({ hasCourse }) => css`
    grid-template-columns: ${hasCourse ? '40% 60%' : '1fr 1fr'};
    padding: ${hasCourse ? '0 4%' : '0 10%'};
  `}

  display: grid;
  margin-top: 62px;
  column-gap: 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  .title-text {
    .bar {
      margin-top: 40px;
    }

    h1 {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15ch;
      font-family: 'Cookie';
      font-weight: 400;
      color: var(--primary-color-light);
      font-size: 64px;
      margin-bottom: 10px;
    }

    h3 {
      font-family: 'Signika Medium';
      font-weight: 300;
      font-size: var(--font-large);
      margin-bottom: 15px;

      span {
        font-family: 'Signika Bold';
        font-size: var(--font-large);
      }
    }

    p {
      font-family: 'Signika Light';
      font-size: var(--font-medium);
      line-height: 28px;

      span {
        font-family: 'Signika Bold';
        font-size: var(--font-medium);
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 40px;
      h1 {
        font-size: 40px;
      }
    }
  }
`;

export const LastWatchedContainer = styled.div`
  background: rgba(255, 255, 255, 0.81);
  border: 1.8px solid rgba(128, 104, 218, 0.18);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  position: relative;
  height: 220px;
  margin-left: 100px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  .img-woman-period {
    transform: scaleX(-1);
  }

  .progress-container {
    position: absolute;

    .container {
      position: absolute;
      left: -110px;
      top: -2px;
    }

    .sm {
      display: none;

      @media (max-width: 768px) {
        display: initial;
        position: absolute;
        top: 35px;
        left: 110px;
      }
    }

    .xl {
      @media (max-width: 768px) {
        display: none;
      }
    }

    img {
      position: absolute;
      height: 270px;
      top: -32px;
      left: -30px;
      z-index: 3;

      @media (max-width: 768px) {
        top: 40px;
        left: 46px;
        height: 120px;
      }
    }
  }

  .watched-info {
    margin-left: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 22px 0;

    .container-btn {
      @media (max-width: 1250px) {
        button {
          width: 100%;
          font-size: var(--font-small);
        }
      }
    }

    @media (max-width: 768px) {
      margin-left: 150px;
    }

    h1 {
      color: var(--secondary-color-light);
      font-size: var(--font-large);
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: var(--font-medium);
      }
    }

    h4 {
      font-size: var(--font-medium);
      font-family: 'Nunito Sans ExtraBold';
      margin-bottom: -15px;

      @media (max-width: 768px) {
        font-size: var(--font-small);
      }
    }

    p {
      width: 80%;
      color: var(--color-text-card);
      font-size: var(--font-medium);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      @media (max-width: 768px) {
        font-size: var(--font-small);
      }
    }
  }
`;
