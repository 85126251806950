import styled from 'styled-components';

export const CourseCardWrapper = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 10px;
    color: var(--third-color-dark);
    font-family: 'Nunito Sans ExtraBold';
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ImgThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
  max-height: 214px;
  overflow: hidden;
  border-radius: 5px;
  .play-icon {
    position: absolute;
    top: 80px;
    left: 50%;
  }

  .thumbnail {
    width: 100%;
    border-radius: 5px;
  }

  .video-card-empty {
    width: 100%;
    height: 180px;
    border-radius: 5px;
    background-color: var(--primary-color);
    opacity: 0.05;
  }

  .bar {
    margin-top: -5px;
  }
`;

export const ProfissionalInfo = styled.div`
  display: flex;
  margin-top: 50px;

  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  .info {
    margin-left: 10px;

    h5 {
      font-family: 'Nunito Sans Light';
    }

    span {
      opacity: 0.5;
      font-family: 'Nunito Sans Light';
    }
  }
`;
