import httpClient from 'http-client';

interface UserSignup {
  email: string;
  password: string;
  name: string;
  telephone: string;
}

interface IVerifyCode {
  email: string;
  code: string;
}

const signup = async (data: UserSignup) => {
  const signupResponse = (await httpClient.post('/cognito/signup', data)).data;

  return signupResponse;
};

const verifyCode = async (data: IVerifyCode): Promise<boolean> => {
  const signupResponse: boolean = (
    await httpClient.post('/cognito/verify-code', data)
  ).data;

  return signupResponse;
};

const resendCode = async (email: string): Promise<boolean> => {
  const codeResponse: boolean = (
    await httpClient.post('/cognito/resend-code', {
      email,
    })
  ).data;

  return codeResponse;
};

export { signup, verifyCode, resendCode };
