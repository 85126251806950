import axios from 'axios';
import { toast } from 'react-toastify';
import ScrollToTop from 'helpers/scrollToTop';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('exeltis-api-token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  function (response) {
    //ScrollToTop();
    return response;
  },
  function (error) {
    if (
      error.response &&
      (error.response.status === 500 || error.response.status === 400)
    ) {
      toast.error(
        'Algo deu errado ao carregar essa solicitação. Tente novamente.',
      );
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem('exeltis-api-token');
      window.localStorage.removeItem('exeltis-user');
      window.location.href = window.location.origin + '/';
    } else {
      return Promise.reject(error);
    }
  },
);

export default httpClient;
