import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  max-width: 1500px;
  margin: 62px auto;
  padding: 10px 21px;

  // lg - xl

  @media only screen and (min-width: 1700px) and (max-width: 2000px) {
    padding: 10px 7%;
  }

  //sm - lg
  @media only screen and (min-width: 1280px) and (max-width: 1699px) {
    padding: 10px 10%;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1279px) {
    padding: 10px 80px;
  }
`;

//breakpoints

/*
xs: 0,
sm: 600,
md: 1000,
lg: 1280,
xl: 1700,
*/
