import httpClient from 'http-client';
import Info from './models/info-interface';

const getInfoByType = async (
  type: string,
  headers?: object,
): Promise<Array<Info>> => {
  const info = await httpClient.get<Array<Info>>('/home-info', {
    params: {
      type,
    },
    headers,
  });

  return (info || { data: {} }).data;
};

export { getInfoByType };
