import React, { useEffect, useState, useCallback, useMemo } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { useParams, RouteComponentProps, useLocation } from 'react-router-dom';
import {
  getCourseModule,
  getCourse,
  startTrail as startTrailService,
  startCourse as startCourseService,
  getContentLesson,
  startLesson as startLessonService,
  finishLesson as finishLessonService,
  finishTrail as finishTrailService,
  finishCourse as finishCourseService,
} from 'services/course';
import { getExtraMaterialByContent } from 'services/course/extra-material';
import { getAuthor } from 'services/profissionals';
import { getCommentsByContent } from 'services/comments';
import Content from 'services/course/models/content-interface';
import { ProgressBar, Dropdown, Footer, Banner, Loading } from 'components';
import { SmallBtn } from 'components/Buttons';
import Course from 'services/course/models/course-interface';
import Product from 'services/product/models/product-interface';
import Author from 'services/profissionals/models/author-interface';
import ExtraMaterial from 'services/course/models/extra-material-interface';
import { getUserProgress, IUserProgressTrail } from 'services/user';
import Comment from 'services/comments/models/comments-interface';
import Trail from 'services/course/models/trail-interface';

import CourseContent from './components/CourseContent';
import SideMenuCourse from './components/SideMenuCourse';
import CommentsContent from './components/CommentsContent';
import LessonDrawerMenu from './components/LessonDrawerMenu';
import ProgressCourse from './components/SideMenuCourse/components/ProgressCourse';
import MaterialCourse from './components/SideMenuCourse/components/MaterialCourse';
import { Header } from 'components';

import {
  CourseWrapper,
  CourseHeader,
  CourseModuleSelector,
  CourseModuleInfoWrapper,
  CourseInfoActions,
} from './style';
import LogoImg from 'assets/logo/logo-small.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import MenuIcon from 'assets/icon/menu-icon-bullet.svg';
import ShareIcon from 'assets/icon/share-icon.svg';
import CommentsIcon from 'assets/icon/comments-icon.svg';
import { showModal } from 'components/Modal';
import ShareOptions from 'components/ShareOptions';

interface CourseParams {
  trailId: string;
  courseId: string;
  lessonId: string;
}

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface ExtendedWindow extends Window {
  hasStartedContent: boolean;
  hasStartedModule: boolean;
  hasStartedTrail: boolean;
  hasFinishedContent: boolean;
  hasFinishedModule: boolean;
  hasFinishedTrail: boolean;
  trailId: string;
  courseId: string;
  lessonId: string;
}

declare let window: ExtendedWindow;

interface props extends RouteComponentProps {}
smoothscroll.polyfill();

const Lesson: React.FC<props> = ({ history }) => {
  const location = useLocation();
  const { courseId, lessonId, trailId } = useParams() as CourseParams;
  const [trail, setTrail] = useState<Trail>();
  const [course, setCourse] = useState<Course>();
  const [lesson, setLesson] = useState<Content>();
  const [allLesson, setAllLesson] = useState<Content[]>();
  const [sortedCourses, setSortedCourses] = useState<Array<Course>>([]);
  const [product, setProduct] = useState<Array<Product>>();
  const [author, setAuthor] = useState<Author>();
  const [extraMaterials, setExtraMaterials] = useState<Array<ExtraMaterial>>();
  const [openMenu, setOpenMenu] = useState<boolean>(true);
  const [comments, setComments] = useState<Array<Comment>>([]);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [overallProgress, setOverallProgress] = useState<number>(0);
  const [progressByCourse, setProgressByCourse] =
    useState<IUserProgressTrail>();
  const [reloadComment, setReloadComment] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [courseProgress, setCourseProgress] = useState<number>(0);

  const isLoading = useMemo(() => {
    return !course;
  }, [course]);

  useEffect(() => {
    (async () => {
      const localCourse = await getCourseModule(courseId);

      setProduct(localCourse.products);
    })();
  }, [courseId]);

  const openShareOptions = () => {
    showModal('Opções de Compartilhamento', <ShareOptions />);
  };

  const refreshCourse = useCallback(async () => {
    const localTrail = await getCourse(trailId);
    const localCourse = localTrail.courses.filter(
      course => course.course_id === courseId,
    );
    const localLesson = localCourse[0].lessons.filter(
      content => content.content_id === lessonId,
    );
    const localAuthorData = await getContentLesson(lessonId);
    let localAuthor;
    if (localAuthorData.authors[0].id !== undefined) {
      localAuthor = await getAuthor(localAuthorData.authors[0].id);
    }
    const localCoursesProgress = await getUserProgress(trailId);
    const localComments = await getCommentsByContent(lessonId);
    const localExtraMaterials = await getExtraMaterialByContent(lessonId);

    setSortedCourses(localTrail.courses);
    setCourse(localCourse[0]);
    setLesson(localLesson[0]);
    setAuthor(localAuthor);
    setExtraMaterials(localExtraMaterials);
    setProgressByCourse(localCoursesProgress);
    setComments(localComments);

    const finishedCourses = localCourse[0].lessons.filter(
      lesson => lesson.alreadyFinished === true,
    );
    const watchedCoursesPercentage =
      (finishedCourses.length * 100) / localCourse[0].lessons.length;

    setCourseProgress(watchedCoursesPercentage);

    if (localTrail && localTrail.trail_id) {
      const allContents = localTrail.courses
        .map(course => course.lessons)
        .flat();
      setTrail(localTrail);
      setAllLesson(allContents);
    }

    if (localTrail.user) {
      setOverallProgress(localTrail.user.progress);
    }
  }, [lessonId, trailId, courseId]);

  useEffect(() => {
    refreshCourse();
  }, [refreshCourse]);

  useEffect(() => {
    (async () => {
      const localComments = await getCommentsByContent(lessonId);

      setComments(localComments);
    })();
  }, [reloadComment, lessonId]);

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration / 1000;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const updateWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setProgress(eventParam);
    }
    return null;
  }, []);

  const getEventListeners = async (player: PlayerEventListener) => {
    if (
      window.location.href.endsWith(
        `trilha/${window.trailId}/curso/${window.courseId}/aula/${window.lessonId}`,
      )
    ) {
      if (lesson && lesson.content_id) {
        switch (player.event) {
          case 'onProgress':
            if (hasReachedCompletionTime(player.duration, player.eventParam)) {
              await finishContent();
            }

            updateWatchTime(player);
            break;

          case 'onStart':
            startContent();
            break;

          case 'onFinish':
            goToNextContent();
            break;

          default:
            break;
        }
      }
    }
  };

  const goToNextContent = () => {
    if (trail) {
      const allContents =
        trail.courses && trail.courses.length
          ? trail.courses.map(course => course.lessons).flat()
          : [];
      const actualContent = allContents.find(
        content =>
          content.content_id === window.lessonId &&
          content.course_id === window.courseId &&
          content.trail_id === window.trailId,
      );

      if (actualContent) {
        const indexOfActualContent = allContents.indexOf(actualContent);

        if (
          indexOfActualContent > -1 &&
          indexOfActualContent < allContents.length - 1
        ) {
          const nextContent = allContents[indexOfActualContent + 1];

          history.push(
            `/trilha/${nextContent.trail_id}/curso/${nextContent.course_id}/aula/${nextContent.content_id}`,
          );
        }
      }
    }
  };

  const menuDrawerGoToNextLesson = (lessonId: string) => {
    history.push(`/trilha/${trailId}/curso/${courseId}/aula/${lessonId}`);
  };

  const startContent = async () => {
    if (lesson && trail) {
      const actualContentModule = trail?.courses.find(
        course => course.course_id === courseId,
      );

      if (!window.hasStartedTrail && !trail?.alreadyStarted) {
        await startTrail();
      }

      if (
        actualContentModule &&
        !window.hasStartedModule &&
        !actualContentModule.alreadyStarted
      ) {
        try {
          actualContentModule.alreadyStarted = true;
          window.hasStartedModule = true;

          await startCourseService(
            lesson.course_id || '',
            lesson.trail_id || '',
          );
        } catch (error) {
          actualContentModule.alreadyStarted = false;
          window.hasStartedModule = false;
        }
      }

      if (!window.hasStartedContent && !lesson.alreadyStarted) {
        try {
          lesson.alreadyStarted = true;
          window.hasStartedContent = true;

          await startLessonService(
            lesson?.course_id || '',
            lesson?.trail_id || '',
            lesson?.content_id,
          );
        } catch (error) {
          lesson.alreadyStarted = false;
          window.hasStartedContent = false;
        }
      }

      setTrail({ ...trail });
    }
  };

  const startTrail = async () => {
    if (trail) {
      try {
        await startTrailService(trailId || '');

        trail.alreadyStarted = true;
        window.hasStartedTrail = true;

        const allContents =
          trail.courses && trail.courses.length
            ? trail.courses.map(course => course.lessons || []).flat()
            : [];
        if (allContents && allContents.length) {
          const firstContent = allContents[0];

          history.push(
            `/trilha/${firstContent.trail_id}/curso/${firstContent.course_id}/aula/${firstContent.content_id}`,
          );
        }

        setTrail({ ...trail });
      } catch (error) {}
    }
  };

  useEffect(() => {
    window.hasStartedModule = false;
    window.hasStartedTrail = false;
    window.hasStartedContent = false;
    window.hasFinishedTrail = false;
    window.hasFinishedContent = false;
    window.hasFinishedModule = false;

    if (trail && trail.trail_id) {
      window.hasFinishedTrail = !!(trail && trail.alreadyFinished);
      window.hasStartedTrail = !!(trail && trail.alreadyStarted);

      const foundCourse = trail.courses.find(
        course => course.course_id === courseId,
      );
      if (foundCourse) {
        window.hasFinishedModule = !!(
          foundCourse && foundCourse.alreadyFinished
        );
        window.hasStartedModule = !!(foundCourse && foundCourse.alreadyStarted);

        const foundContent = foundCourse.contents.find(
          content => content.content_id === lessonId,
        );
        if (foundContent) {
          window.hasFinishedContent = !!(
            foundContent && foundContent.alreadyFinished
          );
          window.hasStartedContent = !!(
            foundContent && foundContent.alreadyStarted
          );
        }
      }
    }
  }, [trailId, courseId, lessonId, trail]);

  const getSelectedContent = useCallback(() => {
    if (trail) {
      const allContents =
        trail.courses && trail.courses.length
          ? trail.courses.map(course => course.contents || []).flat()
          : [];
      const foundContent = allContents.find(
        content =>
          content.content_id === lessonId &&
          content.course_id === courseId &&
          content.trail_id === trailId,
      );

      if (foundContent && foundContent.content_id) {
        setLesson(foundContent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailId, lessonId, courseId]);

  const finishContent = async () => {
    if (trail && lesson) {
      if (!lesson.alreadyFinished && !window.hasFinishedContent) {
        try {
          window.hasFinishedContent = true;
          lesson.alreadyFinished = true;

          await finishLessonService(
            lesson?.course_id || '',
            lesson?.trail_id || '',
            lesson?.content_id,
          );

          lesson.alreadyFinished = true;
        } catch {}
      }

      const foundCourse = trail.courses.find(
        course => course.course_id === courseId,
      );
      if (foundCourse) {
        if (
          foundCourse.contents.every(content => content.alreadyFinished) &&
          !foundCourse.alreadyFinished &&
          !window.hasFinishedModule
        ) {
          try {
            window.hasFinishedModule = true;
            foundCourse.alreadyFinished = true;

            await finishCourseService(
              lesson?.course_id || '',
              lesson?.trail_id || '',
            );
          } catch {}
        }
      }

      if (
        trail.courses.every(course => course.alreadyFinished) &&
        !trail.alreadyFinished &&
        !window.hasFinishedTrail
      ) {
        try {
          trail.alreadyFinished = true;
          window.hasFinishedTrail = true;

          await finishTrailService(lesson?.trail_id || '');
        } catch {}
      }

      setTrail({ ...trail });
    }
  };

  useEffect(() => {
    window.trailId = trailId;
    window.courseId = courseId;
    window.lessonId = lessonId;
  }, [location]);

  useEffect(getSelectedContent, [getSelectedContent]);

  return !isLoading ? (
    <>
      {course && (
        <LessonDrawerMenu
          course={course}
          changeSelectedLesson={menuDrawerGoToNextLesson}
          isOpen={openDrawer}
          lessonId={lessonId}
          coursePosition={course.position}
          onClose={() => setOpenDrawer(false)}
        />
      )}

      <CourseWrapper isOpen={openDrawer}>
        <CourseHeader>
          <img
            src={LogoImg}
            alt="regenesis"
            onClick={() => history.push('/home')}
          />

          <div className="actual-header">
            <Header reduceHeader={false} showLogo={false} showHeaderNotLogged />
          </div>
        </CourseHeader>

        <CourseModuleInfoWrapper open={openMenu}>
          <CourseModuleSelector>
            <div
              className="module-selector"
              onClick={() => setOpenDropdown(menu => !menu)}
            >
              <h2>Módulo {course?.position + ' - ' + course?.title}</h2>

              <img src={BackArrow} alt="back arrow" className="arrow-down" />

              <Dropdown
                open={openDropdown}
                typeMenu={'course'}
                onClose={() => setOpenDropdown(false)}
                trailId={trailId}
                modules={sortedCourses}
                positionProps={{
                  left: '0',
                  top: '40px',
                }}
              />
            </div>

            <div className="lesson-selector">
              <img
                src={MenuIcon}
                alt="menu bullet"
                className="menu-bullet"
                onClick={() => setOpenDrawer(open => !open)}
              />
              {course && lesson ? (
                <h3>
                  {lesson.position + '/' + course.contents.length + ' - '}
                  {course.contents.length === 1 ? 'Aula' : 'Aulas'}
                </h3>
              ) : null}
              <img src={BackArrow} alt="back arrow" className="arrow-left" />
            </div>
          </CourseModuleSelector>

          <div className="course-title">
            <h1>{lesson?.title}</h1>
          </div>

          {lesson && course ? (
            <CourseContent
              lesson={lesson}
              course={course}
              allCourses={sortedCourses}
              trailId={trailId}
              getEventListeners={getEventListeners}
              progress={progress}
            />
          ) : null}

          <CourseInfoActions>
            <div className="first-row">
              <div
                className="info-author"
                onClick={() => history.push('/profissionais')}
              >
                {author?.avatar_url ? (
                  <img src={author.avatar_url} alt="medic author" />
                ) : null}

                <div>
                  <h1>{author?.name}</h1>
                  {author &&
                  author.info &&
                  author.info.specialty &&
                  author.info.specialty.length
                    ? author?.info.specialty.map((specialty, index, array) => (
                        <span key={index}>
                          {specialty}
                          {array.length !== index + 1 ? ', ' : null}
                        </span>
                      ))
                    : null}
                </div>
              </div>

              <div className="share-btn" onClick={openShareOptions}>
                <img src={ShareIcon} alt="share" />
                <span>compartilhar</span>
              </div>
            </div>

            <div className="divider"></div>

            <div className="sm-third-row">
              <div className="lesson-selector">
                <img
                  src={MenuIcon}
                  alt="menu bullet"
                  className="menu-bullet"
                  onClick={() => setOpenDrawer(open => !open)}
                />
                {course && lesson ? (
                  <h3>
                    {lesson.position + '/' + course.contents.length + ' - '}
                    {course.contents.length === 1 ? 'Aula' : 'Aulas'}
                  </h3>
                ) : null}
                <img src={BackArrow} alt="back arrow" className="arrow-left" />
              </div>

              <div className="divider"></div>
            </div>

            <div className="second-row">
              <div
                className="comments-btn"
                onClick={() => setShowComments(comment => !comment)}
              >
                <img src={CommentsIcon} alt="comments" />
                <span>comentários</span>

                {comments && comments.length ? (
                  <div className="notifications-icon">{comments.length}</div>
                ) : null}
              </div>

              <div className="sm-btn">
                <SmallBtn onClick={() => history.push('/produtos/1')}>
                  CONHEÇA REGENESIS
                </SmallBtn>
              </div>
            </div>
          </CourseInfoActions>

          {showComments ? (
            <CommentsContent
              comments={comments}
              lessonId={lessonId}
              reload={() => setReloadComment(comment => comment + 1)}
            />
          ) : null}

          <div className="sm-footer">
            {product && product.length ? (
              <Banner withText product={product[0]} />
            ) : null}

            <div className="progress">
              <h1>jornada no curso</h1>

              <ProgressBar
                hasTitle
                progress={Math.round(overallProgress * 100)}
              />
            </div>

            {course && lesson && progressByCourse ? (
              <ProgressCourse
                courses={progressByCourse}
                selectedCourse={course.course_id}
                trailId={trailId}
                courseProgress={courseProgress}
              />
            ) : null}

            {extraMaterials ? (
              <div className="material-course">
                <MaterialCourse
                  materialCourse={extraMaterials}
                  limit={extraMaterials.length - 1.08}
                />
              </div>
            ) : null}

            <Footer
              backgroundColor={'var(--default-background-color)'}
              hasLogo
            />
          </div>
        </CourseModuleInfoWrapper>

        {course && lesson && product && extraMaterials && progressByCourse ? (
          <SideMenuCourse
            product={product[0]}
            trailId={trailId}
            open={openMenu}
            onChangeMenu={() => setOpenMenu(open => !open)}
            selectedCourse={course}
            progressByCourse={progressByCourse}
            extraMaterial={extraMaterials}
            position={course.position}
            progress={overallProgress}
            courseProgress={courseProgress}
          />
        ) : null}
      </CourseWrapper>
    </>
  ) : (
    <Loading />
  );
};

export default Lesson;
