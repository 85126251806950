import httpClient from 'http-client';
import Trail, { IUserTrail } from './models/trail-interface';
import Course from './models/course-interface';
import Content from './models/content-interface';

interface CourseUserFromResponse {
  course_id: string;
  start_at?: string;
  finish_at?: string;
  progress: number;
  contents: LessonUserFromResponse[];
}

interface LessonUserFromResponse {
  content_view: number | null;
  content_id: string;
  start_at?: string;
  finish_at?: string;
}

const convertToTrail = (trails: Trail[]) => {
  return (trails || []).map(
    trail =>
      ({
        ...trail,
        duration:
          trail.duration ??
          (trail.courses &&
          trail.courses.length &&
          trail.courses.filter(course => course.contents !== null).length
            ? trail.courses
                .map(course => course.contents)
                .flat()
                .map(content => +content.duration)
                .reduce(
                  (somaDuracao, duracaoAtual) => somaDuracao + duracaoAtual,
                )
            : null),
        alreadyStarted: !!trail.user?.start_at,
        alreadyFinished: !!trail.user?.finish_at,
        courses:
          trail.courses && trail.courses.length
            ? convertToCourses(
                trail,
                trail.courses
                  .filter(course => course.is_active && !course.deleted_at)
                  .sort(
                    (courseA, courseB) => courseA.position - courseB.position,
                  ),
                trail.user,
              )
            : [],
      } as Trail),
  );
};

const convertToCourses = (
  trail: Trail,
  courses: Course[],
  courseUser?: IUserTrail,
) => {
  return courses.map(course => {
    let moduleUser: CourseUserFromResponse | undefined;

    if (courseUser && courseUser.courses) {
      moduleUser = courseUser.courses.find(
        moduleUser => moduleUser.course_id === course.course_id,
      );
    }

    return {
      ...course,
      trail_id: trail ? trail.trail_id : '',
      isFirstCourse:
        trail && trail.courses && trail.courses.length
          ? trail.courses[0].course_id === course.course_id
          : false,
      duration: course.contents
        ? course.contents
            .map(content => +content.duration)
            .reduce((somaDuracao, duracaoAtual) => somaDuracao + duracaoAtual)
        : null,
      lessons:
        course.contents && course.contents.length
          ? convertToLessons(
              course.contents.sort(
                (lessonA, lessonB) => lessonA.position - lessonB.position,
              ),
              courseUser?.courses.find(
                userCourse => userCourse.course_id === course.course_id,
              ),
              course,
              trail,
            )
          : [],
      alreadyStarted: !!moduleUser?.start_at,
      alreadyFinished: !!moduleUser?.finish_at,
    } as Course;
  });
};

const convertToLessons = (
  contents: Content[],
  moduleUser?: CourseUserFromResponse,
  course?: Course,
  trail?: Trail,
) => {
  return contents.map((content, index, self) => {
    let contentUser: LessonUserFromResponse | undefined;
    const actualContent = self[index];

    if (moduleUser && moduleUser.contents) {
      contentUser = moduleUser.contents.find(
        contentUser => contentUser.content_id === actualContent?.content_id,
      );
    }

    return {
      ...content,
      trail_id: trail && trail.trail_id ? trail.trail_id : '',
      course_id: course && course.course_id ? course.course_id : '',
      isFirstOfCourse:
        course && course.contents && course.contents.length
          ? course.contents[0].content_id === content.content_id
          : false,
      isLastOfCourse:
        course && course.contents && course.contents.length
          ? course.contents[course.contents.length - 1].content_id ===
            content.content_id
          : false,
      alreadyStarted: !!contentUser?.start_at,
      alreadyFinished: !!contentUser?.finish_at,
      content_view: contentUser?.content_view || null,
    } as Content;
  });
};

const getAllCourses = async (
  filters?: object,
  headers?: object,
): Promise<Trail[]> => {
  let allTrails: Array<Trail> = (
    await httpClient.get('/trails', {
      params: filters,
      headers,
    })
  )?.data;

  if (allTrails && allTrails.length) {
    allTrails = allTrails.filter(trail => trail.is_active);
  }

  return convertToTrail(allTrails);
};

const getCourse = async (trailId: string, headers?: object): Promise<Trail> => {
  if (!trailId) {
    return {} as Trail;
  }

  let trail = (
    await httpClient.get(`/trails/${trailId}`, {
      headers,
    })
  ).data;

  if (trail && trail.trail && trail.user) {
    trail = {
      ...trail.trail,
      user: trail.user,
    };
  } else {
    trail = trail.trail;
  }

  return convertToTrail([trail])[0];
};

const getAllCoursesModule = async (filters?: object): Promise<Course[]> => {
  let allCoursesModule: Array<Course> = (
    await httpClient.get('/courses', { params: filters })
  ).data;

  return allCoursesModule;
};

const getCourseModule = async (courseId: string): Promise<Course> => {
  let courseModule: Course = (await httpClient.get(`/courses/${courseId}`))
    .data;

  return courseModule;
};

const getContentLesson = async (contentId: string): Promise<Content> => {
  let courseModule: Content = (await httpClient.get(`/contents/${contentId}`))
    .data;

  return courseModule;
};

const startLesson = async (
  courseId: string,
  trailId: string,
  lessonId: string,
) => {
  if (!courseId || !trailId || !lessonId) {
    return {};
  }

  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: lessonId,
    })
  ).data;
};

const finishLesson = async (
  courseId: string,
  trailId: string,
  lessonId: string,
) => {
  if (!courseId || !trailId || !lessonId) {
    return {};
  }

  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: lessonId,
    })
  ).data;
};

const updateLessonProgress = async (
  courseId: string,
  trailId: string,
  lessonId: string,
  content_view: number | null,
) => {
  if (!courseId || !trailId || !lessonId) {
    return {};
  }

  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: lessonId,
      content_view,
    })
  ).data;
};

const startTrail = async (trailId: string) => {
  if (!trailId) {
    return {};
  }

  return (
    await httpClient.post(`/trail-users`, {
      trail_id: trailId,
    })
  ).data;
};

const finishTrail = async (trailId: string) => {
  if (!trailId) {
    return {};
  }

  return (
    await httpClient.put(`/trail-users/finish`, {
      trail_id: trailId,
    })
  ).data;
};

const startCourse = async (courseId: string, trailId: string) => {
  if (!courseId || !trailId) {
    return {};
  }

  return (
    await httpClient.post(`/trail-course-users`, {
      trail_id: trailId,
      course_id: courseId,
    })
  ).data;
};

const finishCourse = async (courseId: string, trailId: string) => {
  if (!courseId || !trailId) {
    return {};
  }

  return (
    await httpClient.put(`/trail-course-users/finish`, {
      trail_id: trailId,
      course_id: courseId,
    })
  ).data;
};

interface ISearch {
  data: Array<any>;
  total: number;
}

const getSearchCoursesAndExtraMaterial = async (
  filters?: object,
): Promise<ISearch> => {
  const searchedContent = (
    await httpClient.get('/searches/contents', {
      params: filters,
    })
  ).data;

  return searchedContent;
};

export {
  getAllCourses,
  getCourse,
  getAllCoursesModule,
  getCourseModule,
  getContentLesson,
  startLesson,
  finishLesson,
  updateLessonProgress,
  startTrail,
  finishTrail,
  startCourse,
  finishCourse,
  getSearchCoursesAndExtraMaterial,
};
