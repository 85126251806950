const homeBannerSettings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  draggable: false,
  arrows: true,
};

const supportMaterialSettings = {
  speed: 500,
  slidesToShow: 3.2,
  slidesToScroll: 1,
  infinite: false,
  draggable: false,
  arrows: true,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2.9,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1.7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const supportMaterialMenuSettings = {
  speed: 500,
  slidesToShow: 1.08,
  slidesToScroll: 1,
  infinite: false,
  draggable: false,
  arrows: false,
  swipeToSlide: false,
};

const profissionalSettings = {
  speed: 500,
  slidesToShow: 3.2,
  slidesToScroll: 1.5,
  infinite: false,
  draggable: false,
  arrows: false,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const medicsSettings = {
  speed: 500,
  slidesToShow: 1.4,
  slidesToScroll: 1.5,
  infinite: true,
  draggable: false,
  arrows: false,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export {
  homeBannerSettings,
  supportMaterialSettings,
  profissionalSettings,
  medicsSettings,
  supportMaterialMenuSettings,
};
