import styled, { css } from 'styled-components';

interface InputProps {
  width?: string;
}

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;

  label {
    font-size: var(--font-small);
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    margin-top: 10px;
    font-size: var(--font-normal);
    color: var(--third-color);
    font-weight: 600;
    cursor: pointer;
  }

  input {
    height: 35px;
    border-radius: 6px;
    padding: 0 6px;
    border: 1px solid rgba(155, 153, 175, 0.31);
    outline: none;
    color: var(--secondary-color);
  }

  span {
    padding-top: 5px;
    padding-left: 5px;
    color: var(--color-error);
    font-size: var(--font-small);
  }

  .error-border {
    border: 1px solid var(--color-error);
  }

  .eye-icon {
    position: absolute;
    width: 16px;
    top: 35px;
    left: 94%;
    cursor: pointer;
  }

  .adjust {
    top: 70%;

    @media (max-width: 768px) {
      top: 75%;
    }
  }

  .alert {
    color: #7f89b9;
    margin-left: -6px;
    margin-bottom: 10px;
    margin-top: -10px;
    font-size: 12px;
  }
`;

export const TextArea = styled.textarea<InputProps>`
  border-radius: 6px;
  padding: 10px;
  border: 1px solid rgba(155, 153, 175, 0.31);
  outline: none;
  color: var(--secondary-color);
  background-color: white;
  display: flex;
  align-items: center;
  outline: none;
  color: black;
`;
