import styled, { css } from 'styled-components';

interface ILessonDrawer {
  isOpen: boolean;
}

export const LessonDrawerMenuWrapper = styled.div<ILessonDrawer>`
  ${({ isOpen }) => css`
    width: ${isOpen ? `450px` : `0`};
  `}

  @media (max-width: 768px) {
    ${({ isOpen }) => css`
      width: ${isOpen ? `100%` : `0`};
    `}
  }

  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 24px 64px rgba(45, 48, 58, 0.13);

  ::-webkit-scrollbar {
    display: none;
  }

  .container-drawer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px;

    .icon-close {
      cursor: pointer;
      align-self: flex-end;
      width: 25px;
      height: 25px;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 45px;

      h1 {
        font-size: var(--font-larger);
        text-transform: uppercase;
        color: var(--secondary-color-light);
        font-family: 'Nunito Sans ExtraBold';
        font-weight: 900;
      }

      h4 {
        font-size: var(--font-large);
        font-family: 'Nunito Sans Light';
        font-weight: 400;
        text-transform: uppercase;
      }

      h2 {
        color: var(--secondary-color-light);
        text-transform: uppercase;
        font-size: var(--font-larger);
        font-family: 'Nunito Sans Light';
        font-weight: 300;
      }
    }

    .list-courses {
      position: relative;
      margin-top: 58px;

      .dashed-component {
        width: 50px;
        height: 50px;
        border: none;
        border-top: 2px dashed var(--primary-color-light);
        transform: rotate(90deg);
        margin: 0 0 0 -17px;
      }
    }
  }
`;

export const CoursesListCard = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;

  .play-container {
    position: relative;

    img {
      opacity: 0.6;
      border: 3.5px solid var(--color-progress-background);
      border-radius: 50%;
      padding: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circle-component {
      opacity: 1;
      padding: 23px;
      background-color: var(--third-color);
    }

    .progress-position {
      position: absolute;
      left: -5.6px;
      top: -3.5px;
    }
  }

  h4 {
    text-transform: uppercase;
    color: var(--secondary-color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .bold {
    font-family: 'Nunito Sans ExtraBold';
  }
`;
