import React, { useState, useEffect } from 'react';
import { getAllAuthors } from 'services/profissionals';
import Author from 'services/profissionals/models/author-interface';
import ProfissionalCarousel from 'components/ProfissionalCarousel';
import { profissionalSettings } from 'config/settingsSlider';

import { ProfissionalsWrapper } from './style';

const Profissionals: React.FC = () => {
  const [profissionals, setProfissionals] = useState<Array<Author>>([]);

  useEffect(() => {
    (async () => {
      const localProf = await getAllAuthors({ is_active: true });

      setProfissionals(localProf.authors);
    })();
  }, []);

  return (
    <ProfissionalsWrapper>
      <div className="sm">
        <p>
          Preparamos um curso completo para acompanhar você na sua fase de
          gestação, passando pelos <span>1º</span>, <span> 2º</span> e
          <span> 3º</span> trimestre, até o Pós-parto.
          <br />
          <br />O curso conta com aulas em vídeo, materiais de apoio, dica de
          produtos e muito mais,
          <span> para lhe ajudar nesse momento único.</span>
        </p>
      </div>

      <div className="slider-wrapper-profissional">
        <ProfissionalCarousel
          limit={2.8}
          profissionals={profissionals}
          sliderSettings={profissionalSettings}
        />
      </div>
    </ProfissionalsWrapper>
  );
};

export default Profissionals;
