import axios from 'axios';
import httpClient from 'http-client';
import { User } from 'services/user/models/user-interface';

interface LoginData {
  email: string;
  password: string;
  ip?: string;
}

interface SocialLoginData {
  access_token: string;
  id_token: string;
}

interface IResponseLogin {
  user: User;
  api_token: string;
  ip: string;
}

interface IResponseLIP {
  ip?: string;
}

const login = async (data: LoginData) => {
  const loginResponse = (await httpClient.post('/cognito/login', data)).data;
  return loginResponse;
};

const socialLogin = async (data: SocialLoginData): Promise<IResponseLogin> => {
  const response = (await httpClient.post('/cognito/social', data)).data;
  return response;
};

const getIp = async (): Promise<IResponseLIP> => {
  const response = (await axios.get('https://api.ipify.org/?format=json')).data;
  return response;
};
export { login, socialLogin, getIp };
