import React from 'react';
import { useHistory } from 'react-router-dom';
import ProgressBar from 'components/ProgressBar';

import {
  CourseCardWrapper,
  ProfissionalInfo,
  ImgThumbnailContainer,
} from './style';
import PlayIcon from 'assets/icon/play-icon-card.svg';

interface ICourseCard {
  trailiId: string;
  courseId: string;
  lessonId: string;
  thumbnail: string;
  progress?: number;
  title: string;
  profissional_name?: string;
  profissional_specialty?: Array<string>;
  profissional_avatar?: string | undefined;
}

const CourseCard: React.FC<ICourseCard> = ({
  courseId,
  trailiId,
  lessonId,
  profissional_name,
  profissional_specialty,
  thumbnail,
  title,
  progress,
  profissional_avatar,
}) => {
  const history = useHistory();

  const handleClick = (): void => {
    history.push(`/trilha/${trailiId}/curso/${courseId}/aula/${lessonId}`);
  };

  return (
    <CourseCardWrapper>
      <ImgThumbnailContainer onClick={handleClick}>
        <img src={PlayIcon} alt="play" className="play-icon" />

        {thumbnail ? (
          <img src={thumbnail} alt="video thumbnail" className="thumbnail" />
        ) : (
          <div className="video-card-empty"></div>
        )}

        {progress && progress !== 0 ? (
          <div className="bar">
            <ProgressBar hasTitle={false} progress={progress} />
          </div>
        ) : (
          <></>
        )}
      </ImgThumbnailContainer>

      <h1>{title}</h1>

      <ProfissionalInfo>
        {profissional_avatar ? (
          <img src={profissional_avatar} alt="avatar profissional" />
        ) : null}

        <div className="info">
          <h5>{profissional_name}</h5>
          <span>
            {profissional_specialty && profissional_specialty.length
              ? profissional_specialty.map(
                  (specialty: string, index: number, array: Array<string>) => (
                    <React.Fragment key={index}>
                      {specialty}
                      {index !== array.length - 1 ? ' e ' : ''}
                    </React.Fragment>
                  ),
                )
              : null}
          </span>
        </div>
      </ProfissionalInfo>
    </CourseCardWrapper>
  );
};

export default CourseCard;
