import React, { useState } from 'react';
import Slider from 'react-slick';
import { supportMaterialMenuSettings } from 'config/settingsSlider';
import { ExtraMaterialCard } from 'components';
import ExtraMaterial from 'services/course/models/extra-material-interface';

import { MaterialCourseWrapper } from './style';
import NextArrow from 'assets/icon/arrow-expanded.svg';
import PrevArrow from 'assets/icon/arrow-retracted.svg';
import EmptyIcon from 'assets/icon/empty-document-icon.svg';

interface IMaterialCourse {
  materialCourse: Array<ExtraMaterial>;
  limit: number;
}

const MaterialCourse: React.FC<IMaterialCourse> = ({
  materialCourse,
  limit,
}) => {
  let sliderRef: Slider | null;
  const [reachEnd, setReachEnd] = useState<boolean>(false);
  const [reachStart, setReachStart] = useState<boolean>(true);

  const handleSliderChange = (current: number): void => {
    if (current === limit) {
      setReachEnd(true);
      setReachStart(false);
    } else if (current !== 0) {
      setReachEnd(false);
      setReachStart(false);
    } else {
      setReachEnd(false);
      setReachStart(true);
    }
  };

  return (
    <MaterialCourseWrapper hasMaterial={!!materialCourse}>
      <div className="title-support">
        <h1>material de apoio</h1>

        {materialCourse && materialCourse.length ? (
          <div>
            <img
              src={reachStart ? PrevArrow : NextArrow}
              alt="previous arrow"
              className={reachStart ? '' : 'close'}
              onClick={() => sliderRef?.slickPrev()}
            />
            <img
              src={reachEnd ? PrevArrow : NextArrow}
              alt="next arrow"
              className={reachEnd ? 'close' : ''}
              onClick={() => sliderRef?.slickNext()}
            />
          </div>
        ) : null}
      </div>

      {materialCourse && materialCourse.length ? (
        <div className="slider-wrapper">
          <Slider
            {...supportMaterialMenuSettings}
            ref={slider => (sliderRef = slider)}
            afterChange={current => {
              handleSliderChange(current);
            }}
          >
            {materialCourse.map(
              (extraMaterial: ExtraMaterial, index: number) => (
                <ExtraMaterialCard key={index} materialCourse={extraMaterial} />
              ),
            )}
          </Slider>
        </div>
      ) : (
        <div className="empty-document">
          <div>
            <img src={EmptyIcon} alt="empty document" />

            <h2>Nenhum material cadastrado.</h2>
          </div>
        </div>
      )}
    </MaterialCourseWrapper>
  );
};

export default MaterialCourse;
