import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  max-width: 1500px;
  margin: 62px auto;
  padding: 10px 21px;


  // lg - xl
  @media only screen and (min-width: 1700px) and (max-width: 2000px) {
    padding: 10px 7%;
  }

  //sm - lg
  @media only screen and (min-width: 1280px) and (max-width: 1699px) {
    padding: 10px 10%;
  }

  @media only screen and (min-width: 1000px) and (max-width: 1279px) {
    padding: 10px 80px;
  }
`;

export const Title = styled.h2`
  font-size: 35px;
  color: var(--secondary-color-light);
  font-weight: bold;
  margin-bottom: 7px;
  font-family: 'Nunito Sans ExtraBold';
  text-transform: uppercase;

  @media (max-width: 1280px) {
    font-size: 26px;
  }

  @media (max-width: 650px) {
    font-size: 26px;
    text-align: center;
  }
`;


export const SubTitle = styled.p`
  font-family: 'Nunito Sans';
  font-weight: 100;
  color: var(--color-black);
  font-size: var(--font-larger);
  margin-top: 20px;
  margin-bottom: 15px;

  @media (max-width: 1280px) {
    font-size: var(--font-large);
  }
`;