import styled from 'styled-components';

export const BannerWrapper = styled.div`
  margin: 80px auto 0;
  padding: 0 30px;
  max-width: 1768px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  img {
    border-radius: 8px;
    object-fit: cover;
    padding-left: 20px;
    width: 100%;
    height: 430px;
    max-width: 1205px;
    margin: 0 auto;
    @media (max-width: 768px) {
      object-fit: contain;
      height: auto;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: var(--third-color);
  }
`;
