import React from 'react';
import PlayerSambaVideos from 'components/PlayerSambaVideos';
import { Container, Alerts, ContainerBtn, ContainerImgInfo } from './style';
import alerts1 from 'assets/icon/alerts1.svg';
import alerts2 from 'assets/icon/alerts2.svg';
import { SmallBtn } from 'components/Buttons';
import { useHistory } from 'react-router-dom';
import Product from 'services/product/models/product-interface';

type Props1 = {
  product: Product[] | any;
};

const Content: React.FC<Props1> = ({ product }) => {
  const history = useHistory();
  if (!product) {
    return null;
  }
  const {
    title,
    description,
    subtitle,
    video_reference,
    thumbnail,
    info,
    alerts,
  } = product;

  const handleClick = (): void => {
    history.push('/onde-comprar');
  };

  const handleExternalClick = (): void => {
    window.open(
      'https://www.drogaraia.com.br/regenesis-premium-120-capsulas.html',
      '_blank',
    );
  };

  return (
    <Container>
      <img className="thumb-desktop" src={thumbnail} alt={thumbnail} />
      <div>
        <h2>{title}</h2>
        <h3 className="subtitle">{subtitle}</h3>
        <div className="header-content-mobile">
          <img className="thumb" src={thumbnail} alt={thumbnail} />
          <h3>{subtitle}</h3>
        </div>
        <div className="container-sambaVideos">
          <PlayerSambaVideos
            contentReference={video_reference}
            controlsToEnable={[
              'play',
              'pause',
              'quality',
              'fullscreen',
              'time',
              'volume',
              'speed',
              'subtitles',
              'settings',
            ]}
            getEventListeners={() => {}}
          />
        </div>
        <p>{description}</p>
        <Alerts>
          <div>
            <div className="icon-title">
              <img src={alerts2} alt="recomendação de uso" />
              <h4>RECOMENDAÇÕES DE USO</h4>
            </div>
            <p>{info.usage_recomendations}</p>
          </div>
          <div>
            <div className="icon-title">
              <img
                className="temperature"
                src={alerts1}
                alt="cuidados de conservação"
              />
              <h4>CUIDADOS DE CONSERVAÇÃO</h4>
            </div>
            <p>{info.conservation_care}</p>
          </div>
          <div>
            <p className="alert-medication">{alerts}</p>
          </div>
        </Alerts>
        {info.nutritional_information && (
          <ContainerImgInfo>
            <img
              src={info.nutritional_information}
              alt="Tabela de informações Nutricionais"
            />
          </ContainerImgInfo>
        )}
        {(title === 'Regenesis Premium' && (
          <ContainerBtn>
            <SmallBtn onClick={handleExternalClick} size="md">
              Onde comprar
            </SmallBtn>
          </ContainerBtn>
        )) || (
          <ContainerBtn>
            <SmallBtn onClick={handleClick} size="md">
              Onde comprar
            </SmallBtn>
          </ContainerBtn>
        )}
      </div>
    </Container>
  );
};

export default Content;
