import { Header } from 'components';
import ModulesContent from './components/ModulesContent';

import { Page } from './style';
import smoothscroll from 'smoothscroll-polyfill'


smoothscroll.polyfill()

function Profissionals() {
  return (
    <Page>
      <Header reduceHeader={false} />

      <ModulesContent />
    </Page>
  );
}

export default Profissionals;
