import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  .legend-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;

    h5,
    span {
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-family: 'Nunito Sans SemiBold';
    }

    span {
      font-size: var(--font-medium);
    }
  }

  .progress-bar {
    background-color: var(--color-progress-background);
    height: 9px;
    border-radius: 5px;
    width: 100%;

    div {
      background-color: var(--primary-color-light);
      border-radius: 5px;
      height: 8px;
    }
  }
`;
