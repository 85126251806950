import styled, { keyframes } from 'styled-components';

const animate = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const List = styled.div`
  margin-bottom: 10px;
`;

export const TabTitle = styled.div`
  background-color: white;
  border: 1px solid rgba(155, 153, 175, 0.31);
  border-radius: 4px;

  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--secondary-color);
    text-transform: uppercase;

    &:hover,
    &.open {
    }
    &.open {
      border-bottom: 1px solid transparent;
      font-family: 'Nunito Sans ExtraBold';
    }

    .close-img,
    .open-img {
      transform: rotate(-90deg);
      width: 28px;
      height: 28px;
      border: 2px solid var(--secondary-color);
      border-radius: 50%;
      padding: 2px;
    }

    .open-img {
      transform: rotate(90deg);
    }
  }
`;

export const TextFaq = styled.div`
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;

    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-content {
    width: 100%;
    animation: ${animate} 0.6s;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: var(--font-large);
  }
`;
