import React, { useEffect, useState } from 'react';

import { PaginationWrapper } from './style';
import Arrow from 'assets/icon/back-arrow.svg';

interface IPagination {
  quantity: number;
  next: () => void;
  preview: () => void;
}

const Pagination: React.FC<IPagination> = ({ quantity, next, preview }) => {
  const [elements, setElements] = useState<number>(0);

  useEffect(() => {
    const total = Math.round(quantity / 5);

    setElements(total);
  }, [quantity]);

  return (
    <PaginationWrapper>
      <img src={Arrow} alt="arrow" onClick={preview} />

      <div className="list-pages">
        {Array(elements)
          .fill([])
          .map((_, index) => (
            <h3 key={index}>{index + 1}</h3>
          ))}
      </div>

      <img src={Arrow} alt="arrow" className="to-right" onClick={next} />
    </PaginationWrapper>
  );
};

export default Pagination;
