import styled from 'styled-components';

interface BannerRegenesisWrapperProps {
  color?: string;
}

export const BannerRegenesisWrapper = styled.div<BannerRegenesisWrapperProps>`
  background-color: ${({ color }) => color || 'var(--banner-color)'};
  border-radius: 4px;
  height: 120px;
  width: 100%;
  color: white;

  @media (max-width: 768px) {
    width: 100%;
  }

  .without-text {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    img {
      width: 80px;
      height: 100%;
    }

    .elements {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }

  .xl {
    height: 100%;
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .sm {
    display: none;
    padding: 0 20px;
    img:first-of-type {
      width: 80px;
    }
    img {
      height: 100px;
    }
    p {
      font-size: 14px;
      padding: 0 10px;
    }

    @media (max-width: 768px) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-top: 8px;
    }
  }

  h2 {
    font-family: 'Cookie';
    font-size: var(--font-largest);
    font-weight: 100;
  }

  p {
    font-size: var(--font-medium);
    width: 50%;
  }

  .btn-main {
    background-color: ${({ color }) => color || 'var(--banner-color)'};
    color: white;
    text-transform: uppercase;
    border-style: none;
    border: 1px solid white;
    padding: 10px 25px;
    border-radius: 20px;
    letter-spacing: 1px;
  }
`;
