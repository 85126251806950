import React, { useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/globals';
import Routes from './components/routes';
import { Container } from './components/Container';
import { Loading } from './components';
import awsconfig from './aws-exports';

Auth.configure(awsconfig);

const App: React.FC = () => {
  useEffect(() => {
    if (window.navigator.onLine === false) {
      toast.error(
        'Você parece estar sem internet. Verifique sua conexão para continuar.',
      );
    }
  }, []);

  return (
    <Router>
      <GlobalStyle />
      <Container>
        <Routes />
      </Container>
    </Router>
  );
};

export default App;
