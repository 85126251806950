import styled from 'styled-components';

export const FormContainer = styled.div`
  z-index: 2;

  .container {
    padding: 30px 60px;
  }

  .container-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      font-family: 'Nunito Sans SemiBold';

      img {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 4.5%;
    margin-bottom: 70px;

    .container {
      padding: 0;
    }
  }

  .title {
    margin-top: 40px;
    margin-bottom: 30px;
    color: var(--secondary-color-light);

    .btn-signup-xl {
      display: flex;
      column-gap: 10px;
      margin-top: 20px;

      button {
        height: 32px;
        border-style: none;
        border-radius: 20px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      img {
        cursor: pointer;
      }

      .facebook-btn-xl {
        width: auto;
      }

      .google-btn-xl {
        width: 0px;
        margin-top: 5px;
        display: flex;
        align-items: center;
      }
    }

    .btn-signup-sm {
      display: none;
      row-gap: 20px;
      margin-top: 20px;
      flex-direction: column;

      @media (max-width: 768px) {
        display: flex;
      }

      .facebook-btn-sm {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        background-color: #3b5998;
        height: 42px;
        font-family: 'Nunito Sans ExtraBold';
        border-radius: 20px;
        border-style: none;
        width: 100%;
        color: white;
      }

      .google-btn-sm {
        display: flex;
        align-items: center;
        height: 42px;
        justify-content: center;
        color: var(--third-color);
        font-family: 'Nunito Sans ExtraBold';
        background-color: white;
        border: 1.7px solid rgba(155, 153, 175, 0.31);
        border-radius: 20px;

        img {
          margin-right: 15px;
        }
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: var(--font-large);
    }

    h4 {
      margin-top: 18px;
    }
  }

  h5 {
    margin-top: 80px;
    font-size: var(--font-large);
    color: var(--secondary-color);
    cursor: pointer;

    @media (max-width: 768px) {
      margin-top: 15px;
      text-align: center;
    }
  }

  #message-input {
    height: 240px;
    width: 100%;
  }

  div {
    .input-email-container {
      width: 78%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .input-phone-container {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .input-subject-container {
      width: 68%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
