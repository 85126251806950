import React from 'react';
import { Container } from './style';
import FAQ from 'services/faq/models/faq-interface';

type Props1 = {
  faq: FAQ[] | any;
};

const ContentUI: React.FC<Props1> = ({ faq }) => {
  if (!faq) {
    return null;
  }
  const { content } = faq;

  return <Container>{content}</Container>;
};

export default ContentUI;
