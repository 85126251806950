import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 2%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

   a {
    text-decoration: none;
  }
  
`;

export const FooterSocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 13px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }

  img {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }
`;

export const FooterNavInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 40px;
  }

  a {
    text-transform: uppercase;
    color: var(--color-text-footer);
    cursor: pointer;
    text-decoration: none;
  }
`;
