import React from 'react';
import { Banner, ProgressBar, Header } from 'components';
import MaterialCourse from './components/MaterialCourse';
import ProgressCourse from './components/ProgressCourse';
import Course from 'services/course/models/course-interface';
import Product from 'services/product/models/product-interface';
import ExtraMaterial from 'services/course/models/extra-material-interface';
import { IUserProgressTrail } from 'services/user';

import {
  SideMenuCourseWrapper,
  SideMenuRetracted,
  SideMenuExpanded,
} from './style';
import LogoRegenesis from 'assets/logo/logo-regenesis-white-small.svg';

interface ISideMenu {
  open: boolean;
  onChangeMenu: () => void;
  selectedCourse: Course;
  extraMaterial: Array<ExtraMaterial>;
  trailId: string;
  position: number;
  product: Product;
  progress: number;
  progressByCourse: IUserProgressTrail;
  courseProgress: number;
}

const SideMenuCourse: React.FC<ISideMenu> = ({
  open,
  onChangeMenu,
  selectedCourse,
  trailId,
  extraMaterial,
  position,
  product,
  progress,
  progressByCourse,
  courseProgress,
}) => {
  const handleStepsClasses = (
    type: string,
    positionGiven: number,
  ): string | undefined => {
    if (selectedCourse) {
      if (type === 'hr') {
        switch (positionGiven) {
          case 1:
            return position >= 1
              ? 'dashed-component selected-dashed'
              : 'dashed-component';

          case 2:
            return position >= 2
              ? 'dashed-component selected-dashed'
              : 'dashed-component';

          case 3:
            return position >= 3
              ? 'dashed-component selected-dashed'
              : 'dashed-component';

          case 4:
            return position === 4
              ? 'dashed-component selected-dashed'
              : 'dashed-component';
        }
      } else {
        switch (positionGiven) {
          case 1:
            return position >= 1 ? 'module selected' : 'module';

          case 2:
            return position >= 2 ? 'module selected' : 'module';

          case 3:
            return position >= 3 ? 'module selected' : 'module';

          case 4:
            return position === 4 ? 'module selected' : 'module';
        }
      }
    }
  };

  return (
    <>
      {open ? (
        <SideMenuCourseWrapper open={open}>
          <div className="arrow-btn" onClick={onChangeMenu}>
            <div className="arrow-set"></div>
            <div className="arrow-set"></div>
          </div>

          <SideMenuExpanded>
            <div className="journey-course">
              <Header reduceHeader />

              <h1>jornada do curso</h1>

              {progressByCourse && (
                <ProgressCourse
                  courses={progressByCourse}
                  trailId={trailId}
                  selectedCourse={selectedCourse.course_id}
                  courseProgress={courseProgress}
                />
              )}

              <ProgressBar hasTitle progress={Math.round(progress * 100)} />
            </div>

            <MaterialCourse
              materialCourse={extraMaterial}
              limit={extraMaterial.length - 1.08}
            />

            <div>
              <Banner withText={false} product={product} />
            </div>
          </SideMenuExpanded>
        </SideMenuCourseWrapper>
      ) : (
        <SideMenuCourseWrapper open={open}>
          <div className="arrow-btn" onClick={onChangeMenu}>
            <div className="arrow-set"></div>
            <div className="arrow-set"></div>
          </div>

          <SideMenuRetracted color={product?.info.color}>
            <div className="steps-course" onMouseEnter={() => onChangeMenu()}>
              <span className={handleStepsClasses('span', 1)}></span>
              <hr className={handleStepsClasses('hr', 1)} />

              <span className={handleStepsClasses('span', 2)}></span>
              <hr className={handleStepsClasses('hr', 2)} />

              <span className={handleStepsClasses('span', 3)}></span>
              <hr className={handleStepsClasses('hr', 3)} />

              <span className={handleStepsClasses('span', 4)}></span>
            </div>

            <div className="logo-footer">
              <img src={LogoRegenesis} alt="regenesis" />
            </div>
          </SideMenuRetracted>
        </SideMenuCourseWrapper>
      )}
    </>
  );
};

export default SideMenuCourse;
