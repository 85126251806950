import styled, { css } from 'styled-components';
import SearchInput from 'components/SearchInput';

interface IOpenDrawer {
  open: boolean;
}

export const MenuDrawerWrapper = styled.div<IOpenDrawer>`
  ${({ open }) => css`
    display: ${open ? 'flex' : 'none'};
    width: ${open ? '80%' : '0'};
    animation: ${open ? 'slide' : 'none'};
    animation-duration: 1s;
  `}

  .search-input-click {
    text-decoration: none;
  }

  .search-btn-container {
    display: flex;
    flex-direction: column;
    transform: translate(0px, 53px);
  }

  z-index: 5;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--color-menu);
  flex-direction: column;
  padding: 50px;

  @keyframes slide {
    0% {
      width: 0;
      left: -50%;
    }
    100% {
      width: 80%;
      left: 0;
    }
  }

  .search-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    //position: absolute;
    //top: 23%;
    cursor: pointer;
    margin-bottom: 10px;

    h2 {
      text-transform: uppercase;
      color: var(--secondary-color);
      font-size: var(--font-medium);
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .close-btn {
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .menu-icons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      display: flex;
      gap: 14px;
    }

    h2 {
      text-transform: uppercase;
      color: var(--secondary-color);
      font-size: var(--font-medium);
      cursor: pointer;
    }

    img {
      width: 20px;
      height: 20px;
    }

    .rotate {
      transform: rotate(180deg);
    }
  }

  .divider-menu {
    background-color: var(--secondary-color);
    width: 100%;
    opacity: 0.1;
    height: 1px;
    margin: 22px 0;
  }

  .logout-wrapper {
    display: flex;
    position: fixed;
    bottom: 10%;
  }

  .menu-icons-not-register {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 88px;

    h2 {
      text-transform: uppercase;
      color: var(--secondary-color);
      font-size: var(--font-medium);
      cursor: pointer;
    }
  }
`;

export const InputSearchMobile = styled(SearchInput)`
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--third-color);
  width: 60%;
  outline: none;
  font-size: var(--font-largest);
  padding-bottom: 6px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
