import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from 'components/CircularProgress';
import { IUserProgressTrail } from 'services/user';

import { ProgressCourseWrapper } from './style';
import Img1Period from 'assets/details/woman-draw-1period.svg';
import Img2Period from 'assets/details/woman-draw-2period.svg';
import Img3Period from 'assets/details/woman-draw-3period.svg';
import Img4Period from 'assets/details/woman-draw-4period.svg';

interface IProgressCourse {
  courses: IUserProgressTrail;
  selectedCourse: string;
  trailId: string;
  courseProgress: number;
}

const ProgressCourse: React.FC<IProgressCourse> = ({
  courses,
  selectedCourse,
  trailId,
  courseProgress,
}) => {
  const history = useHistory();

  const handleImgComponent = (
    position: number,
    courseId: string,
    progress: number,
  ): ReactElement => {
    switch (position) {
      case 1:
        return (
          <>
            {courseId === selectedCourse ? (
              ProgressCircularBar(position, progress)
            ) : (
              <img src={Img1Period} alt="1 period woman" />
            )}
          </>
        );

      case 2:
        return (
          <>
            {courseId === selectedCourse ? (
              ProgressCircularBar(position, progress)
            ) : (
              <img src={Img2Period} alt="2 period woman" />
            )}
          </>
        );

      case 3:
        return (
          <>
            {courseId === selectedCourse ? (
              ProgressCircularBar(position, progress)
            ) : (
              <img src={Img3Period} alt="3 period woman" />
            )}
          </>
        );

      case 4:
        return (
          <>
            {courseId === selectedCourse ? (
              ProgressCircularBar(position, progress)
            ) : (
              <img src={Img4Period} alt="4 period woman" />
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <ProgressCourseWrapper>
      <div className="woman-steps">
        <i></i>
        <hr className="dashed-component" />
        {courses.courses &&
          courses.courses.length &&
          courses.courses.map((course, index: number, array) => (
            <React.Fragment key={index}>
              <div
                className="img-container"
                onClick={() =>
                  history.push(
                    `/meus-cursos/trilha/${trailId}/curso/${course.course_id}`,
                  )
                }
              >
                {handleImgComponent(
                  index + 1,
                  course?.course_id,
                  courseProgress,
                )}
                <h6
                  className={course?.course_id === selectedCourse ? 'bold' : ''}
                >
                  {course.name}
                </h6>
              </div>
              {index !== array.length - 1 && (
                <hr className="dashed-component" />
              )}
            </React.Fragment>
          ))}
      </div>
    </ProgressCourseWrapper>
  );
};

function ProgressCircularBar(period: number, progress: number): ReactElement {
  return (
    <div className="progress-bar-circular">
      <span>{period}°</span>

      <div className="container">
        <CircularProgress
          progress={progress}
          strokeColor={'#E46C9A'}
          stroke={5.5}
          radius={48}
        />
      </div>
    </div>
  );
}

export default ProgressCourse;
