import React from 'react';
import { FAQWrapper } from './style';
import { Header, Footer } from 'components';
import ModulesContent from './components/ModulesContent';

const FAQ: React.FC = () => {
  return (
    <>
      <FAQWrapper>
        <Header reduceHeader={false} showHeaderNotLogged />

        <ModulesContent />
        <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
      </FAQWrapper>
    </>
  );
};

export default FAQ;
