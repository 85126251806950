import { useEffect, useState } from 'react';
import { getAllCourses, getCourse } from 'services/course';
import { getUserDetails } from 'services/user';
import { User } from 'services/user/models/user-interface';
import Trail from 'services/course/models/trail-interface';
import { Header, Footer } from 'components';

import { ProfileWrapper } from './style';
import ProfileInfo from './components/ProfileInfo';
import OverallProgress from './components/OverallProgress';
import CertificateVerify from './components/CertificateVerify';
import smoothscroll from 'smoothscroll-polyfill'


smoothscroll.polyfill()

function Profile() {
  const [user, setUser] = useState<User>();
  const [trail, setTrail] = useState<Trail>();
  const [reload, setReload] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const localTrails = await getAllCourses();
      const localTrail = await getCourse(localTrails[0].trail_id);

      setTrail(localTrail);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const localUser = await getUserDetails();

      setUser(localUser);
    })();
  }, [reload]);

  return (
    <ProfileWrapper>
      <Header reduceHeader={false} />

      {user ? (
        <ProfileInfo
          user={user}
          reload={() => setReload(reload => reload + 1)}
        />
      ) : null}
      {trail && user ? (
        <>
          <OverallProgress trail={trail} />
          <CertificateVerify user={user} trailId={trail?.trail_id} />
        </>
      ) : null}
      <Footer backgroundColor={'var(--default-background-color)'} hasLogo />
    </ProfileWrapper>
  );
}

export default Profile;
