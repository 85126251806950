import React from 'react';

import { ProgressBarWrapper } from './style';

interface IProgressBar {
  progress: number;
  hasTitle: boolean;
}

const ProgressBar: React.FC<IProgressBar> = ({ progress, hasTitle }) => {
  return (
    <ProgressBarWrapper>
      {hasTitle ? (
        <div className="legend-progress">
          <h5>já assistido</h5>
          <span>{progress?.toString().replace('.', ',')}%</span>
        </div>
      ) : (
        <></>
      )}

      <div className="progress-bar">
        <div style={{ width: `${progress}%` }}></div>
      </div>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
