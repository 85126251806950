import React from 'react';
import { Header, Footer } from 'components';
import ModulesContent from './components/ModulesContent';
import { LocalizationWrapper } from './style';

const Localization: React.FC = () => {
  return (
    <LocalizationWrapper>
      <Header reduceHeader={false} />
      <ModulesContent />
      <Footer backgroundColor={'transparent'} hasLogo />
    </LocalizationWrapper>
  );
};

export default Localization;
