import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendEmailContactUs } from 'services/faq';
import { maskPhone } from 'helpers/masks';
import { validateEmail } from 'helpers/validation';
import { toast } from 'react-toastify';
import { SmallBtn } from 'components/Buttons';
import { Title, SubTitle, Wrapper, Input, TextArea } from 'components';
import { FormContainer, ContainerCard } from './style';
import PaperPlaneRight from 'assets/icon/PaperPlaneRight.svg';
import Card from '../Card';

const Contact: React.FC = () => {
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [subjectError, setSubjectError] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const history = useHistory();

  const handleSignup = async (): Promise<void> => {
    if (!email) {
      setEmailError('Preencha corretamente este campo');
    }

    if (!name) {
      setNameError('Preencha corretamente este campo');
    }

    if (!phone) {
      setPhoneError('Preencha corretamente este campo');
    }

    if (name.includes(' ') === false) {
      setNameError('Informe um nome e sobrenome');
    }

    if (!subject.length) {
      setSubjectError('Informe um assunto ');
    }

    if (!message.length) {
      setMessageError('Informe uma mensagem ');
      return;
    }

    if (
      phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        .length < 11
    ) {
      setPhoneError('Informe o seu celular com DDD');
      return;
    }

    if (!validateEmail(email) && email) {
      setEmailError('Informe um e-mail com formato válido');
      return;
    }

    if (
      !emailError &&
      !nameError &&
      !phoneError &&
      !subjectError &&
      !messageError
    ) {
      const data = {
        email,
        name,
        phone: phone
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replace(' ', ''),
        subject,
        text: message,
      };

      const user = JSON.parse(localStorage.getItem('exeltis-user') as string);
      const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;

      await sendEmailContactUs(
        data,
        user ? undefined : { Authorization: token },
      )
        .then(() => {
          history.push('/home');
          toast.success('Sucesso ao enviar a mensagem');
        })
        .catch(() => {
          toast.error(
            '⚠ Algo deu errado ao enviar sua mensagem. Tente novamente.',
          );
        });
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'name-input':
        setName(e.target.value);
        break;

      case 'email-input':
        setEmail(e.target.value);
        break;

      case 'phone-input':
        setPhone(maskPhone(e.target.value));
        break;

      case 'subject-input':
        setSubject(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    switch (e.target.id) {
      case 'message-input':
        setMessage(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Title>Fale com a gente</Title>
      <FormContainer>
        <div className="container">
          <SubTitle>Entre em contato por e-mail</SubTitle>
          <Input
            type="text"
            id="name-input"
            value={name}
            onChange={handleChangeInput}
            onFocus={() => setNameError('')}
            label="Nome"
          >
            {nameError && <span>{nameError}</span>}
          </Input>
          <div className="input-email-container">
            <Input
              type="email"
              id="email-input"
              value={email}
              onChange={handleChangeInput}
              onFocus={() => setEmailError('')}
              label="E-mail"
            >
              {emailError && <span>{emailError}</span>}
            </Input>
          </div>
          <div className="input-phone-container">
            <Input
              type="text"
              id="phone-input"
              value={phone}
              onChange={handleChangeInput}
              onFocus={() => setPhoneError('')}
              label="Telefone"
            >
              {phoneError && <span>{phoneError}</span>}
            </Input>
          </div>
          <div className="input-subject-container">
            <Input
              type="text"
              id="subject-input"
              value={subject}
              onChange={handleChangeInput}
              onFocus={() => setSubjectError('')}
              label="Assunto"
            >
              {subjectError && <span>{subjectError}</span>}
            </Input>
          </div>
          <div className="input-message-container">
            <TextArea
              type="text"
              id="message-input"
              value={message}
              onChange={handleChangeTextArea}
              onFocus={() => setMessageError('')}
              label="Mensagem"
            >
              {messageError && <span>{messageError}</span>}
            </TextArea>
          </div>
          <div className="container-button">
            <SmallBtn onClick={handleSignup}>
              <img src={PaperPlaneRight} alt="enviar mensagem" />
              Enviar Mensagem
            </SmallBtn>
          </div>
        </div>
      </FormContainer>
      <ContainerCard>
        <Card
          title="sac"
          content="O Serviço de Atendimento ao Cliente (SAC) tem como finalidade o fornecimento de informações gerais e a resolução de demandas relacionadas a sugestões e reclamações."
          contact={true}
          mrLg={true}
          myXs={true}
        />
        <Card
          title="Farmacovigilância"
          content="Caso tenha alguma suspeita de reação adversa ou desvio de qualidade de algum de nossos produtos entre em contato conosco.
"
          link={true}
          mlLg={true}
        />
      </ContainerCard>
    </Wrapper>
  );
};

export default Contact;
