import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from 'services/user/models/user-interface';
import { BlankBtn } from 'components/Buttons';
import { downloadCertificate } from 'services/certificate';

import { CertificateVerifyContainer } from './style';
import CertificateIcon from 'assets/icon/certificate-icon.svg';

interface ICertificateVerify {
  user: User;
  trailId: string;
}

const CertificateVerify: React.FC<ICertificateVerify> = ({ user, trailId }) => {
  const [hasCertificate, setHasCertificate] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      downloadCertificate(trailId, false)
        .then(response => {
          setHasCertificate(response);
        })
        .catch(() => {
          setHasCertificate(false);
        });
    })();
  }, []);

  useEffect(() => {
    if (location.state === 'certificate') {
      document.getElementById('certificate-container')?.scrollIntoView();
    }
  }, [location]);

  const handleCertificateDownload = async () => {
    await downloadCertificate(trailId, true);
  };

  return (
    <CertificateVerifyContainer id="certificate-container">
      <h1>certificado</h1>

      {hasCertificate ? (
        <p>
          <span>{user.name}</span>, você atingiu o número de horas necessário
          para realizar o download do certificado
        </p>
      ) : (
        <p>
          <span>{user.name}</span>, você precisa atingir no mínimo{' '}
          <span>{'03 horas'}</span> de curso para emitir seu certificado.
          Assista mais aulas, e volte aqui para emitir seu certificado.
        </p>
      )}

      <div
        className={
          hasCertificate ? 'certificate-btn' : 'certificate-btn disable'
        }
      >
        <BlankBtn
          disabled={!hasCertificate}
          onClick={handleCertificateDownload}
        >
          <img src={CertificateIcon} alt="certificate" /> gerar certificado
        </BlankBtn>
      </div>
    </CertificateVerifyContainer>
  );
};

export default CertificateVerify;
