import styled from 'styled-components';

export const SignupContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 38%;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: var(--color-secondary-background-color);

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .img-1,
    .img-2,
    .back-arrow,
    .flower,
    .flower-1,
    .flower-2,
    .flower-3,
    .flower-4,
    .flower-5,
    .flower-6,
    .flower-7,
    .flower-8 {
      display: none;
    }
  }

  .footer-signup {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-left: 80px;
    position: relative;

    @media (max-width: 768px) {
      display: none;
    }

    .img-1 {
      width: 120px;
      margin-left: 80px;
      margin-top: -50px;
    }

    .img-2 {
      width: 280px;
      margin-left: 80px;
      margin-top: 90px;
    }

    .back-arrow {
      width: 50px;
      z-index: 2;
      cursor: pointer;
    }

    .flower {
      position: absolute;
      background: transparent;
      opacity: 0.2;
    }

    .flower-1 {
      top: -70px;
      left: -80px;
    }

    .flower-2 {
      top: -75px;
      left: 100px;
    }

    .flower-3 {
      top: 120px;
      left: 250px;
    }

    .flower-4 {
      top: -40px;
      left: 380px;
    }

    .flower-5 {
      top: 250px;
      left: 220px;
    }

    .flower-6 {
      top: 180px;
      left: 440px;
    }

    .flower-7 {
      top: 400px;
      left: 270px;
    }

    .flower-8 {
      top: 370px;
      left: 340px;
    }
  }
`;

export const FormContainer = styled.div`
  z-index: 2;

  @media (max-width: 768px) {
    padding: 0 4.5%;
    margin-bottom: 70px;
  }

  .title {
    margin-top: 40px;
    margin-bottom: 30px;
    color: var(--secondary-color-light);

    .btn-signup-xl {
      display: flex;
      column-gap: 10px;
      margin-top: 20px;

      button {
        height: 40px;
        width: 50px;
        border-style: none;
        border-radius: 20px;
      }

      @media (max-width: 768px) {
        display: none;
      }

      img {
        cursor: pointer;
      }

      .facebook-btn-xl {
        width: auto;
      }

      .google-btn-xl {
        background-color: transparent !important;
        display: block !important;
        align-items: unset !important;
        color: transparent !important;
        box-shadow: none !important;
        padding: 0 !important;
        border-radius: 2px !important;
        border: none !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        font-family: inherit !important;
        opacity: 1 !important;
      }
    }

    .btn-signup-sm {
      display: none;
      row-gap: 20px;
      margin-top: 20px;
      flex-direction: column;

      @media (max-width: 768px) {
        display: flex;
      }

      .facebook-btn-sm {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        background-color: #3b5998;
        height: 42px;
        font-family: 'Nunito Sans ExtraBold';
        border-radius: 20px;
        border-style: none;
        width: 100%;
        color: white;
      }

      .google-btn-sm {
        display: flex;
        align-items: center;
        height: 42px;
        justify-content: center;
        color: var(--third-color);
        font-family: 'Nunito Sans ExtraBold';
        background-color: white;
        border: 1.7px solid rgba(155, 153, 175, 0.31);
        border-radius: 20px;

        img {
          margin-right: 15px;
        }
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: var(--font-large);
    }

    h4 {
      margin-top: 18px;
    }
  }

  h5 {
    font-size: var(--font-large);
    color: var(--secondary-color);
    cursor: pointer;
    &:first-of-type {
      margin-top: 80px;
    }
    &:nth-of-type(2) {
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      margin-top: 15px;
      text-align: center;
    }
  }

  .container-input-phone {
    width: 70%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const SocialMediaLinks = styled.div`
  display: flex;
  gap: 18px;
`;

export const SocialMedia = styled.div``;
