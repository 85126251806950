import styled from 'styled-components';

export const Container = styled.span`
  cursor: pointer;
  height: 44px;
  width: 44px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;

  border-radius: 50%;
  white-space: nowrap;
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;

  svg {
    width: 24px;
  }
`;
