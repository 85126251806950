import React from 'react';
import { Wrapper, Title, SubTitle } from './style';
import Map from '../map';

const ModulesContent: React.FC = () => {
    return (
      <Wrapper>
        <Title>ONDE COMPRAR</Title>
        <SubTitle>
                Encontre a farmácia mais próxima de você para comprar os produtos da
                Linha Regenesis.
        </SubTitle>
            <Map />
      </Wrapper>
    );
}

export default ModulesContent;