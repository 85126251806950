import React from 'react';
import ExtraMaterial from 'services/course/models/extra-material-interface';
import { ExtraMaterialContainer } from './style';
import { BiFile } from 'react-icons/bi'

interface IExtraMaterialCard {
  materialCourse: ExtraMaterial;
}

const ExtraMaterialCard: React.FC<IExtraMaterialCard> = ({
  materialCourse,
}) => {
  return (
    <a
      href={materialCourse.reference}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ExtraMaterialContainer>
        {materialCourse.thumbnail ? (
          <img src={materialCourse.thumbnail} alt="support" />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0,0,0,0.1)'
            }}
          >
            <BiFile size={32} />
          </div>
        )}

        <div className="info-support-card">
          <h2>{materialCourse.name}</h2>

          <p>{materialCourse.info.description}</p>
        </div>
      </ExtraMaterialContainer>
    </a>
  );
};

export default ExtraMaterialCard;
