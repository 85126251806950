import styled from 'styled-components';

export const LandingPageMedicsContainer = styled.div`
  background-color: var(--default-background-color);
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 0;
  }

  .xl {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .sm {
    display: none;
    padding: 0 8%;
    width: 100%;

    @media (max-width: 768px) {
      display: initial;
    }
  }

  .grid-medics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 70px;
    row-gap: 80px;
    margin-top: 80px;
  }

  h1 {
    font-family: 'Nunito Sans Light';
    font-weight: 100;
    font-size: var(--font-bigger);
    text-transform: uppercase;
    color: var(--secondary-color-light);
    text-align: center;

    @media (max-width: 768px) {
      font-size: var(--font-largest);
    }

    span {
      font-family: 'Nunito Sans ExtraBold';
      font-size: var(--font-bigger);
      color: var(--secondary-color-light);

      @media (max-width: 768px) {
        font-size: var(--font-largest);
      }
    }
  }
`;
