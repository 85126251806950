import styled from 'styled-components';

export const LandingPageVideoModules = styled.div`
  background-color: var(--default-background-color);
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .container-content,
  .container-reverse-content {
    display: flex;
    column-gap: 18%;
    row-gap: 70px;
    padding-bottom: 100px;
  }

  @media only screen and (max-width: 1400px) {
    .container-content,
    .container-reverse-content {
      display: flex;
      column-gap: 5%;
      row-gap: 0px;
      padding-bottom: 14px;
    }
  }

  @media only screen and (max-width: 1280px) {
    .img-my {
      margin: 20px 0;
    }

    .container-content {
      display: flex;
      flex-direction: column;
    }
    .container-reverse-content {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

export const LandingPageModuleCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .divider {
    border: 1px solid var(--color-progress-background);
    height: 1px;
    margin-top: 25px;
  }

  .title {
    background-color: white;
    color: var(--secondary-color-light);
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 12px 15px;
    border-radius: 4px;
    border: 1px solid rgba(155, 153, 175, 0.31);

    .arrow {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      align-items: center;

      .back {
        display: none;

        @media (max-width: 768px) {
          display: inherit;
          height: 20px;
          width: 20px;
          transform: rotate(-90deg);
        }
      }
    }

    .open {
      .back {
        transform: rotate(90deg);
      }
    }

    .close {
      display: none;
    }
  }

  .xl {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .sm {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  }

  .list {
    display: flex;
    align-items: center;
    margin: 30px 0;
    margin-left: 20px;
    cursor: pointer;

    h5 {
      color: var(--secondary-color);
      text-transform: uppercase;
      margin-left: 25px;
    }

    .icon-play {
      display: initial;
      transform: rotate(120deg);
      width: 20px;
      height: 20px;
    }
  }
`;
