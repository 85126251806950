import httpClient from 'http-client';
import Author from './models/author-interface';

interface IAuthorResponse {
  authors: Author[];
  specialty: Array<string>;
}

const getAllAuthors = async (
  filters?: object,
  headers?: object,
): Promise<IAuthorResponse> => {
  const response = await httpClient.get<IAuthorResponse>('/authors', {
    params: filters,
    headers,
  });

  return (response || { data: {} }).data;
};

const getAuthor = async (author_id: string): Promise<Author> => {
  const author: Author = (await httpClient.get(`/authors/${author_id}`)).data;

  return author;
};

export { getAllAuthors, getAuthor };
