import React, { useState, useEffect } from 'react';
import ProfissionalCard from 'components/ProfissionalCard';
import ProfissionalCarousel from 'components/ProfissionalCarousel';
import Author from 'services/profissionals/models/author-interface';
import { getAllAuthors } from 'services/profissionals/index';
import { medicsSettings } from 'config/settingsSlider';

import { LandingPageMedicsContainer } from './style';

const Medics: React.FC = () => {
  const [medics, setMedics] = useState<Array<Author>>([]);

  useEffect(() => {
    (async () => {
      const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;

      const localMedics = await getAllAuthors(
        { is_active: true },
        { Authorization: token },
      );

      setMedics(localMedics.authors);
    })();
  }, []);

  return (
    <LandingPageMedicsContainer>
      <div className="xl">
        <h1>
          <span>+</span> de <span>{medics && medics.length}</span> profissionais
          da <span>saúde</span>
        </h1>

        <div className="grid-medics">
          {medics && medics.length
            ? medics.map((profissional: Author, index: number) => (
                <ProfissionalCard
                  key={index}
                  profissional={profissional}
                  clickable={false}
                />
              ))
            : null}
        </div>
      </div>

      <div className="sm">
        <h1>
          <span>+</span> de <span>{medics && medics.length}</span> profissionais
          da <span>saúde</span>
        </h1>

        <ProfissionalCarousel
          limit={5}
          profissionals={medics}
          sliderSettings={medicsSettings}
        />
      </div>
    </LandingPageMedicsContainer>
  );
};

export default Medics;
