import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Course from 'services/course/models/course-interface';
import { MenuDrawerWrapper, InputSearchMobile } from './style';
import Arrow from 'assets/icon/back-arrow.svg';
import Logo from 'assets/logo/logo-small.svg';
import CloseIcon from 'assets/icon/close-icon-full.svg';
import SignoutIcon from 'assets/icon/signout-icon.svg';
import Glass from 'assets/icon/magnifying-glass-icon.svg';

interface IMenuDrawer {
  courses?: Course[];
  trailId?: string;
  openDrawer: boolean;
  onClose: () => void;
  logout: () => void;
  isRegister?: boolean;
}

const DrawerMenu: React.FC<IMenuDrawer> = ({
  openDrawer,
  onClose,
  logout,
  courses,
  trailId,
  isRegister,
}) => {
  const history = useHistory();
  const [openCourses, setOpenCourses] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState(false);

  const onInputSearch = () => setInputSearch(!inputSearch);

  return (
    <MenuDrawerWrapper open={openDrawer}>
      {isRegister ? (
        <>
          <div className="close-btn">
            <img src={Logo} alt="regenesis" />

            <img src={CloseIcon} alt="close" onClick={onClose} />
          </div>

          {!openCourses && !openProfile ? (
            <>
              <div className="search-btn-container">
                <div className="search-btn" onClick={onInputSearch}>
                  <img src={Glass} alt="search" />
                  <h2>pesquisar</h2>
                </div>
                <div>
                  {inputSearch && (
                    <InputSearchMobile
                      placeholder="Aulas ou materiais..."
                      searchIcon={false}
                    />
                  )}
                </div>
              </div>
            </>
          ) : null}

          <div className="menu-icons">
            {openCourses ? (
              <>
                <div onClick={() => setOpenCourses(false)}>
                  <img src={Arrow} alt="arrow" />
                  <h2>meu curso</h2>
                </div>

                <div className="divider-menu"></div>

                {courses?.map((course, index, array) => {
                  return (
                    <React.Fragment key={index}>
                      <h2
                        onClick={() =>
                          history.push(
                            `/meus-cursos/trilha/${trailId}/curso/${course.course_id}`,
                          )
                        }
                      >
                        {course.title}
                      </h2>

                      {index !== array.length - 1 && (
                        <div className="divider-menu"></div>
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : openProfile ? (
              <>
                <div onClick={() => setOpenProfile(false)}>
                  <img src={Arrow} alt="arrow" />
                  <h2>meu perfil</h2>
                </div>

                <div className="divider-menu"></div>

                <h2 onClick={() => history.push('/perfil')}>editar perfil</h2>

                <div className="divider-menu"></div>

                <h2
                  onClick={() =>
                    history.push({
                      pathname: '/perfil',
                      state: 'certificate',
                    })
                  }
                >
                  certificado
                </h2>
              </>
            ) : (
              <>
                <div onClick={() => setOpenProfile(true)}>
                  <h2>meu perfil</h2>
                  <img src={Arrow} alt="arrow" className="rotate" />
                </div>

                <div className="divider-menu"></div>

                <div onClick={() => setOpenCourses(true)}>
                  <h2>meu curso</h2>
                  <img src={Arrow} alt="arrow" className="rotate" />
                </div>

                <div className="divider-menu"></div>

                <h2 onClick={() => history.push('/profissionais')}>
                  profissionais
                </h2>

                <div className="divider-menu"></div>

                <h2 onClick={() => history.push('/produtos/1')}>produtos</h2>

                <div className="divider-menu"></div>

                <h2 onClick={() => history.push('/onde-comprar')}>
                  onde comprar
                </h2>
              </>
            )}

            <div className="logout-wrapper" onClick={logout}>
              <img src={SignoutIcon} alt="signout" />
              <h2>sair</h2>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="close-btn">
            <img src={Logo} alt="regenesis" />

            <img src={CloseIcon} alt="close" onClick={onClose} />
          </div>

          <div className="menu-icons-not-register">
            <h2 onClick={() => history.push('/login')}>entre</h2>

            <div className="divider-menu"></div>

            <h2 onClick={() => history.push('/signup')}>cadastre-se</h2>
          </div>
        </>
      )}
    </MenuDrawerWrapper>
  );
};

export default DrawerMenu;
