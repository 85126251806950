import styled from 'styled-components';

export const PolicyContainerWrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.68) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--default-background-color);
`;

export const PrivacyPolicy = styled.div`
  margin-top: 25px;

  div > * {
    font-size: 16px;
  }
`;
