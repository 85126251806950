import styled, { css } from 'styled-components';

interface ISideMenu {
  open: boolean;
}

interface SideMenuRetractedProps {
  color: string;
}

export const SideMenuCourseWrapper = styled.div<ISideMenu>`
  ${({ open }) => css`
    width: ${open ? `32%` : `50px`};
  `}

  position: fixed;
  right: 0;
  height: 100%;
  transition: all 0.7s ease-in-out;

  @media (max-width: 768px) {
    display: none;
  }

  .arrow-btn {
    background-color: var(--secondary-color);
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    left: -30px;
    ${({ open }) => css`
      padding-left: ${open ? `0` : `5px`};
    `}

    .arrow-set {
      margin-top: 7px;
      border: solid white;
      border-width: 0 2.2px 2.2px 0;
      padding: 5px;
      display: inline-block;
      ${({ open }) => css`
        transform: ${open ? `rotate(-45deg)` : `rotate(135deg)`};
        -webkit-transform: ${open ? `rotate(-45deg)` : `rotate(135deg)`};
      `}
    }
  }
`;

export const SideMenuRetracted = styled.div<SideMenuRetractedProps>`
  background: var(--color-side-menu);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .steps-course {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 240px;
    position: relative;
    cursor: pointer;

    .module {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--third-color);

      ::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 2px solid var(--third-color);
        left: -7px;
        top: -7px;
        background: transparent;
      }
    }

    .selected {
      background: var(--primary-color-light);

      ::after {
        content: "";
        position: absolute;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 2px solid var(--primary-color-light);
        left: -7px;
        top: -7px;
        background: transparent;
      }
    }

    .dashed-component {
      width: 58px;
      height: 58px;
      border: none;
      border-top: 2px dashed var(--third-color);
      transform: rotate(90deg);
      margin: 7px 0 7px -55px;
    }

    .selected-dashed {
      border-top: 2px dashed var(--primary-color-light);
    }
  }

  .logo-footer {
    width: 100%;
    background-color: ${({color}) => color ? color : 'var(--banner-color)'};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
  }
`;

export const SideMenuExpanded = styled.div`
  background: var(--color-side-menu);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: space-between;

  ::-webkit-scrollbar {
    display: none;
  }

  .journey-course {
    width: 100%;
    background: white;
    padding: 30px 20px 28px 20px;

    h1 {
      color: var(--secondary-color-light);
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }
  }
`;
