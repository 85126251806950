import React from 'react';
import { getProduct, getAllProducts } from 'services/product';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import Categories from './Categories';
import Content from '../Content';
import Product from 'services/product/models/product-interface';

const Container = styled.div`
  @media screen and (max-width: 1185px) {
    display: none;
  }
`;

const Tab: React.FC = () => {
  const { id } =
    useParams<{
      id: string;
    }>();
  const history = useHistory();

  const [products, setProducts] = React.useState<Product[]>([]);
  const [productSelected, setProductSelected] = React.useState<Product>();

  React.useEffect(() => {
    (async () => {
      const localProducts = await getAllProducts();
      let localProduct;
      if (id === '1') {
        history.push(`/produtos/${localProducts[0].product_id}`);
      } else {
        localProduct = await getProduct(id);

        setProductSelected(localProduct);
      }

      setProducts(localProducts);
    })();
  }, [id]);

  return (
    <>
      <Categories products={products} />
      <Container>
        <Content product={productSelected} />
      </Container>
    </>
  );
};

export default Tab;
