import styled, { css } from 'styled-components';

export const ProfileInfoContainer = styled.div`
  padding: 0 10%;
  margin-top: 50px;

  @media (max-width: 768px) {
    h1 {
      text-align: center;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 70px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 24px;
  }

  .edit-icon {
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
  }

  .input-display {
    margin-top: 0;
    margin-bottom: 20px;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .container-btn {
      display: flex;
      align-items: center;
      margin-left: 8px;
      gap: 18px;

      @media (max-width: 768px) {
        margin-top: 16px;
      }
    }

    .input-name {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--third-color);
      width: 60%;
      outline: none;
      font-size: var(--font-largest);
      padding-bottom: 6px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .input-phone {
      background: transparent;
      border: none;
      border-bottom: 2px solid var(--third-color);
      width: 100%;
      outline: none;
      padding-bottom: 6px;
      font-size: var(--font-large);
    }
  }

  .img-container-input {
    position: relative;
    margin-right: 45px;

    @media (max-width: 768px) {
      margin-bottom: 40px;
      margin-right: 0;
    }

    .avatar {
      width: 128px;
      height: 128px;
      border-radius: 50%;
      object-fit: cover;
    }

    .camera {
      position: absolute;
      background-color: var(--third-color);
      border-radius: 50%;
      padding: 5px;
      top: 70%;
      left: 65%;
      cursor: pointer;
    }

    input[type='file'] {
      display: none;
    }
  }

  .info-user {
    h1 {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--third-color-dark);
      font-size: 46px;
      text-transform: capitalize;
      font-family: 'Nunito Sans Light';

      @media (max-width: 768px) {
        font-size: var(--font-larger);
        margin-bottom: 16px;
      }
    }

    h6 {
      font-size: var(--font-large);
      opacity: 0.4;
      font-family: 'Nunito Sans Light';
      margin-top: -5px;

      @media (max-width: 768px) {
        text-align: center;
        margin-bottom: 16px;
      }
    }

    .edit-phone {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 22px;

      @media (max-width: 768px) {
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        margin-top: 0;
      }
    }

    span {
      font-size: var(--font-large);
    }
  }

  .btn-password {
    margin-left: auto;

    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }

    button {
      font-size: var(--font-normal);
    }
  }
`;

interface IProfileMenu {
  isOpen: boolean;
}

export const ProfileMenuWrapper = styled.div<IProfileMenu>`
  ${({ isOpen }) => css`
    width: ${isOpen ? `590px` : `0`};
  `}

  @media (max-width: 768px) {
    ${({ isOpen }) => css`
      width: ${isOpen ? `100%` : `0`};
    `}
  }

  height: 100%;
  position: fixed;
  padding: 22px 30px;
  right: 0;
  top: 0;
  z-index: 5;
  border-radius: 8px;
  background-color: #f7f7f7;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 24px 64px rgba(45, 48, 58, 0.13);

  ::-webkit-scrollbar {
    display: none;
  }

  .close-icon {
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;

    img {
      cursor: pointer;
    }
  }

  .form-password {
    margin-top: 50px;
    width: 100%;

    h5 {
      font-size: var(--font-small);
    }
  }

  .btn-component {
    margin-top: 45px;
    display: flex;
    gap: 18px;
    justify-content: flex-end;
  }
`;

export const CodeInput = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;

  input[type='number'] {
    text-align: center;
    width: 64px;
    height: 80px;
    border: 1.5px solid var(--secondary-color);
    color: var(--secondary-color);
    outline: none;

    position: relative;
    z-index: 99999;

    :focus {
      border: 2px solid var(--third-color-dark);
    }
    @media (min-width: 998px){
      font-size: 38px;
    }
    @media (max-width: 997px){
      width: 30px;
      height: 40px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media (max-width: 997px){
    gap: 10px;
    justify-content: space-around;
    max-width: 400px;
    margin: 0 auto;
  }
`;