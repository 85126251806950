import React, { useState } from 'react';
import Arrow from 'assets/icon/back-arrow.svg';
import { Dropdown, Container } from './style';
import { OutsideClick } from 'components';
import triangle from 'assets/icon/triangle-icon.svg';

interface Props {
  specialty: string;
  specialties: Array<string>;
  onChange: (specialty: string) => void;
  resetPage: () => void;
}

const DropdownUI: React.FC<Props> = ({
  specialties,
  onChange,
  resetPage,
  specialty,
}) => {
  const [menuSpecialists, setMenuSpecialists] = useState<boolean>(false);

  const handleMenuOpen = () => {
    menuSpecialists ? setMenuSpecialists(false) : setMenuSpecialists(true);
  };

  const onClose = () => {
    setMenuSpecialists(false)
  }

  return (
    <OutsideClick handleClick={onClose}>
      <Container>
        <div className="my-specialists">
          <div className="selected" onClick={handleMenuOpen}>
            <span>ESPECIALIDADE</span>
            <img src={Arrow} alt="arrow" className="arrow-speciality" />
          </div>

          <Dropdown open={menuSpecialists}>
            <img src={triangle} alt="/" className="triangle" />

            <ul>
              <li
                className={specialty === '' ? 'selected-specialty' : ''}
                onClick={() => {
                  resetPage();
                  handleMenuOpen();
                }}
              >
                Mostrar Todos
            </li>

              {specialties.map((specialtyEl, index) => {
                return (
                  <li
                    key={index}
                    className={
                      specialty === specialtyEl ? 'selected-specialty' : ''
                    }
                    onClick={() => {
                      onChange(specialtyEl);
                      handleMenuOpen();
                    }}
                  >
                    {specialtyEl}
                  </li>
                );
              })}
            </ul>
          </Dropdown>
        </div>
      </Container>

    </OutsideClick>
  );
};

export default DropdownUI;
