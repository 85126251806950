import React, { useState, useEffect } from 'react';
import { getInfoByType } from 'services/info';
import { useHistory } from 'react-router-dom';
import Info from 'services/info/models/info-interface';
import { PlayerSambaVideos } from 'components';
import { MainBtn } from 'components/Buttons';

import { LandingPageVideoContainer } from './style';
import LogoGestante from 'assets/logo/logo@3x 1.svg';

const WelcomeVideo: React.FC = () => {
  const history = useHistory();
  const [welcomeVideo, setWelcomeVideo] = useState<Array<Info>>();

  useEffect(() => {
    (async () => {
      let localVideo;
      const user = JSON.parse(localStorage.getItem('exeltis-user') as string);

      if (user) {
        localVideo = await getInfoByType('WELCOME_VIDEO');
      } else {
        const token = 'Bearer ' + process.env.REACT_APP_UNLOGGED_TOKEN;
        localVideo = await getInfoByType('WELCOME_VIDEO', {
          Authorization: token,
        });
      }

      setWelcomeVideo(localVideo);
    })();
  }, []);

  return (
    <LandingPageVideoContainer>
      <div className="containers">
        <img src={LogoGestante} alt="gestante" />

        <div className="text-explain">
          <p>
            Curso completo com <span>profissionais de saúde</span> e
            <span> especialistas</span> com aulas que te acompanham do 1°
            trimestre de gestação aos 6 meses de vida do seu bebê!
          </p>
        </div>
      </div>

      <div className="containers">
        {welcomeVideo && welcomeVideo.length ? (
          <div className="video">
            <PlayerSambaVideos
              contentReference={welcomeVideo[0].info.reference}
              controlsToEnable={[
                'play',
                'pause',
                'quality',
                'fullscreen',
                'time',
                'volume',
                'subtitles',
                'settings',
              ]}
              getEventListeners={() => {}}
            />
          </div>
        ) : null}

        <MainBtn onClick={() => history.push('/signup')}>
          quero me inscrever
        </MainBtn>
        <p>curso 100% online e gratuito</p>
      </div>
    </LandingPageVideoContainer>
  );
};

export default WelcomeVideo;
