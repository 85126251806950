import styled from 'styled-components';

export const OverallProgressContainer = styled.div`
  padding: 0 10%;

  @media (max-width: 768px) {
    h1 {
      text-align: center;
      margin-top: -28px;
    }
  }

  .progress-bar {
    margin-top: 45px;
    position: relative;

    @media (max-width: 768px) {
      text-align: center;
      margin-top: 60px;
    }

    span {
      top: -350%;
      right: 0;
      position: absolute;
      font-size: var(--font-large);
      color: var(--secondary-color-light);
    }
  }

  .each-course-progress {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }
`;

export const CourseProgressCard = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    transform: rotateY(180deg);
    border: 5px solid var(--color-progress-background);
  }

  .progress {
    position: absolute;
    left: -7px;
    top: -8.5px;
  }

  .course-progress {
    margin-left: 18px;

    h2 {
      font-size: var(--font-large);
      font-weight: 700;
      text-transform: uppercase;
      max-width: 125px;
      color: var(--secondary-color);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    h5 {
      font-size: var(--font-medium);
      text-transform: uppercase;
      margin-top: 5px;
    }
  }
`;
