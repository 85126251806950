import React, { useState } from 'react';
import Slider from 'react-slick';
import Author from 'services/profissionals/models/author-interface';
import ProfissionalCard from '../ProfissionalCard';

import { ProfissionalCardWrapper } from './style';
import NextArrow from 'assets/icon/arrow-expanded.svg';
import PrevArrow from 'assets/icon/arrow-retracted.svg';

interface IProfissional {
  profissionals: Array<Author>;
  sliderSettings: any;
  limit: number;
}

const ProfissionalCarousel: React.FC<IProfissional> = ({
  profissionals,
  sliderSettings,
  limit,
}) => {
  const [reachEnd, setReachEnd] = useState<boolean>(false);
  const [reachStart, setReachStart] = useState<boolean>(true);
  let sliderRef: Slider | null;

  const handleSliderChange = (current: number): void => {
    if (current === limit) {
      setReachEnd(true);
      setReachStart(false);
    } else if (current !== 0) {
      setReachEnd(false);
      setReachStart(false);
    } else {
      setReachEnd(false);
      setReachStart(true);
    }
  };

  return (
    <ProfissionalCardWrapper>
      <div className="title-medics">
        <h1>profissionais</h1>

        <div>
          <img
            src={reachStart ? PrevArrow : NextArrow}
            alt="previous arrow"
            className={reachStart ? '' : 'close'}
            onClick={() => sliderRef?.slickPrev()}
          />
          <img
            src={reachEnd ? PrevArrow : NextArrow}
            alt="next arrow"
            className={reachEnd ? 'close' : ''}
            onClick={() => sliderRef?.slickNext()}
          />
        </div>
      </div>

      <Slider
        {...sliderSettings}
        ref={slider => (sliderRef = slider)}
        afterChange={current => handleSliderChange(current)}
      >
        {profissionals &&
          profissionals.length &&
          profissionals.map((profissional: Author, index: number) => (
            <React.Fragment key={index}>
              <ProfissionalCard profissional={profissional} clickable />
            </React.Fragment>
          ))}
      </Slider>
    </ProfissionalCardWrapper>
  );
};

export default ProfissionalCarousel;
