import React, { useEffect, useState } from 'react';
import { getUserProgress, IUserProgressTrail } from 'services/user';
import Course from 'services/course/models/course-interface';
import Trail from 'services/course/models/trail-interface';
import ProgressBar from 'components/ProgressBar';
import CircularProgress from 'components/CircularProgress';

import { OverallProgressContainer, CourseProgressCard } from './style';
import ImgWoman1 from 'assets/details/woman-draw-1period.svg';
import ImgWoman2 from 'assets/details/woman-draw-2period.svg';
import ImgWoman3 from 'assets/details/woman-draw-3period.svg';
import ImgWoman4 from 'assets/details/woman-draw-4period.svg';

interface IOverallProgress {
  trail: Trail;
}

const OverallProgress: React.FC<IOverallProgress> = ({ trail }) => {
  const [userProgress, setUserProgress] = useState<IUserProgressTrail>();
  const [courseProgress, setCourseProgress] = useState<any>();

  const createFormattedObject = (
    courses: Course[],
    progress: IUserProgressTrail,
  ): void => {
    let sortedCourses: any = progress.courses.map(course => {
      let courseFormat = courses.find(
        (courseSet: Course) => courseSet.course_id === course.course_id,
      );

      let contentLesson = courseFormat?.lessons.filter(
        lesson => lesson.alreadyFinished && lesson.alreadyStarted,
      );

      if (courseFormat) {
        return {
          ...course,
          position: courseFormat.position,
          content_watched:
            contentLesson?.length + '/' + courseFormat.lessons.length,
        };
      }

      return null;
    });

    if (sortedCourses && sortedCourses.length) {
      sortedCourses = sortedCourses.sort(
        (a: any, b: any) => a?.position - b?.position,
      );
    }

    setCourseProgress(sortedCourses);
  };

  useEffect(() => {
    (async () => {
      const localProgress = await getUserProgress(trail.trail_id);

      createFormattedObject(trail.courses, localProgress);
      setUserProgress(localProgress);
    })();
  }, []);

  const handleReturnImg = (index: number): string => {
    switch (index) {
      case 0:
        return ImgWoman1;

      case 1:
        return ImgWoman2;

      case 2:
        return ImgWoman3;

      case 3:
        return ImgWoman4;

      default:
        return '';
    }
  };

  return (
    <>
      {userProgress ? (
        <OverallProgressContainer>
          <h1>já assistido</h1>

          <div className="progress-bar">
            <span>{Math.round(userProgress.trail.progress * 100)}%</span>

            <ProgressBar
              hasTitle={false}
              progress={userProgress?.trail.progress * 100}
            />
          </div>

          <div className="each-course-progress">
            {courseProgress && courseProgress.length
              ? courseProgress.map((course: any, index: number) => (
                  <CourseProgressCard key={index}>
                    <img src={handleReturnImg(index)} alt="woman" />

                    <div className="progress">
                      <CircularProgress
                        progress={course.percent * 100}
                        stroke={5}
                        radius={62}
                        strokeColor={'#E46C9A'}
                      />
                    </div>

                    <div className="course-progress">
                      <h2>{course.name}</h2>
                      <h5>{course.content_watched} - Aulas</h5>
                    </div>
                  </CourseProgressCard>
                ))
              : null}
          </div>

          <div className="divider"></div>
        </OverallProgressContainer>
      ) : null}
    </>
  );
};

export default OverallProgress;
