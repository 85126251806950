function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function checkPassword(password: string): boolean {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return re.test(password);
}

function validateName(name: string): boolean {
  const re = /[!@#$%^&*(),.?":{}|<>]/g;
  return re.test(name);
}

function checkEmptyString(str: string) {
  if (!str || !str.replace(/[!@#$%¨&()_+=~^<>*|"]/g, '').trim()) {
    return true;
  }

  return false;
}

export { validateEmail, checkPassword, validateName, checkEmptyString };
