import httpClient from 'http-client';
import { generateCertificate } from 'pdf/generateCertificate';
import download from 'downloadjs';

export interface Certificate {
  user_id: string;
  user_name: string;
  emitted_at: Date;
  watched_time: number;
}

const downloadCertificate = async (
  trailId: string,
  makeDownload: boolean,
): Promise<boolean> => {
  const certificateData = (await httpClient.post(`/certificates/${trailId}`))
    .data;

  if (makeDownload) {
    const certificate = await generateCertificate(certificateData);

    download(certificate, 'certificado');

    return Promise.resolve(true);
  }

  return !!certificateData;
};

export { downloadCertificate };
