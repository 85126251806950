import styled from 'styled-components';

export const ModulesContentWrapper = styled.div`
  width: 100%;
`;

export const ModulesListContainer = styled.div`
  display: grid;
  grid-template-columns: 28% 65%;
  margin: 20px auto;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }

  .woman-img {
    height: 900px;
    transform: scaleX(-1);

    @media (max-width: 768px) {
      display: none;
    }
  }

  .paginator {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 100px;
    gap: 20px;

    @media (max-width: 768px) {
      justify-content: center;
      padding: 0 8px;
    }

    h4 {
      color: var(--secondary-color);
      text-transform: uppercase;
    }

    img {
      cursor: pointer;
    }
  }
`;

export const ModuleSelectorTitle = styled.div`
  width: 100%;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      padding: 0 12px;
    }

    h1 {
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-family: 'Nunito Sans ExtraBold';
      font-size: var(--font-larger);
    }

    h4 {
      text-transform: uppercase;
      color: var(--color-text-card);
      padding-top: 8px;
      font-size: var(--font-medium);
    }

    .arrows {
      display: flex;
      align-items: center;
      gap: 22px;

      .close {
        transform: rotate(180deg);
      }

      img {
        cursor: pointer;
      }
    }
  }

  .progress-bar-module {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 55px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      margin-top: 8px;
      margin-bottom: 30px;
      padding: 0 12px;
      gap: 30px;
    }

    h4 {
      color: var(--secondary-color-light);
      text-transform: uppercase;
      font-family: 'Nunito Sans Light';
      font-size: var(--font-larger);
      font-weight: 200;

      @media (max-width: 768px) {
        font-size: var(--font-largest);
        align-self: flex-start;
      }
    }

    .bar {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .courses {
    display: flex;
    row-gap: 60px;
    column-gap: 70px;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 70px;

    @media (max-width: 768px) {
      justify-content: center;
      padding: 0 5px;
    }
  }
`;
