import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { signup } from 'services/signup';
import { maskPhone } from 'helpers/masks';
import { validateEmail, checkPassword, validateName } from 'helpers/validation';
import { toast } from 'react-toastify';
import { Footer, Input, Header } from 'components';
import { SmallBtn } from 'components/Buttons';

import {
  SignupContainer,
  FormContainer,
  SocialMediaLinks,
  SocialMedia,
} from './style';

import LogoCursoGestante from 'assets/logo/logo@3x 1.svg';
import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import BackArrow from 'assets/icon/back-arrow.svg';
import Flower1 from 'assets/details/flower-1.svg';
import Flower2 from 'assets/details/flower-2.svg';
import Flower3 from 'assets/details/flower-3.svg';
import Flower4 from 'assets/details/flower-4.svg';
import Flower5 from 'assets/details/flower-5.svg';
import Flower6 from 'assets/details/flower-6.svg';
import Flower7 from 'assets/details/flower-7.svg';
import Flower8 from 'assets/details/flower-8.svg';
import smoothscroll from 'smoothscroll-polyfill';
import FacebookButton from 'components/FacebookButton';
import GoogleButton from 'components/GoogleButton';

smoothscroll.polyfill();

interface props extends RouteComponentProps {}

function Signup({ history }: props) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');

  const getErrorMessage = async (error: any): Promise<void> => {
    return error && error.response && error.response.data
      ? typeof error.response.data.message === 'object'
        ? error.response.data.message.message
        : error.response.data.message
      : error.message || error;
  };

  const handleSignup = async (): Promise<void> => {
    if (!email) {
      setEmailError('Preencha corretamente este campo');
    }

    if (!name) {
      setNameError('Preencha corretamente este campo');
    }

    if (!phone) {
      setPhoneError('Preencha corretamente este campo');
    }

    if (!password) {
      setPasswordError('Preencha corretamente este campo');
    }

    if (!repeatPassword) {
      setRepeatPasswordError('Preencha corretamente este campo');
      return;
    }

    if (validateName(name) || name.length < 2) {
      setNameError(
        'O nome precisa ter pelo menos duas letras e nenhum caracter especial.',
      );
      return;
    }

    if (password !== repeatPassword) {
      setPasswordError('As senhas não se correspondem, informe senhas iguais.');
      setRepeatPasswordError(
        'As senhas não se correspondem, informe senhas iguais.',
      );
      return;
    }

    if (password === repeatPassword && !checkPassword(password)) {
      setPasswordError(
        'A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas e números',
      );
      return;
    }

    if (
      phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')
        .length < 11
    ) {
      setPhoneError('O número de telefone está inválido.');
      return;
    }

    if (!validateEmail(email) && email) {
      setEmailError('Informe um e-mail com formato válido');
      return;
    }

    if (
      !emailError &&
      !nameError &&
      !passwordError &&
      !repeatPasswordError &&
      !phoneError
    ) {
      const data = {
        email,
        password,
        name,
        telephone: phone
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replace(' ', ''),
      };

      signup(data)
        .then(response => {
          if (response) {
            history.push('/redefinir', {
              email,
              type: 'signup',
            });
            toast.success(
              'Cadastro criado! Verifique seu email para receber um código de segurança',
            );
          }
        })
        .catch(() => {
          toast.error(
            '⚠ Algo deu errado ao concluir seu cadastro. Tente novamente.',
          );
        });
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (e.target.id) {
      case 'name-input':
        setName(e.target.value);
        break;

      case 'email-input':
        setEmail(e.target.value);
        break;

      case 'phone-input':
        setPhone(maskPhone(e.target.value));
        break;

      case 'password-input':
        setPassword(e.target.value);
        break;

      case 'repeat-password-input':
        setRepeatPassword(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <SignupContainer>
      <Header reduceHeader={false} />

      <div className="logo-container">
        <img src={Flower1} alt="flower1" className="flower flower-1" />
        <img src={Flower2} alt="flower2" className="flower flower-2" />
        <img src={Flower3} alt="flower3" className="flower flower-3" />
        <img src={Flower4} alt="flower4" className="flower flower-4" />
        <img src={Flower5} alt="flower5" className="flower flower-5" />
        <img src={Flower6} alt="flower6" className="flower flower-6" />
        <img src={Flower7} alt="flower7" className="flower flower-7" />
        <img src={Flower8} alt="flower8" className="flower flower-8" />

        <img
          src={BackArrow}
          alt="back arrow"
          className="back-arrow"
          onClick={() => history.push('/')}
        />

        <img
          src={LogoRegenesis}
          alt="regenesis"
          className="img-1"
          onClick={() => history.push('/')}
        />

        <img
          src={LogoCursoGestante}
          alt="curso de gestante"
          className="img-2"
        />
      </div>

      <FormContainer>
        <div className="title">
          <h2>cadastre-se com</h2>

          <div className="btn-signup-xl">
            <SocialMediaLinks>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Facebook"
              >
                <FacebookButton aria-hidden />
              </SocialMedia>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Google"
              >
                <GoogleButton aria-hidden />
              </SocialMedia>
            </SocialMediaLinks>
          </div>

          <div className="btn-signup-sm">
            <SocialMediaLinks>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Facebook"
              >
                <FacebookButton aria-hidden />
                <p>Continuar com o Facebook</p>
              </SocialMedia>
              <SocialMedia
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                onKeyDown={e =>
                  e.code === 'Enter' &&
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                tabIndex={0}
                aria-label="Entre com o Google"
              >
                <GoogleButton aria-hidden />
                <p>Continuar com o Google</p>
              </SocialMedia>
            </SocialMediaLinks>
          </div>

          <h4>ou com seu email</h4>
        </div>

        <Input
          type="text"
          id="name-input"
          value={name}
          onChange={handleChangeInput}
          onFocus={() => setNameError('')}
          label="Nome"
        >
          {nameError && <span>{nameError}</span>}
        </Input>

        <Input
          type="email"
          id="email-input"
          value={email}
          onChange={handleChangeInput}
          onFocus={() => setEmailError('')}
          label="E-mail"
        >
          {emailError && <span>{emailError}</span>}
        </Input>

        <div className="container-input-phone">
          <Input
            type="text"
            id="phone-input"
            value={phone}
            onChange={handleChangeInput}
            onFocus={() => setPhoneError('')}
            label="Celular"
          >
            {phoneError && <span>{phoneError}</span>}
          </Input>
        </div>

        <Input
          type="password"
          id="password-input"
          value={password}
          onChange={handleChangeInput}
          onFocus={() => setPasswordError('')}
          label="Senha"
          alert="A senha precisa conter no mínimo 8 caracteres, incluindo letras maíusculas, minúsculas, números e caracteres especiais"
        >
          {passwordError && <span>{passwordError}</span>}
        </Input>

        <Input
          type="password"
          id="repeat-password-input"
          value={repeatPassword}
          onChange={handleChangeInput}
          onFocus={() => setRepeatPasswordError('')}
          label="Confirmar senha"
        >
          {repeatPasswordError && <span>{repeatPasswordError}</span>}
        </Input>

        <SmallBtn onClick={handleSignup}>Cadastrar</SmallBtn>

        <h5
          onClick={() =>
            history.push('/redefinir', {
              type: 'codeFirstStep',
            })
          }
        >
          Já tem um código de verificação ou não recebeu o código?
        </h5>
        <h5 onClick={() => history.push('/login')}>Já tem uma conta?</h5>
      </FormContainer>

      <div className="footer-signup">
        <Footer
          backgroundColor={'var(--color-secondary-background-color)'}
          hasLogo
        />
      </div>
    </SignupContainer>
  );
}

export default Signup;
