import React from 'react';
import styled, { css, keyframes, DefaultTheme } from 'styled-components';

interface IProps {
  size: string;
  color: string;
  fixed: boolean;
  relative: boolean;
  theme: DefaultTheme;
  bg: boolean;
}

const animation = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const Loading = styled.div<Partial<IProps>>`
  ${props =>
    props.size === 'xs' &&
    css`
      transform: scale(0.3);
    `}

  ${props =>
    props.fixed &&
    css`
      margin: auto;
      position: fixed;
      left: 50%;
      margin-left: -20px;
      top: 50%;
      margin-top: -20px;
    `}

	${props =>
    props.relative &&
    css`
      position: absolute;
      left: 15px;
      top: 15px;
    `}

	z-index: 1000000000;
  opacity: 1;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: ${animation} 0.6s infinite;
    border-color: ${p => p.color} transparent transparent transparent;
  }
`;

const Background = styled.div<Partial<IProps>>`
  background: white;
  opacity: 0.6;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const LoadingUI: React.FC<Partial<IProps>> = (props, bg) => {
  const style = {
    size: '',
    color: 'primary',
    fixed: true,
    relative: false,
    ...props,
  };

  if (props.theme) {
    style.color = '#E46C9A';
  } else {
    style.color = '#B92481';
  }

  return (
    <Background id="loading-global-element">
      <Loading {...style}>
        <div></div>
      </Loading>
    </Background>
  );
};

export default LoadingUI;
