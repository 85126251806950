import httpClient from 'http-client';
import ExtraMaterial from './models/extra-material-interface';

const getAllExtraMaterial = async (): Promise<Array<ExtraMaterial>> => {
  const extraMaterial: ExtraMaterial[] = (
    await httpClient.get('/extra-materials/')
  ).data;

  return extraMaterial;
};
const getExtraMaterialByCourse = async (): Promise<Array<ExtraMaterial>> => {
  const extraMaterial: ExtraMaterial[] = (
    await httpClient.get('/extra-materials/course')
  ).data;

  return extraMaterial;
};

const getExtraMaterialByContent = async (
  contentId: string,
): Promise<Array<ExtraMaterial>> => {
  const extraMaterial = (await httpClient.get(`/extra-materials/${contentId}`))
    .data;

  if (extraMaterial.extra_material && extraMaterial.extra_material.length) {
    return extraMaterial.extra_material as ExtraMaterial[];
  }

  return [] as ExtraMaterial[];
};

export {
  getAllExtraMaterial,
  getExtraMaterialByCourse,
  getExtraMaterialByContent,
};
