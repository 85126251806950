import styled, { keyframes } from 'styled-components';

const animate = keyframes`
	z-index: 10;
	0% {
		opacity: 0;
		transform: translate(0px, -30px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
`;

export const TabTitle = styled.div`
  .accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;

    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-title {
    font-weight: 600;
    cursor: pointer;
    padding: 20px 40px;
    border-top: 1px solid #d2cee2;
    border-bottom: 1px solid #d2cee2;
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--secondary-color);
    font-family: 'Nunito Sans ExtraBold';
    font-size: 18px;
    text-transform: uppercase;

    &:hover,
    &.open {
    }
    &.open {
      border-bottom: 1px solid transparent;
    }
  }

  .accordion-content {
    width: 100%;
    animation: ${animate} 0.6s;
  }
`;
