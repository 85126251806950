import React from 'react';
import { TabTitle } from './style';
import arrow_up from 'assets/icon/arrow_up.svg';
import arrow_down from 'assets/icon/arrow_down.svg';
import Product from 'services/product/models/product-interface';

type Props = {
  products: Product[];
  setProductSelected: Function;
};

const AccordionUI: React.FC<Props> = ({
  products,
  setProductSelected,
  children,
}) => {
  const [isOpen, setOpen] = React.useState<String>('');

  function verifyIsOpen(id: string) {
    return id === isOpen;
  }

  return (
    <>
      {products.map((product, index) => {
        return (
          <React.Fragment key={index}>
            <TabTitle className="accordion-wrapper">
              <div
                className={`accordion-title ${
                  verifyIsOpen(String(product.product_id)) ? 'open' : 'close'
                }`}
                onClick={() => {
                  if (verifyIsOpen(String(product.product_id))) {
                    setOpen('');
                  } else {
                    setOpen(String(product.product_id));
                  }

                  setProductSelected(product);
                }}
              >
                {product.title}

                <img
                  src={`${
                    verifyIsOpen(String(product.product_id))
                      ? arrow_up
                      : arrow_down
                  }`}
                  alt="/"
                />
              </div>
              {verifyIsOpen(String(product.product_id)) && (
                <div
                  className={`accordion-item ${
                    !verifyIsOpen(String(product.product_id)) ? 'collapsed' : ''
                  }`}
                >
                  <div className="accordion-content">{children}</div>
                </div>
              )}
            </TabTitle>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default AccordionUI;
