import styled from 'styled-components';

//TABS

export const Container = styled.div`
  .categories-nav {
    @media screen and (min-width: 1186px) {
      display: flex;
      flex: auto;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-top: 6px;
      cursor: grab;

      ::-webkit-scrollbar {
        display: none;
      }

      ul {
        display: flex;
        border-bottom: 1px solid #d2cee2;
        padding: 0 20px;
        gap: 30px;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      li {
        list-style: none;
      }

      li:last-child {
        a {
          margin-right: 0;
        }
      }

      a {
        padding-bottom: 26px;
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        position: relative;
        margin-left: 10px;
        margin-right: 10px;

        &::before {
          content: '';
          background: var(--secondary-color);
          width: 17px;
          opacity: 0;
          height: 4px;
          display: block;
          border-radius: 0;
          position: absolute;
          bottom: 0;
          left: 54%;
          transform: scaleX(1) translateX(-300%);
          transition: all 0.3s ease;
        }

        &.active {
          &::before {
            transform: scaleX(12) translateX(-10%);
            opacity: 1;
          }
          span {
            color: var(--secondary-color);
            font-family: 'Nunito Sans ExtraBold';
            font-size: var(--font-medium);
          }
        }

        span {
          color: var(--secondary-color);
          font-family: 'Nunito Sans ExtraBold';
          font-size: var(--font-medium);
        }
      }
    }

    @media screen and (max-width: 1185px) {
      display: none;
    }
  }
`;
