import styled, { css } from 'styled-components';

export interface ButtonStyle {
  isWhite?: boolean;
  size?: 'xs' | 'md'
  bg?: 'blueDark' | 'blue';
}

export const getSize = (size: 'xs' | 'md' | undefined) => {
  switch (size) {
    case 'xs':
      return 125;
    case 'md':
      return 145;
    default:
      return 125;
  }
};

export const BlankBtnWrapper = styled.button`
  background-color: transparent;
  color: var(--third-color);
  width: 380px;
  height: 46px;
  border: 1.8px solid var(--third-color);
  text-transform: uppercase;
  border-radius: 37px;
  font-weight: 800;
  letter-spacing: 1.5px;
  font-size: var(--font-large);
  align-self: 'none';
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;

  &:hover {
    border: 3px solid var(--secondary-color-dark);
    color: var(--secondary-color-dark);
  }

  &:active {
    color: var(--secondary-color);
    border: 3px solid var(--secondary-color);
  }

  @media (max-width: 768px) {
    font-size: var(--font-small);
    gap: 2px;
    width: 95%;

    img {
      display: none;
    }
  }
`;

export const MainBtnWrapper = styled.button`
  background-color: var(--primary-color);
  color: white;
  width: 440px;
  height: 58px;
  border: none;
  text-transform: uppercase;
  border-style: inherit;
  border-radius: 37px;
  font-weight: 800;
  letter-spacing: 1.5px;
  font-size: var(--font-large);
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;

  &:hover {
    background-color: var(--primary-color-dark);
  }

  &:active {
    background-color: var(--primary-color);
    box-shadow: 0px 0px 10px rgba(88, 88, 167, 0.6);
  }

  @media (max-width: 768px) {
    gap: 2px;
    width: 95%;

    img {
      display: none;
    }
  }
`;

export const SmallBtnWrapper = styled.button<ButtonStyle>`
  float: right;
  background-color: var(--third-color);
  color: white;
  max-width: 100%;
  height: 38px;
  outline: none;
  font-weight: 600;
  border-style: none;
  letter-spacing: 0.5px;
  border-radius: 20px;

  ${props => css`
    width: ${getSize(props.size)}px;
  `}

  ${props =>
    props.isWhite &&
    css`
      background-color: transparent;
      color: var(--secondary-color);
      border: 1px solid var(--secondary-color);
    `}

  &:hover {
    background-color: var(--secondary-color-dark);

    ${props =>
      props.isWhite &&
      css`
        background-color: var(--secondary-color);
        color: #fff;
        border: 1px solid var(--secondary-color);
        transition: 0.6s;
      `}
  }

  @media (max-width: 768px) {
    float: initial;
    width: 100%;
  }
`;

export const EmptyBtnWrapper = styled.button`
  border: none;
  background: transparent;
  letter-spacing: 1px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;
