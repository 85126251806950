import styled from 'styled-components';

export const ProductWrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.68) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--default-background-color);
`;
