import React from 'react';
import { BackgroundFlowers } from 'components';
import { SmallBtn } from 'components/Buttons';
import LogoRegenesis from 'assets/logo/logo-regenesis.svg';
import { Container } from './style';
import { useHistory } from 'react-router-dom';

type ErrorProps = {
  type: 'permission' | 'loadingError' | 'Error404';
};

const ErrorPage: React.FC<ErrorProps> = ({ type }) => {
  const history = useHistory();

  return (
    <BackgroundFlowers isLogo={false}>
      <Container>
        <h2>Tem algo errado, mamãe.</h2>
        {type === 'Error404' && (
          <h3>erro 404: A página que você procura não existe.</h3>
        )}
        {type === 'permission' && (
          <h3>A página que você procura não está disponível</h3>
        )}
        {type === 'loadingError' && <h3>O carregamento da página falhou</h3>}
        <img src={LogoRegenesis} alt="Exeltis" />
        {type === 'Error404' && 'permission' && (
          <SmallBtn isWhite={false} onClick={() => history.goBack()}>
            VOLTAR A PÁGINA ANTERIOR
          </SmallBtn>
        )}
        {type === 'loadingError' && (
          <SmallBtn isWhite={false} onClick={() => history.go(0)}>
            TENTAR NOVAMENTE
          </SmallBtn>
        )}
      </Container>
    </BackgroundFlowers>
  );
};

export default ErrorPage;
