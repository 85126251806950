import React, { useState } from 'react';
import InstagramIcon from 'assets/icon/Instagram-icon-secondary-color.svg';
import LinkIcon from 'assets/icon/link-icon-secondary-color.svg';
import PhoneIcon from 'assets/icon/phone-icon-secondary-color.svg';
import GpsIcon from 'assets/icon/gps-icon.svg';
import { SmallBtn } from 'components/Buttons';
import Author from 'services/profissionals/models/author-interface';
import { Card, Content, MoreInfo, Hr, BioParagraph } from './style';

type Props = {
  professional: Author;
};

const CardProfissional: React.FC<Props> = ({ professional }) => {
  const { name, info, avatar_url, description } = professional;
  const [displayNumber, setDisplayNumber] = useState(false);
  const [showMoreActive, setShowMoreActive] = useState(true);

  return (
    <Card>
      <Content>
        <div className="wrapper">
          <>
            <img className="profile" src={avatar_url} alt="profissional" />
          </>
          <div className="info-profissional">
            <h3>{name}</h3>

            {info.specialty.length
              ? info.specialty.map((specialty, index, array) => (
                  <React.Fragment key={index}>
                    <span>{specialty}</span>

                    {array.length !== index + 1 ? <span> e </span> : null}
                  </React.Fragment>
                ))
              : null}

            <div className="gps">
              <img src={GpsIcon} alt="gps" />

              <p>{info.city}</p>
            </div>
          </div>
        </div>
        <Hr />
        <MoreInfo>
          <div className="content">
            {description && (
              <>
                <h3>BIO</h3>
                <BioParagraph showMoreActive={showMoreActive}>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </BioParagraph>
                {description.length >= 100 && (
                  <p
                    onClick={() => setShowMoreActive(!showMoreActive)}
                    className="read-more"
                  >
                    Ler {showMoreActive ? 'mais...' : 'menos'}
                  </p>
                )}
              </>
            )}
            <>
              <nav>
                <ul>
                  <li>
                    {!!info.telephone && (
                      <div
                        className="phone-number"
                        onClick={() => setDisplayNumber(!displayNumber)}
                      >
                        <img src={PhoneIcon} alt="phone" />
                        {displayNumber ? info.telephone : 'Mostrar número'}
                      </div>
                    )}
                  </li>
                  <li>
                    {!!info.site && (
                      <a href={info.site} target="_blank" rel="noreferrer">
                        <img src={LinkIcon} alt="site" />
                        Site
                      </a>
                    )}
                  </li>
                  <li>
                    {!!info.social_media && (
                      <a
                        href={info.social_media}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={InstagramIcon} alt="instagram" />
                        Instagram
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
              <Hr />
            </>
            {professional.courses && professional.courses.length ? (
              <div className="modules">
                <h3>Aulas nos Módulos</h3>
                <div>
                  {professional.courses.slice(0, 4).map((course, index) => (
                    <SmallBtn isWhite={true} size="md" key={index}>
                      {course.title.toUpperCase()}
                    </SmallBtn>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </MoreInfo>
      </Content>
    </Card>
  );
};

export default CardProfissional;
