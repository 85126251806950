import styled, { css } from 'styled-components';

interface IHeader {
  isReduced: boolean;
}

export const HeaderContainerWrapper = styled.div<IHeader>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  ${({ isReduced }) => css`
    padding: ${isReduced ? '0' : '40px 20px'};
  `}

  @media (max-width: 768px) {
    display: none;
  }

  .logo {
    width: 80px;
    cursor: pointer;
  }

  .reduced-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
  }
`;

export const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  gap: 28px;

  a {
    text-decoration: none;

    display: block;
    position: relative;
    color: var(--secondary-color);
    font-family: 'Nunito Sans SemiBold';
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;

    &::before {
      content: '';
      background-color: var(--secondary-color);
      width: 4px;
      opacity: 0;
      height: 3px;
      display: block;
      border-radius: 0;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: scaleX(1) translateX(-300%);
      transition: all 0.3s ease;
      border-radius: 0.7px;
    }

    :hover {
      &::before {
        transform: scaleX(12) translateX(-10%);
        opacity: 1;
      }
    }

    &.active {
      &::before {
        transform: scaleX(12) translateX(-10%);
        opacity: 1;
      }
    }
  }

  span {
    color: var(--secondary-color);
    font-family: 'Nunito Sans SemiBold';
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
  }

  .my-course {
    display: inline-block;
    position: relative;

    .selected {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .arrow-course {
      height: 20px;
      width: 30px;
      transform: rotate(270deg);
    }
  }
`;

export const ProfileMenuContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
    color: var(--secondary-color);
    font-family: 'Nunito Sans SemiBold';
  }

  img {
    height: 37px;
    width: 37px;
    border: 3px solid var(--third-color);
    border-radius: 50%;
    cursor: pointer;
  }
`;

interface IOpenDrawer {
  open: boolean;
}

export const HomeWrapper = styled.div`
  display: none;

  img {
    cursor: pointer;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    padding: 3.4% 4%;
  }

  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;

export const MenuBackgroundOpacity = styled.div<IOpenDrawer>`
  ${({ open }) => css`
    display: ${open ? 'flex' : 'none'};
    width: ${open ? '100%' : '0'};
  `}

  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
`;

export const NotRegisterHeader = styled.div`
  display: flex;
  padding: 35px 70px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    cursor: pointer;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 40px;

    p {
      font-weight: 600;
      cursor: pointer;
      color: var(--secondary-color);
    }
  }
`;

export const NotRegisterHeaderResponsive = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 3.4% 4%;
  }

  img {
    cursor: pointer;
  }
`;
