function completePercentageInVideo(
  duration: number,
  content_view: number,
): number {
  const percentage = (content_view * 100) / duration;

  return percentage;
}

export { completePercentageInVideo };
