import styled from 'styled-components';

export const ProfissionalCardWrapper = styled.div`
  display: flex;
  align-items: center;

  .avatar-doctor {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    object-fit: cover;
  }

  .main-info {
    display: flex;
    flex-direction: column;
    margin-left: 25px;

    div {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        color: var(--secondary-color);
      }
    }

    h2 {
      color: var(--third-color-dark);
      font-weight: 800;
      font-size: var(--font-large);
      margin-bottom: 8px;
    }

    h4 {
      margin-bottom: 20px;
    }
  }
`;
