import styled, { css } from 'styled-components';

interface CommentFormContainerProps {
  isResponseComponent: boolean;
}

export const CommentsContentWrapper = styled.div`
  width: 100%;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 36px;
  }

  h1 {
    color: var(--secondary-color-light);
    text-transform: uppercase;
    font-size: var(--font-larger);
    font-family: 'Nunito Sans Light';
    margin-bottom: 50px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  h6 {
    opacity: 0.6;
    margin-bottom: 28px;
  }
`;

export const CommentFormContainer = styled.div<CommentFormContainerProps>`
  margin-bottom: 62px;
  ${({ isResponseComponent }) =>
    isResponseComponent &&
    css`
      margin-top: 20px;
      margin-left: 5%;
    `}

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }

  .btns-container {
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: flex-end;
    gap: 18px;

    button {
      text-transform: uppercase;
    }
  }

  .input-container {
    width: 100%;
    display: flex;
    gap: 18px;

    img {
      width: 40px;
      height: 40px;
    }

    textarea {
      width: 100%;
      background: transparent;
      border-style: none;
      border-bottom: 1px solid rgba(80, 117, 205, 0.28);
      outline: none;
      resize: none;

      &:focus {
        border-bottom: 1.8px solid var(--third-color);
      }
    }
  }
`;

export const CommentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .spacer {
    margin-bottom: 30px;
  }

  .container {
    display: flex;
    gap: 18px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .btns-comment {
    margin-left: 5%;
    display: flex;
    align-items: center;
    gap: 18px;

    .show-response {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    @media (max-width: 768px) {
      margin-left: 0;
      justify-content: space-between;
      padding: 0 6px;
    }

    button {
      text-transform: uppercase;
    }

    h3 {
      color: var(--third-color);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      cursor: pointer;
    }

    .arrow-icon {
      width: 15px;
      height: 15px;
      transform: rotate(-90deg);
    }

    .open {
      transform: rotate(90deg);
    }
  }

  .profile-avatar {
    width: 40px;
    height: 40px;
    margin-top: 6px;
  }

  .comment-card {
    border: 1px solid rgba(80, 117, 205, 0.28);
    background: rgba(255, 249, 249, 0.48);
    border-radius: 8px;
    box-shadow: 10px 10px 30px rgba(80, 117, 205, 0.06);
    padding: 28px;
    width: 100%;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      position: relative;

      h2 {
        color: var(--secondary-color);
        font-size: var(--font-large);
        font-weight: 800;
      }

      span {
        opacity: 0.55;
        font-size: var(--font-small);
      }
      button {
        position: absolute;
        right: -20px;
        top: -20px;
        background-color: transparent;
        border: none;
        width: 15px;
        height: 15px;
        img {
          width: 100%;
          display: block;
        }
      }
    }

    p {
      font-size: var(--font-large);
      white-space: pre-wrap;
    }
  }

  .list-responses {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    gap: 25px;

    @media (max-width: 768px) {
      margin-left: 7%;
    }
  }
`;
