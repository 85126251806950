import styled from 'styled-components';

export const LandingPageVideoContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 18px 120px;
  @media (max-width: 1100px) {
    grid-template-columns: 100%;
    padding: 0 3%;
    background-image: linear-gradient(
      360deg,
      rgba(255, 241, 241, 1) 5%,
      rgba(255, 241, 241, 0) 20%
    );
  }
  .containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  img {
    width: 400px;
    @media (max-width: 1280px) {
      width: auto;
      margin-top: 30px;
    }
  }
  .text-explain {
    border: 2px solid white;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.38);
    backdrop-filter: blur(18px);
    width: 400px;
    padding: 22px 30px;
    margin-top: 25px;
    @media (max-width: 1280px) {
      width: auto;
    }
    p {
      font-size: var(--font-large);
      color: var(--secondary-color);
      text-align: center;
      line-height: 28px;
      span {
        font-weight: 800;
        font-size: var(--font-large);
      }
    }
  }
  .video {
    width: 700px;
    height: 380px;
    margin-bottom: 40px;
    @media (max-width: 1280px) {
      width: 100%;
      margin-top: 40px;
    }
    @media screen and (max-width: 1024px) {
      > div {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  p {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-weight: 800;
    margin-top: 5px;
    font-size: var(--font-small);
  }
`;
