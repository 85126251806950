import React from 'react';
import { useHistory } from 'react-router-dom';
import Course from 'services/course/models/course-interface';

import { DropdownMenuContainer } from './style';
import UserIcon from 'assets/icon/user-icon.svg';
import CertificateIcon from 'assets/icon/certificate-icon.svg';
import SignoutIcon from 'assets/icon/signout-icon.svg';
import triangle from 'assets/icon/triangle-icon.svg';

interface IDrowdown {
  open: boolean;
  modules?: Course[];
  onClose: () => void;
  trailId?: string;
  positionProps: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  typeMenu: string;
  logout?: () => void;
}

const Dropdown: React.FC<IDrowdown> = ({
  open,
  modules,
  onClose,
  trailId,
  positionProps,
  typeMenu,
  logout,
}) => {
  const history = useHistory();

  return (
    <DropdownMenuContainer
      open={open}
      style={positionProps}
      typeMenu={typeMenu}
    >
      {typeMenu === 'course' ? (
        <>
          <img src={triangle} alt="/" className="triangle" />
          <ul>
            {modules &&
              modules.length &&
              modules.map((module, index: number, array: Course[]) => (
                <React.Fragment key={index}>
                  <li
                    key={index}
                    onClick={() => {
                      history.push(
                        `/meus-cursos/trilha/${trailId}/curso/${module.course_id}`,
                      );
                      onClose();
                    }}
                  >
                    {module.title}
                  </li>

                  {index !== array.length - 1 && (
                    <div className="divider-header"></div>
                  )}
                </React.Fragment>
              ))}
          </ul>
        </>
      ) : (
        <>
          <img src={triangle} alt="/" className="triangle-1" />
          <div className="border-arrow"></div>

          <ul>
            <li onClick={() => history.push('/perfil')}>
              <img src={UserIcon} alt="user" />
              EDITAR PERFIL
            </li>

            <div className="divider-header"></div>

            <li
              onClick={() =>
                history.push({
                  pathname: '/perfil',
                  state: 'certificate',
                })
              }
            >
              <img src={CertificateIcon} alt="certificate" />
              CERTIFICADO
            </li>

            <div className="divider-header"></div>

            <li onClick={logout}>
              <img src={SignoutIcon} alt="signout" />
              SAIR
            </li>
          </ul>
        </>
      )}
    </DropdownMenuContainer>
  );
};

export default Dropdown;
